import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Row, Col } from 'reactstrap';
import { Context as UIContext, SessionLogin, Tree, SlideLoader, ComponentEvents } from "@pathomation/pma.ui/src/index";
import { getAccessTokenAsync } from '../../actions/AuthActions';
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";

export const Viewer = () => {
    const [serverCreds, setServerCreds] = useState({});
    const context = useRef(new UIContext({ caller: "Laba" }));
    const navigate = useNavigate();
    const location = useLocation();

    const fetchServerSettings = async () => {
        const res = await fetch(`api/Settings`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (res.status == 404) {
            return;
        }

        var screds = await res.json();

        const res1 = await fetch(`api/PmaCoreSessionId`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res1.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (res1.status == 404) {
            return;
        }

        var sessionIdResult = await res1.json();
        setServerCreds({ username: screds.username, serverUrl: screds.serverUrl, path: screds.path, sessionId: sessionIdResult.sessionId });
    }

    useEffect(() => {
        document.title = "WSI browser";
        fetchServerSettings();
    }, []);

    useLayoutEffect(() => {
        if (!serverCreds || !serverCreds.serverUrl) {
            return;
        }

        new SessionLogin(context.current, [{ serverUrl: serverCreds.serverUrl, sessionId: serverCreds.sessionId }]);
        const serverUrl = serverCreds.serverUrl;

        const tree = new Tree(context.current, {
            servers: [
                {
                    name: "PMA.core",
                    url: serverUrl,
                }
            ],
            autoExpandNodes: true,
            element: "#tree-view",
        });

        const slideLoader = new SlideLoader(context.current, {
            element: "#viewer",
            barcode: true,
            annotations: true,
        });

        tree.listen(ComponentEvents.SlideSelected, function (args) {
            slideLoader.load(serverUrl, args.path);
        });
    }, [serverCreds]);

    return (<Row style={{ height: "100%" }}>
        <Col sm={3} style={{ height: "100%", overflow: "auto" }}><div id="tree-view"></div></Col>
        <Col><div style={{ height: "100%" }} id="viewer"></div></Col>
    </Row>);
}

