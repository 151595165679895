import React, { useContext, useState, useEffect } from 'react';
import {
    Row, Col, FormGroup, Input, Button, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledCollapse, Label, ButtonGroup, FormFeedback
} from 'reactstrap';
import { Message } from './Message';
import { getAccessTokenAsync } from '../../actions/AuthActions';
import { AppContext } from '../../State/AppContext';
import { userLogout } from "../../actions/AuthActions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faThumbsUp, faThumbsDown, faSpinner, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Formik, Form, ErrorMessage } from 'formik';
import { AutoCompleteField } from './AutoCompleteField';
import { useSearchParams } from "react-router-dom";
import { getOBXValues, getTAT, getOBRValues } from '../../actions/Types';
import * as XLSX from "xlsx";
import moment from 'moment';


export const Home = () => {
    const pageSize = 50;
    const context = useContext(AppContext);
    let [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({});

    const [disapprovalReasons, setDisapprovalReasons] = useState(null);
    const [identifiers, setIdentifiers] = useState(null);
    const [messageStatusNotes, setMessageStatusNotes] = useState("");
    const [mustSearch, setMustSearch] = useState(false);
    const [showModalMessage, setShowModalMessage] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        document.title = "Home - Verification of automated staining";

        let qs = new URLSearchParams(searchParams);

        let orderNumberInitialFilter = "";
        if (qs.has("ordernumber")) {
            orderNumberInitialFilter = qs.get("ordernumber");
        }

        let lotNumberInitialFilter = "";
        if (qs.has("lotnumber")) {
            lotNumberInitialFilter = qs.get("lotnumber");
        }

        let stainerInitialFilter = "";
        if (qs.has("stainer")) {
            stainerInitialFilter = qs.get("stainer");
        }

        let dateInitialFilter = "";
        if (qs.has("date")) {
            dateInitialFilter = qs.get("date");
        }

        let statusInitialFilter = "";
        if (qs.has("status")) {
            statusInitialFilter = qs.get("status");
        }

        let stainNameInitialFilter = "";
        if (qs.has("stainname")) {
            stainNameInitialFilter = qs.get("stainname");
        }

        let oNumberInitialFilter = "";
        if (qs.has("onumber")) {
            oNumberInitialFilter = qs.get("onumber");
        }

        let lotSerialInitialFilter = "";
        if (qs.has("lotserial")) {
            lotSerialInitialFilter = qs.get("lotserial");
        }

        let institutionInitialFilter = "";
        if (qs.has("institution")) {
            institutionInitialFilter = qs.get("institution");
        }

        let requesterInitialFilter = "";
        if (qs.has("requester")) {
            requesterInitialFilter = qs.get("requester");
        }

        let pg = parseInt(searchParams.page);
        if (isNaN(pg)) {
            pg = 0;
        }

        const newState = {
            messages: [],
            currentPage: pg,
            totalMessages: 0,
            orderNumber: orderNumberInitialFilter,
            lotNumber: lotNumberInitialFilter,
            stainerFilter: stainerInitialFilter,
            dateFilter: dateInitialFilter,
            status: statusInitialFilter,
            stainName: stainNameInitialFilter,
            oNumber: oNumberInitialFilter,
            lotSerial: lotSerialInitialFilter,
            institution: institutionInitialFilter,
            requesterFilter: requesterInitialFilter
        };

        fetchIdentifiers(newState);
    }, []);

    useEffect(() => {
        if (mustSearch) {
            applyFilter();
        }
    }, [mustSearch]);

    useEffect(() => {
        if (messageStatusNotes !== "") {
            postApprove(false);
        }

    }, [messageStatusNotes]);

    const filterChanged = (e) => {
        var val = e.target.value;
        if (e.target.name === "orderNumber") {
            setState({ ...state, orderNumber: val });
        }
        if (e.target.name === "lotNumber") {
            setState({ ...state, lotNumber: val });
        }
        if (e.target.name === "stainer") {
            setState({ ...state, stainerFilter: val });
        }
        if (e.target.name === "date") {
            setState({ ...state, dateFilter: val });
        }
        if (e.target.name === "status") {
            setState({ ...state, status: val });
        }
        if (e.target.name === "stainName") {
            setState({ ...state, stainName: val });
        }
        if (e.target.name === "oNumber") {
            setState({ ...state, oNumber: val });
        }
        if (e.target.name === "lotSerial") {
            setState({ ...state, lotSerial: val });
        }
        if (e.target.name === "institution") {
            setState({ ...state, institution: val });
        }
        if (e.target.name === "requester") {
            setState({ ...state, requesterFilter: val });
        }
    }

    const clearFilter = async () => {
        setSearchParams("");
        setState(
            {
                ...state,
                orderNumber: "",
                lotNumber: "",
                stainerFilter: "",
                dateFilter: "",
                status: "",
                stainName: "",
                oNumber: "",
                lotSerial: "",
                institution: "",
                requesterFilter: ""
            });

        setMustSearch(true);
    }

    const applyFilter = async () => {
        await populateData(0, pageSize, state.orderNumber, state.lotNumber, state.stainerFilter, state.dateFilter, state.status, state.stainName, state.oNumber, state.lotSerial, state.institution, state.requesterFilter);
    }

    const isFilterActive = () => {
        return state.orderNumber || state.lotNumber || state.stainerFilter || state.dateFilter || state.status || state.stainName || state.oNumber || state.lotSerial || state.institution || state.requesterFilter;
    }

    const messageChanged = async (id) => {
        const response = await fetch('api/message/message?id=' + id, {
            headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
        });

        const message = await response.json();
        if (message && message.id) {
            let mIndex = state.messages.findIndex(m => m.id === message.id);
            if (mIndex > -1) {
                setState({
                    ...state,
                    messages: [
                        ...state.messages.slice(0, mIndex),
                        message,
                        ...state.messages.slice(mIndex + 1),
                    ]
                });
            }
        }
    }

    const renderPages = (pages, keyPrefix) => {
        if (pages.length <= 1) {
            return <></>;
        }

        let step = 1;
        if (pages.length > 10) {
            step = Math.floor(pages.length / 10);
        }

        return (
            // <div className="row justify-content-center">
            <div className="col-auto ml-auto mr-auto">
                <div className="btn-group mb-3">
                    {pages.map(p => {
                        if (p.index === 0 || p.index === (pages.length - 1) || Math.abs(p.index - state.currentPage) < 3 || p.index % step === 0) {
                            return (<button
                                onClick={(evt) => {
                                    evt.preventDefault();
                                    populateData(p.index, pageSize, state.orderNumber, state.lotNumber, state.stainerFilter, state.dateFilter, state.status, state.stainName, state.oNumber, state.lotSerial, state.institution);
                                }}
                                key={keyPrefix + p.index}
                                className={`btn ${p.current ? "btn-primary" : "btn-secondary"}`}>{p.index + 1}
                            </button>);
                        }

                        return <React.Fragment key={keyPrefix + p.index}></React.Fragment>;
                    })}
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div className="btn-group mb-3 ml-4">
                    Messages&nbsp;<b>{(pageSize * state.currentPage) + 1}</b> - <b>{pageSize * (state.currentPage + 1) > state.totalMessages ? state.totalMessages : pageSize * (state.currentPage + 1)}</b>&nbsp;of&nbsp;<b>{state.totalMessages}</b>
                </div>
            </div>
            // </div>
        );
    }

    const approveClick = async (approve) => {
        if (window.confirm(`Are you sure you want to set the state of ${state.totalMessages} messages?`)) {
            setButtonClicked(true);
            if (!approve) {
                setMessageStatusNotes("");
                setShowModalMessage(true);
            }
            else {
                await postApprove(approve);
            }
        }
    }

    const postApprove = async (approve) => {
        if (!approve && messageStatusNotes === "") {
            setShowWarning(true);
            return;
        }

        setShowModalMessage(false);
        setShowWarning(false);

        try {
            var response = await fetch(`api/message/approveAll`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + getAccessTokenAsync() },
                    body: JSON.stringify({
                        OrderNumber: state.orderNumber,
                        LotNumberFilter: state.lotNumber,
                        StainerFilter: state.stainerFilter,
                        DateFilter: state.dateFilter !== "" ? state.dateFilter : null,
                        StatusFilter: state.status !== "" ? state.status : null,
                        StainNameFilter: state.stainName,
                        LotSerialFilter: state.lotSerial,
                        ONumberFilter: state.oNumber,
                        InstitutionFilter: state.institution,
                        Approve: approve,
                        Notes: messageStatusNotes
                    })
                });

            const result = await response.json();
            setButtonClicked(false);

            if (result === true) {
                populateData(state.currentPage, pageSize, state.orderNumber, state.lotNumber, state.stainerFilter, state.dateFilter, state.status, state.stainName, state.oNumber, state.lotSerial, state.institution);
                return;
            }

            console.warn("Server error for this message");
        }
        catch (error) {
            setButtonClicked(false);
        }
    }

    const renderMessages = () => {
        const totalPages = Math.ceil(state.totalMessages / pageSize);

        let pages = [];
        for (let i = 0; i < totalPages; i++) {
            pages.push({ index: i, current: state.currentPage === i });
        }

        return (
            <>
                <Row className="filters">
                    <div className="form-row mr-auto ml-auto" style={{ width: "100%" }}>
                        <div className="form-group col-md-12 pl-3">
                            <div className="form-group row">
                                <div className="form-group col-md">
                                    <Label for="institution">Institution</Label>
                                    <InputGroup>
                                        <Input className="form-control" type="text" name="institution" id="institution" placeholder="Institution" onChange={filterChanged} value={state.institution}
                                            onKeyPress={e => e.key === "Enter" ? applyFilter() : null} />
                                        <div className="input-group-append">
                                            <Button color="primary" onClick={() => setState({ ...state, institution: "" })}>
                                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                            </Button>
                                        </div>
                                    </InputGroup>
                                </div>
                                <div className="form-group col-md">
                                    <Label for="stainName">Stain name</Label>
                                    <InputGroup>
                                        <Input className="form-control" type="text" name="stainName" id="stainName" placeholder="Stain name" onChange={filterChanged} value={state.stainName}
                                            onKeyPress={e => e.key === "Enter" ? applyFilter() : null} />
                                        <div className="input-group-append">
                                            <Button color="primary" onClick={() => setState({ ...state, stainName: "" })}>
                                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                            </Button>
                                        </div>
                                    </InputGroup>
                                </div>
                                <div className="form-group col-md">
                                    <Label for="lotNumber">O-number</Label>
                                    <InputGroup>
                                        <Input className="form-control" type="text" name="oNumber" id="oNumber" placeholder="O-number" onChange={filterChanged} value={state.oNumber}
                                            onKeyPress={e => e.key === "Enter" ? applyFilter() : null} />
                                        <div className="input-group-append">
                                            <Button color="primary" onClick={() => setState({ ...state, oNumber: "" })}>
                                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                            </Button>
                                        </div>
                                    </InputGroup>
                                </div>
                                <div className="form-group col-md">
                                    <Label for="lotSerial">Lot serial number</Label>
                                    <InputGroup>
                                        <Input className="form-control" type="text" name="lotSerial" id="lotSerial" placeholder="Lot serial number" onChange={filterChanged} value={state.lotSerial}
                                            onKeyPress={e => e.key === "Enter" ? applyFilter() : null} />
                                        <div className="input-group-append">
                                            <Button color="primary" onClick={() => setState({ ...state, lotSerial: "" })}>
                                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                            </Button>
                                        </div>
                                    </InputGroup>
                                </div>
                                <div className="form-group col-md pl-4">
                                    <Label for="status">Status</Label>
                                    <InputGroup>
                                        <select className="form-select" name="status" id="status" title="Status" onChange={filterChanged} value={state.status}>
                                            <option value="">All</option>
                                            <option value="0">Not set</option>
                                            <option value="1">Approved</option>
                                            <option value="2">Disapproved</option>
                                        </select>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-12 border-bottom pl-3">
                            <div className="form-group row">
                                <div className="form-group col-md">
                                    <Label for="orderNumber">Case number</Label>
                                    <InputGroup>
                                        <Input className="form-control" type="text" name="orderNumber" id="orderNumber" placeholder="Case number" onChange={filterChanged} value={state.orderNumber}
                                            onKeyPress={e => e.key === "Enter" ? applyFilter() : null} />
                                        <div className="input-group-append">
                                            <Button color="primary" onClick={() => setState({ ...state, orderNumber: "" })}>
                                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                            </Button>
                                        </div>
                                    </InputGroup>
                                </div>
                                <div className="form-group col-md">
                                    <Label for="lotNumber">Lot number</Label>
                                    <InputGroup>
                                        <Input className="form-control" type="text" name="lotNumber" id="lotNumber" placeholder="Lot number" onChange={filterChanged} value={state.lotNumber}
                                            onKeyPress={e => e.key === "Enter" ? applyFilter() : null} />
                                        <div className="input-group-append">
                                            <Button color="primary" onClick={() => setState({ ...state, lotNumber: "" })}>
                                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                            </Button>
                                        </div>
                                    </InputGroup>
                                </div>
                                <div className="form-group col-md">
                                    <Label for="stainer">Stainer serial number</Label>
                                    <InputGroup>
                                        <Input className="form-control" type="text" name="stainer" id="stainer" placeholder="Stainer serial number" onChange={filterChanged} value={state.stainerFilter}
                                            onKeyPress={e => e.key === "Enter" ? applyFilter() : null} />
                                        <div className="input-group-append">
                                            <Button color="primary" onClick={() => setState({ ...state, stainerFilter: "" })}>
                                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                            </Button>
                                        </div>
                                    </InputGroup>
                                </div>
                                <div className="form-group col-md">
                                    <Label for="stainer">Requester</Label>
                                    <InputGroup>
                                        <Input className="form-control" type="text" name="requester" id="requester" placeholder="Requester" onChange={filterChanged} value={state.requesterFilter}
                                            onKeyPress={e => e.key === "Enter" ? applyFilter() : null} />
                                        <div className="input-group-append">
                                            <Button color="primary" onClick={() => setState({ ...state, requesterFilter: "" })}>
                                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                            </Button>
                                        </div>
                                    </InputGroup>
                                </div>
                                <div className="form-group col-md">
                                    <Label for="date">Date</Label>
                                    <InputGroup>
                                        <Input className="form-control" type="date" name="date" id="date" placeholder="Date" value={state.dateFilter} onChange={filterChanged}
                                            onKeyPress={e => e.key === "Enter" ? applyFilter() : null} />
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row justify-content-center">
                            <div className="form-group col-md-2 pl-4 border-bottom">
                                <ButtonGroup className="mr-auto ml-auto" style={{ paddingTop: "23px", width: "100%" }}>
                                    <Button color="primary" id="submit" onClick={applyFilter}><FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>&nbsp;Filter</Button>
                                    <Button color="danger" id="clear" onClick={clearFilter}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>&nbsp;Clear</Button>
                                    <Button color="success" onClick={() => { exportToExcel(); }}><FontAwesomeIcon icon="fa-solid fa-file-excel"></FontAwesomeIcon>&nbsp;Export</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </Row>
                <Row className="pagination justify-content-center">
                    {renderPages(pages, "top_")}
                    <Col xs="auto" className={(totalPages <= 1 ? "ml-auto" : "") + (" d-flex align-items-center")}>
                        <InputGroup className="flex-grow-1 flex-shrink-1" style={{ flexBasis: 150 }}>
                            <Button color="primary" style={{ display: "none" }} id="toggler" className={(isFilterActive() ? "active" : "")}>Filters</Button>
                        </InputGroup>

                        <div className="d-flex flex-row flex-nowrap flex-grow-1 flex-shrink-1" style={{ flexBasis: 150 }}>
                            <Button color="link" className="" onClick={() => approveClick(true)}>
                                <FontAwesomeIcon spin={buttonClicked} className="text-muted" icon={!buttonClicked ? faThumbsUp : faSpinner}></FontAwesomeIcon>
                            </Button>
                            <Button color="link" className="" onClick={() => approveClick(false)}>
                                <FontAwesomeIcon spin={buttonClicked} className="text-muted" icon={!buttonClicked ? faThumbsDown : faSpinner}></FontAwesomeIcon>
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {state.messages?.map(m =>
                        <Message key={m.id} value={m} identifiers={identifiers} disapprovalReasons={disapprovalReasons} messageChanged={messageChanged} />
                    )}
                    {totalPages === 0 && <div className="col-auto"><h5><em>No messages found for this filter</em></h5></div>}
                    <UncontrolledCollapse toggler="#toggler" className="col-4 float-filters card">
                        <div className={"card-body"}>
                            <div className="form-horizontal">
                                <FormGroup>
                                    <Label>Order number filter</Label>
                                    <InputGroup>
                                        <Input type="text" name="orderNumber" placeholder="Order number filter" onChange={filterChanged} value={state.orderNumber} />
                                        <div className="input-group-append">
                                            <Button color="primary" onClick={() => setState({ ...state, orderNumber: "" })}>
                                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                            </Button>
                                        </div>
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                    <Label>Lot number filter</Label>
                                    <InputGroup>
                                        <Input type="text" name="lotNumber" placeholder="Lot number filter" onChange={filterChanged} value={state.lotNumber} />
                                        <div className="input-group-append">
                                            <Button color="primary" onClick={() => setState({ ...state, lotNumber: "" })}>
                                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                            </Button>
                                        </div>
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                    <Label>Stainer filter</Label>
                                    <InputGroup>
                                        <Input type="text" name="stainer" placeholder="Stainer filter" onChange={filterChanged} value={state.stainerFilter} />
                                        <div className="input-group-append">
                                            <Button color="primary" onClick={() => setState({ ...state, stainerFilter: "" })}>
                                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                            </Button>
                                        </div>
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup className="">
                                    <Label>Date</Label>
                                    <Input type="date" name="date" placeholder="Date" value={state.dateFilter} onChange={filterChanged} />
                                </FormGroup>

                                <FormGroup>
                                    <Button color="primary" onClick={applyFilter}>Apply</Button>
                                </FormGroup>
                            </div>
                        </div>
                    </UncontrolledCollapse>
                </Row>
                <Row className="justify-content-center">
                    {renderPages(pages, "bot_")}
                </Row>
            </>
        );
    }

    const populateData = async (page, size, orderNumberFilter, lotNumberFilter, stainerFilter, dateFilter, statusFilter, stainNameFilter, oNumberFilter, lotSerialFilter, institutionFilter, requesterFilter) => {
        setMustSearch(false);
        setLoading(true);

        const response = await fetch(`api/message?page=${page}&pageSize=${size}` + (lotNumberFilter ? `&lotNumberFilter=${lotNumberFilter}` : "")
            + (orderNumberFilter ? `&orderNumberFilter=${encodeURIComponent(orderNumberFilter)}` : "")
            + (stainerFilter ? `&stainerFilter=${encodeURIComponent(stainerFilter)}` : "")
            + (dateFilter ? `&dateFilter=${encodeURIComponent(dateFilter)}` : "")
            + (statusFilter ? `&statusFilter=${encodeURIComponent(statusFilter)}` : "")
            + (stainNameFilter ? `&stainNameFilter=${encodeURIComponent(stainNameFilter)}` : "")
            + (oNumberFilter ? `&oNumberFilter=${encodeURIComponent(oNumberFilter)}` : "")
            + (lotSerialFilter ? `&lotSerialFilter=${encodeURIComponent(lotSerialFilter)}` : "")
            + (institutionFilter ? `&institutionFilter=${encodeURIComponent(institutionFilter)}` : "")
            + (requesterFilter ? `&requesterFilter=${encodeURIComponent(requesterFilter)}` : ""), {
            headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
        });

        const messagePage = await response.json();

        setState({
            ...state,
            orderNumber: orderNumberFilter,
            lotNumber: lotNumberFilter,
            stainerFilter: stainerFilter,
            dateFilter: dateFilter,
            status: statusFilter,
            stainName: stainNameFilter,
            oNumber: oNumberFilter,
            lotSerial: lotSerialFilter,
            institution: institutionFilter,
            requesterFilter: requesterFilter,
            messages: messagePage.messages,
            currentPage: messagePage.currentPage,
            totalMessages: messagePage.totalMessages,
        });

        setLoading(false);
    }

    const fetchIdentifiers = async (newState) => {
        if (!identifiers) {
            const response = await fetch("api/message/identifiers", {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

            if (response.status === 401) {
                userLogout(context);
                return;
            }

            setIdentifiers(await response.json());
        }

        if (!disapprovalReasons) {
            const response = await fetch("api/DisapprovalReason", {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

            if (response.status === 401) {
                userLogout(context);
                return;
            }

            setDisapprovalReasons(await response.json());
        }

        populateData(newState.currentPage, pageSize, newState.orderNumber, newState.lotNumber, newState.stainerFilter, newState.dateFilter, newState.status, newState.stainName, newState.oNumber, newState.lotSerial, newState.institution, newState.requesterFilter);
    }

    let stored = [];
    const fetchData = async (page, size, orderNumberFilter, lotNumberFilter, stainerFilter, dateFilter, statusFilter, stainNameFilter, oNumberFilter, lotSerialFilter, institutionFilter, requesterFilter) => {
        const response = await fetch(`api/message?page=${page}&pageSize=${size}` + (lotNumberFilter ? `&lotNumberFilter=${lotNumberFilter}` : "")
            + (orderNumberFilter ? `&orderNumberFilter=${encodeURIComponent(orderNumberFilter)}` : "")
            + (stainerFilter ? `&stainerFilter=${encodeURIComponent(stainerFilter)}` : "")
            + (dateFilter ? `&dateFilter=${encodeURIComponent(dateFilter)}` : "")
            + (statusFilter ? `&statusFilter=${encodeURIComponent(statusFilter)}` : "")
            + (stainNameFilter ? `&stainNameFilter=${encodeURIComponent(stainNameFilter)}` : "")
            + (oNumberFilter ? `&oNumberFilter=${encodeURIComponent(oNumberFilter)}` : "")
            + (lotSerialFilter ? `&lotSerialFilter=${encodeURIComponent(lotSerialFilter)}` : "")
            + (institutionFilter ? `&institutionFilter=${encodeURIComponent(institutionFilter)}` : "")
            + (requesterFilter ? `&requesterFilter=${encodeURIComponent(requesterFilter)}` : ""), {
            headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
        });

        const messagePage = await response.json();

        stored = messagePage.messages.map(x => {
            let parsedMessage = JSON.parse(x.messageJson);
            let { slideId, orderNumber, specimenReceivedDateTime } = getOBRValues(parsedMessage);
            let { requester, stainingRunCompletedTime } = getOBXValues(parsedMessage);
            let tat = getTAT(specimenReceivedDateTime, stainingRunCompletedTime);

            //convert the staining date to an appropriate form
            let year = stainingRunCompletedTime.slice(0, 4);
            let month = stainingRunCompletedTime.slice(4, 6);
            let day = stainingRunCompletedTime.slice(6, 8);
            let hour = stainingRunCompletedTime.slice(8, 10);
            let minutes = stainingRunCompletedTime.slice(10, 12);
            stainingRunCompletedTime = `${year}-${month}-${day}T${hour}:${minutes}`;

            return {
                orderNumber: x.orderNumber,
                recipientId: x.uzbRecipientId,
                blockId: x.uzbBlockId,
                slideId: x.uzbSlideId,
                universalServiceId: x.universalServiceId,
                date: moment(x.messageDate).format("LLLL"),
                oNumber: x.oNumber,
                stainerType: x.stainer,
                stainerSerialNumber: x.stainerSerial,
                institution: x.institution,
                requester: x.requester,
                stainingFinishedTime: moment(stainingRunCompletedTime).format("LLLL"),
                tatTime: tat,
                status: x.status,
                statusChangedBy: x.statusChangedBy,
                statusComments: x.statusNotes,
            };
        });
    }

    const exportToExcel = () => {
        fetchData(0, state.totalMessages, state.orderNumber, state.lotNumber, state.stainerFilter, state.dateFilter, state.status, state.stainName, state.oNumber, state.lotSerial, state.institution, state.requesterFilter).then(() => {
            const worksheet = XLSX.utils.json_to_sheet(stored);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Messages");
            XLSX.utils.sheet_add_aoa(worksheet, [["Order Number", "Recipient ID", "Block ID", "Slide ID", "Universal Service ID", "Date", "O-Number", "Stainer Type", "Stainer Serial Number",
                "Institution", "Requester", "Stain Finished Time", "TAT Time", "Status", "Status Changed By", "Comments"]], { origin: "A1" });
            XLSX.writeFile(workbook, "Stored Messages.xlsx", { compression: true });
        });
    }

    if (loading) {
        return <p><em>Loading...</em></p>;
    }

    return (<>
        {!loading && renderMessages()}
        {showModalMessage &&
            <Modal isOpen={showModalMessage} size='lg'>
                <ModalHeader>Provide explanation why this stain didn't meet the criteria for approval</ModalHeader>
                <Formik
                    initialValues={{ reason: "" }}
                    enableReinitialize={true}
                    validate={values => {
                        const errors = {};
                        if (!values.reason) {
                            errors.reason = 'Please provide a reason!';
                        }
                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting, setStatus }) => {
                        setMessageStatusNotes(values.reason);
                    }}>
                    {({ isSubmitting, submitForm, status, isValid }) => (
                        <><ModalBody>
                            <Form>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <AutoCompleteField autocompleteValues={disapprovalReasons.map(r => r.reason)} fieldName="reason" placeholder="Reason" invalid={`${(!isValid || showWarning)}`} ></AutoCompleteField>
                                    <ErrorMessage name="reason" component="div" className="text-danger" />
                                    <FormFeedback>Please provide a reason!</FormFeedback>
                                </FormGroup>
                                {status && <div className="text-danger">{status}</div>}
                            </Form>
                        </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={() => { submitForm(); }}>Save</Button>{' '}
                                <Button color="secondary" onClick={() => { setShowModalMessage(false); setButtonClicked(false); setShowWarning(false); }}>Cancel</Button>
                            </ModalFooter>
                        </>)}
                </Formik>
            </Modal>}
    </>);
}


