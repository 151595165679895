import React, { useEffect, useState } from 'react';
import { getAccessTokenAsync } from '../../../actions/AuthActions';
import { FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, ButtonGroup, Container } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";

export const ActionsIndex = () => {
    const [state, setState] = useState({
        data: [],
        modalOpen: false,
        error: ""
    });

    const navigate = useNavigate();
    const location = useLocation();

    const initialQuery = `UPDATE [Cases] SET [Status] = 1 WHERE [Cases].[CaseNumber] = '{caseNumber}'`
    const [formInitialValues, setInitialValues] = useState({ id: "", name: "", level: "0", active: true, query: initialQuery });

    const columns = [{
        dataField: 'name',
        text: 'Name',
        sort: true
    },
    {
        dataField: 'active',
        text: 'Active',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return (<input type="checkbox" readOnly checked={cell == true}></input>)
        }
    },
    {
        dataField: 'level',
        text: 'Level',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return cell == 0 ? "Slide" : cell == 1 ? "Case" : "Order";
        }
    },
    {
        isDummyField: true,
        dataField: '',
        text: 'Actions',
        sort: false,
        headerStyle: (column, colIndex) => {
            return { width: '180px', textAlign: 'right' };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                <ButtonGroup size="sm" className="ml-auto">
                    <Button color="primary" onClick={() => {
                        onEdit(row.id);
                    }}>Edit</Button>
                    <Button color="danger" onClick={() => {
                        onDelete(row.id);
                    }}>Delete</Button>
                </ButtonGroup>
            </>
        },
        style: () => {
            return { display: "flex" }
        }
    }];

    const handleTableChange = async (type, props) => {
        let url = `api/Actions`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        setState(prvState => { return { ...prvState, data: result } });
    }

    useEffect(() => {
        document.title = "Actions - Case workflow module";
        handleTableChange();
    }, []);

    const createNew = () => {
        setInitialValues({ id: "", name: "", level: "0", query: initialQuery, active: true });
        setState(prvState => { return { ...prvState, modalOpen: true } });
    }

    const onEdit = (id) => {
        let cq = state.data.find(d => d.id == id);
        if (cq == null) {
            return;
        }

        setInitialValues({ id: id, name: cq.name, level: cq.level, query: cq.sql, active: cq.active });
        setState(prvState => { return { ...prvState, modalOpen: true } });
    }

    const onDelete = async (id) => {
        if (id == null || !window.confirm("Are you sure you want to delete this action?")) {
            return;
        }

        let url = `api/Actions/${id}`;
        const resp = await fetch(url,
            {
                method: "DELETE",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" }
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status != 200) {
            setState({ ...state, error: "Error deleting query" });
        }

        handleTableChange();
    }

    const tryParseInt = (v) => {
        try {
            return parseInt(v);
        }
        catch (Exception) {
            return 0;
        }
    }

    const submitHandler = async (values, setStatus) => {
        let url = `api/Actions/`;
        if (values.id) {
            url += `${values.id}`;
        }

        setStatus(null);
        let l = tryParseInt(values.level);
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    Name: values.name,
                    Level: l ? l : 0,
                    Active: values.active == true,
                    Sql: values.query,
                })
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }
        if (resp.status != 200) {
            setStatus(await resp.text());
            return;
        }

        let res = await resp.json();

        setState(prvState => { return { ...prvState, modalOpen: false } });
        handleTableChange();
    }

    return (<Container>
        <div className="col">
            <h4 className="pull-left"><small><Link to="/pulz/admin">[<FontAwesomeIcon icon={faArrowLeft} /> back]</Link></small> Actions</h4>
            <Button className="pull-right mb-3" type="default" onClick={() => createNew()}><FontAwesomeIcon icon={faPlus} /> Create</Button>
            <BootstrapTable
                bootstrap4
                remote
                keyField="id"
                data={state.data}
                columns={columns}
                bordered={false}
                condensed={true}
                // sort={{ dataField: state.sortField, order: state.sortOrder }}
                // pagination={paginationFactory({ page: state.page, sizePerPage: state.sizePerPage, totalSize: state.totalSize, showTotal: true, withFirstAndLast: true })}
                onTableChange={handleTableChange}
                classes="customqueries-table"
            />
        </div>
        {state.modalOpen &&
            <Modal isOpen={state.modalOpen} size="xl">
                <ModalHeader>
                    {!formInitialValues.id && <>Create a new action</>}
                    {formInitialValues.id && <>Edit action</>}
                </ModalHeader>
                <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    validate={values => {
                        const errors = {};
                        if (!values.name) {
                            errors.name = 'Required';
                        }
                        if (!values.query) {
                            errors.query = 'Required';
                        }
                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting, setStatus }) => {
                        submitHandler(values, setStatus);
                        setSubmitting(false);
                    }}>
                    {({ isSubmitting, submitForm, status }) => (
                        <><ModalBody>
                            <Form>
                                <Field type="hidden" name="id" />
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <label>Name</label>
                                    <Field type="text" name="name" className="form-control" />
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                </FormGroup>
                                <FormGroup check className="mb-2 mt-2 mr-sm-2">
                                    <Field type="checkbox" id="active" name="active" className="form-check-input" />
                                    <Label check for="active">Active</Label>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <label>Level</label>
                                    <Field as="select" name="level" className="form-control" >
                                        <option value="0">Slide</option>
                                        <option value="1">Case</option>
                                        <option value="2">Order</option>
                                    </Field>
                                    <ErrorMessage name="level" component="div" className="text-danger" />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <label>Query</label>
                                    <Field component="textarea" name="query" className="form-control" />
                                    <ErrorMessage name="query" component="div" className="text-danger" />
                                    <small className="form-text text-muted">
                                        You can use &#123;slideId&#125; to be replaced with the slide id, &#123;caseNumber&#125; for the case number and &#123;orderId&#125; for the order id
                                    </small>
                                </FormGroup>
                                {status && <div className="text-danger">{status}</div>}
                            </Form>
                        </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={() => { submitForm(); }}>Save</Button>{' '}
                                <Button type="button" color="secondary" onClick={() => setState({ ...state, modalOpen: false })}>Cancel</Button>
                            </ModalFooter>
                        </>
                    )}
                </Formik>
            </Modal>}
    </Container>);
}