import React, { Component } from 'react';
import { Container, Navbar, NavbarBrand, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { AppContext } from '../../State/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export class NavMenu extends Component {
    static displayName = NavMenu.name;
    static contextType = AppContext;


    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container className="d-sm-inline-flex flex-sm-row" style={{ maxWidth: "100%" }}>
                        <NavLink className="text-dark" style={{ marginTop: 9.5 }} tag={(props) => <Link {...props} />} to="/">Pathology Lab Workspace</NavLink>
                        <NavbarBrand style={{ flexGrow: 1, marginLeft: "auto", marginRight: "auto" }} tag={(props) => <Link {...props} />} to="/vas/home">Verification of automated staining </NavbarBrand>

                        <ul className="navbar-nav" style={{ marginTop: 3 }}>
                            <NavItem>
                                <NavLink tag={(props) => <Link {...props} />} className="text-dark" to="/vas/home">Messages</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={(props) => <Link {...props} />} className="text-dark" to="/vas/timeline">Timeline</NavLink>
                            </NavItem>
                            <UncontrolledDropdown nav>
                                <DropdownToggle nav caret className="text-dark">Configuration</DropdownToggle>
                                <DropdownMenu end>
                                    <NavLink tag={(props) => <Link {...props} />} className="text-dark" style={{ whiteSpace: "nowrap" }} to="/vas/reasons">Disapproval Reasons</NavLink>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            {this.context?.state?.isLogin &&
                                <NavItem>
                                    <NavLink tag={(props) => <Link {...props} />} className="text-dark" to="/signout">
                                        <FontAwesomeIcon icon={faUser} />&nbsp;
                                        <span className="pl-1">{this.context?.state?.user ?? "Signout"}</span>
                                    </NavLink>
                                </NavItem>
                            }
                        </ul>

                    </Container>
                </Navbar>
            </header>
        );
    }
}
