import React, { useState, useEffect } from 'react';
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { Field, useFormikContext } from 'formik';

export const AutoCompleteField = ({ autocompleteValues, fieldName, ...props }) => {
    const { values, setFieldValue } = useFormikContext();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [focused, setFocused] = React.useState(false)
    const onFocus = () => setFocused(true)

    useEffect(() => {
        if (!autocompleteValues) {
            return;
        }

        if (!focused) {
            setDropdownOpen(false);
            return;
        }

        let filteredValues = getFilteredValues();
        if (filteredValues.length > 0) {
            setDropdownOpen(true);
        }
        else {
            setDropdownOpen(false);
        }
    }, [focused, autocompleteValues, values[fieldName]]);

    const getFilteredValues = () => {
        if (!autocompleteValues) {
            return [];
        }

        if (!values?.[fieldName]) {
            return autocompleteValues.filter(v => v);
        }

        return autocompleteValues.filter(v => v && v.toLocaleLowerCase().indexOf(values[fieldName].toLocaleLowerCase()) > -1);
    }

    return (<>
        <Dropdown isOpen={dropdownOpen} toggle={() => { setDropdownOpen(!dropdownOpen) }}>
            <DropdownToggle tag="div" onClick={() => { setDropdownOpen(!dropdownOpen) }}>
                <Field type="text" name={fieldName} className="form-control" onFocus={onFocus} onBlur={onFocus} {...props} />
            </DropdownToggle>
            <DropdownMenu style={{ width: "100%" }}>
                {getFilteredValues().map(v => <DropdownItem key={v} onClick={() => { setFieldValue(fieldName, v); setFocused(false); setDropdownOpen(false); }}>{v}</DropdownItem>)}
            </DropdownMenu>
        </Dropdown>
    </>);
}