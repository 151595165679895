import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";
import useSyncDataContext from "../state/useSyncData";
import { getAccessTokenAsync } from '../../../actions/AuthActions';
import { TransformWrapper, TransformComponent, MiniMap } from "react-zoom-pan-pinch";
import { PageLinksDropdown } from '../ui/PageLinksDropdown';
import { Row } from 'reactstrap';

export const Macro = () => {
    const syncDataContext = useSyncDataContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [serverCreds, setServerCreds] = useState();

    const fetchServerSettings = async () => {
        const res = await fetch(`api/Settings`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (res.status == 404) {
            return;
        }

        var screds = await res.json();

        const res1 = await fetch(`api/PmaCoreSessionId`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res1.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (res1.status == 404) {
            return;
        }

        var sessionIdResult = await res1.json();

        setServerCreds({ username: screds.username, serverUrl: screds.serverUrl, path: screds.path, sessionId: sessionIdResult.sessionId });
    }

    useEffect(() => {
        document.title = `Macro browser for ${syncDataContext.data.caseNumber} - PIMS`;
    }, [syncDataContext.data]);

    useEffect(() => {
        document.title = "Macro browser - PIMS";
        fetchServerSettings();
    }, []);

    if (!serverCreds) {
        return <div>Loading ...</div>;
    }

    if (!syncDataContext.data.selectedSlidePaths || syncDataContext.data.selectedSlidePaths.length == 0) {
        return <div>No slide selected</div>;
    }

    return (<>
        <Row>
            <div className='col'>
                <h3>Case {syncDataContext.data.caseNumber} Macro Browser</h3>
            </div>

            <div className='col-2 justify-content-end'>
                <PageLinksDropdown></PageLinksDropdown>
            </div>
        </Row>

        {syncDataContext.data.selectedSlidePaths.length === 0 && <div>No slide selected</div>}
        <div className="d-flex flex-wrap">
            {syncDataContext.data.selectedSlidePaths.map((x, idx) => {
                const imgEl = (<img
                    alt={`${x}`}
                    src={`${serverCreds.serverUrl}macro?sessionID=${encodeURIComponent(serverCreds.sessionId)}&pathOrUid=${encodeURIComponent(x)}&w=0&h=2000`}
                />);

                return (<div key={idx} className="m-2" style={{ border: "1px #dfdfdf solid", position: "relative", borderRadius: "5px" }}>
                    <TransformWrapper panning={{ velocityDisabled: true }} centerOnInit={true} minScale={0.2} initialScale={0.2}>
                        <div
                            style={{
                                position: "absolute",
                                zIndex: 5,
                                top: "5px",
                                right: "5px",
                            }}
                        >
                            <MiniMap width={100}>{imgEl}</MiniMap>
                        </div>
                        <TransformComponent wrapperStyle={{ height: "600px", width: "600px", margin: "0 auto" }}>
                            {imgEl}
                        </TransformComponent>
                    </TransformWrapper>

                    <img
                        alt={`${x}`}
                        src={`${serverCreds.serverUrl}barcode?sessionID=${encodeURIComponent(serverCreds.sessionId)}&pathOrUid=${encodeURIComponent(x)}&w=0&h=150`}
                        style={{ display: "block", height: "150px", margin: "0 auto" }}
                    />
                    <h5 className="text-center p-2">{syncDataContext.data.selectedSlideNames[idx]}</h5>
                </div>);
            })}
            <div className='col-2 d align-items-end' style={{ position: 'absolute', top: '55px', right: '24px' }}>

            </div>
        </div>
    </>);
}
