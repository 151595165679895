import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { AppContext } from '../State/AppContext';
import { useNavigate } from 'react-router-dom';

const RequiredAuth = ({ children }) => {
    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!appContext.state.isLogin) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
        }

    }, [appContext.state]);

    if (!appContext.state.isLogin) {
        return <></>;
    }

    return children;
}

export { RequiredAuth };