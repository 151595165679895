import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button } from "reactstrap"
import { userLogout } from "../../actions/AuthActions";
import { AppContext } from '../../State/AppContext';
import logo from "../../img/logo.svg";

export const Signout = () => {
    const context = useContext(AppContext);
    const navigate = useNavigate();

    const onSignoutClicked = () => {
        userLogout(context);
    }

    return (
        <Container fluid>
            <div className="row justify-content-md-center">
                <div className="col-6">
                    <img className="w-100" src={logo} alt="Genesys Pixelator" />
                    <h1>Sign out</h1>
                    <br />
                    <p>Press the button below to signout.</p>
                    <Button color="primary" onClick={onSignoutClicked}>Sign Out</Button>
                    <Button color="light" className="ml-2" onClick={() => navigate(-1)}>Cancel</Button>
                </div>
            </div>
        </Container>
    );
}
