import React, { useEffect, useState } from 'react';
import { getAccessTokenAsync } from '../../../actions/AuthActions';
import { DropdownToggle, DropdownItem, DropdownMenu, FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, Container, Dropdown } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";

export const StatusIndex = () => {
    const [state, setState] = useState({
        data: [],
        modalOpen: false,
        error: ""
    });

    const navigate = useNavigate();
    const location = useLocation();
    const [formInitialValues, setInitialValues] = useState({ id: "", name: "", level: "0", color: "#000", icon: "" });

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            sort: true
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true
        },
        {
            dataField: 'color',
            text: 'Color',
            sort: false,
            formatter: (cell) => (<div className="card" style={{ backgroundColor: cell, height: "24px", width: "50%" }} ></div>)
        },
        {
            dataField: 'icon',
            text: 'Icon',
            sort: false,
            formatter: (cell) => {
                var v = cell.split("_");
                if (v.length == 1) {
                    v = ["fas", v];
                }

                return (<FontAwesomeIcon icon={v}></FontAwesomeIcon>);
            }
        },
        {
            dataField: 'level',
            text: 'Level',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                switch (cell) {
                    case 0: return "Slide";
                    case 1: return "Case";
                    case 2: return "Order";
                    case 3: return "Recipient";
                    case 4: return "Block";
                }
            }
        },
        {
            isDummyField: true,
            dataField: '',
            text: 'Actions',
            sort: false,
            headerStyle: (column, colIndex) => {
                return { width: '180px', textAlign: 'right' };
            },
            formatter: (cell, row, rowIndex) => {
                return <>
                    <ButtonGroup size="sm" className="ml-auto">
                        <Button color="primary" onClick={() => {
                            onEdit(row.id);
                        }}>Edit</Button>
                        <Button color="danger" disabled={row.id <= 13} onClick={() => {
                            onDelete(row.id);
                        }}>Delete</Button>
                    </ButtonGroup>
                </>
            },
            style: () => {
                return { display: "flex" }
            }
        }];

    const handleTableChange = async (type, props) => {
        let url = `api/StatusDescriptions`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        if (props) {
            result = result.sort((a, b) => {
                if (a[props.sortField] > b[props.sortField]) {
                    return props.sortOrder === 'asc' ? 1 : -1;
                } else if (b[props.sortField] > a[props.sortField]) {
                    return props.sortOrder === 'asc' ? -1 : 1;
                }
                return 0;
            });
        }

        setState(prvState => { return { ...prvState, data: result } });
    }

    const generateIconList = () => {
        var icons = Object.entries(far).map(v => ["far", v[1].iconName]).concat(Object.entries(fas).map(v => ["fas", v[1].iconName]));
        return icons;
    }

    useEffect(() => {
        document.title = "Statuses - Case workflow module";
        handleTableChange();
    }, []);

    const createNew = () => {
        setInitialValues({ id: "", name: "", level: "0", color: "#000", icon: "" });
        setState(prvState => { return { ...prvState, modalOpen: true } });
    }

    const onEdit = (id) => {
        let cq = state.data.find(d => d.id == id);
        if (cq == null) {
            return;
        }

        setInitialValues({ id: id, name: cq.name, level: cq.level, color: cq.color, icon: cq.icon });
        setState(prvState => { return { ...prvState, modalOpen: true } });
    }

    const onDelete = async (id) => {
        if (id == null || !window.confirm("Are you sure you want to delete this status?")) {
            return;
        }

        let url = `api/StatusDescriptions/${id}`;
        const resp = await fetch(url,
            {
                method: "DELETE",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" }
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status != 200) {
            setState({ ...state, error: "Error deleting query" });
        }

        handleTableChange();
    }

    const tryParseInt = (v) => {
        try {
            return parseInt(v);
        }
        catch (Exception) {
            return 0;
        }
    }

    const submitHandler = async (values, setStatus) => {
        let url = `api/StatusDescriptions/`;
        if (values.id) {
            url += `${values.id}`;
        }

        setStatus(null);
        let l = tryParseInt(values.level);
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    Name: values.name,
                    Level: l ? l : 0,
                    Color: values.color,
                    Icon: values.icon,
                })
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status != 200) {
            setStatus(await resp.text());
            return;
        }

        let res = await resp.json();

        setState(prvState => { return { ...prvState, modalOpen: false } });
        handleTableChange();
    }

    return (<Container>
        <div className="col">
            <h4 className="pull-left"><small><Link to="/pulz/admin">[<FontAwesomeIcon icon={faArrowLeft} /> back]</Link></small> Statuses</h4>
            <Button className="pull-right mb-3" type="default" onClick={() => createNew()}><FontAwesomeIcon icon={faPlus} /> Create</Button>
            <BootstrapTable
                bootstrap4
                remote
                keyField="id"
                data={state.data}
                columns={columns}
                bordered={false}
                condensed={true}
                sort={{ dataField: state.sortField, order: state.sortOrder }}
                // pagination={paginationFactory({ page: state.page, sizePerPage: state.sizePerPage, totalSize: state.totalSize, showTotal: true, withFirstAndLast: true })}
                onTableChange={handleTableChange}
                classes="customqueries-table"
            />
        </div>
        {state.modalOpen &&
            <Modal isOpen={state.modalOpen} size="xl">
                <ModalHeader>
                    {!formInitialValues.id && <>Create a new status</>}
                    {formInitialValues.id && <>Edit status</>}
                </ModalHeader>
                <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    validate={values => {
                        const errors = {};
                        if (!values.name) {
                            errors.name = 'Required';
                        }
                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting, setStatus }) => {
                        submitHandler(values, setStatus);
                        setSubmitting(false);
                    }}>
                    {({ isSubmitting, submitForm, status }) => (
                        <><ModalBody>
                            <Form>
                                <Field type="hidden" name="id" />
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <label>Name</label>
                                    <Field type="text" name="name" className="form-control" />
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                </FormGroup>
                                <div className="form-row">
                                    <FormGroup className="col-4 mb-2 mrmb-sm-0">
                                        <label htmlFor="level">Level</label>
                                        <Field as="select" name="level" className="form-control" >
                                            <option value="0">Slide</option>
                                            <option value="1">Case</option>
                                            <option value="2">Order</option>
                                            <option value="3">Recipient</option>
                                            <option value="4">Block</option>
                                        </Field>
                                        <ErrorMessage name="level" component="div" className="text-danger" />
                                    </FormGroup>
                                    <FormGroup className="col-4 mb-2 mb-sm-0">
                                        <label>Color</label>
                                        <Field type="color" name="color" className="form-control" >
                                            {({ field, form, meta }) => (
                                                <div className="w-100">
                                                    <input type="color" {...field} className="form-control" />
                                                </div>
                                            )}
                                        </Field>
                                        <ErrorMessage name="color" component="div" className="text-danger" />
                                    </FormGroup>
                                    <FormGroup className="col-4 mb-2 mb-sm-0">
                                        <label htmlFor="icon">Icon</label>
                                        <AutoCompleteField autocompleteValues={generateIconList()} name="icon" />
                                        <ErrorMessage name="icon" component="div" className="text-danger" />
                                    </FormGroup>
                                </div>
                                {status && <div className="text-danger">{status}</div>}
                            </Form>
                        </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={() => { submitForm(); }}>Save</Button>{' '}
                                <Button type="button" color="secondary" onClick={() => setState({ ...state, modalOpen: false })}>Cancel</Button>
                            </ModalFooter>
                        </>
                    )}
                </Formik>
            </Modal>}
    </Container>);
}

const AutoCompleteField = ({ autocompleteValues, name }) => {
    const { values, setFieldValue } = useFormikContext();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [focused, setFocused] = React.useState(false)
    const onFocus = () => setFocused(true)

    useEffect(() => {
        if (!autocompleteValues) {
            return;
        }

        if (!focused) {
            setDropdownOpen(false);
            return;
        }

        let filteredValues = getFilteredValues();
        if (filteredValues.length > 0) {
            setDropdownOpen(true);
        }
        else {
            setDropdownOpen(false);
        }
    }, [focused, autocompleteValues, values[name]]);

    const getFilteredValues = () => {
        if (!autocompleteValues) {
            return [];
        }

        if (!values?.[name]) {
            return autocompleteValues.filter(v => v);
        }

        let searchValue = values[name].toLocaleLowerCase();
        searchValue = searchValue.indexOf("_") > -1 ? searchValue.split("_")[1] : searchValue;
        return autocompleteValues.filter(v => v && v[1].toLocaleLowerCase().indexOf(searchValue) > -1);
    }

    const iconRender = (v) => {
        return (<><FontAwesomeIcon icon={v}></FontAwesomeIcon><span className="ml-2">{v[1]}</span></>);
    }
    return (<>
        <Dropdown isOpen={dropdownOpen} toggle={() => { setDropdownOpen(!dropdownOpen) }}>
            <DropdownToggle tag="div" onClick={() => { setDropdownOpen(!dropdownOpen) }}>
                <Field type="text" name={name} className="form-control" onFocus={onFocus} onBlur={onFocus} />
            </DropdownToggle>
            <DropdownMenu style={{ width: "100%", maxHeight: "400px" }} className="overflow-auto">
                {getFilteredValues().map(v => <DropdownItem key={v} onClick={() => { setFieldValue(name, `${v[0]}_${v[1]}`); setDropdownOpen(false); setFocused(false); }}>
                    {iconRender(v)}
                </DropdownItem>)}
            </DropdownMenu>
        </Dropdown>
    </>);
}