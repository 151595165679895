import React, { Component } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, FormGroup, ModalFooter, FormFeedback } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { getAccessTokenAsync } from '../../actions/AuthActions';
import { getOBRValues, getOBXValues } from '../../actions/Types';
import { AutoCompleteField } from './AutoCompleteField';
import { Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import { getTAT } from '../../actions/Types';

export class Message extends Component {
    static displayName = Message.name;
    static colors = {
        "SAC": 'primary',
        "ZSI": 'primary',
        "PV1": 'secondary',
        "ORC": 'secondary',
        "OBR": 'dark',
        "OBX": 'dark',
        "PID": 'light',
    };

    constructor(props) {
        super(props);
        this.state = {
            buttonClicked: false,
            modalMessage: false,
            showWarning: false
        };

        this.messageStatusNotes = "";
        let msg = this.props.value;
        this.parsedMessage = JSON.parse(this.props.value.messageJson);
        let { slideId, orderNumber, specimenReceivedDateTime } = getOBRValues(this.parsedMessage);
        let { requester, stainingRunCompletedTime } = getOBXValues(this.parsedMessage);

        this.tat = getTAT(specimenReceivedDateTime, stainingRunCompletedTime);

        this.slideId = slideId;
        this.caseNumber = msg.orderNumber;
        if (this.caseNumber.length < 8) {
            this.caseNumber = this.caseNumber.substring(0, 3) + this.caseNumber.substring(3).padStart(5, '0');
        }

        this.orderNumber = `${msg.orderNumber} - ${msg.uzbRecipientId} - ${msg.uzbBlockId} - ${msg.uzbSlideId}`;
        this.requester = requester;
        this.stainingRunCompletedTime = stainingRunCompletedTime;

        var ids = this.props.identifiers;
        this.counts = this.parsedMessage.reduce((acc, val) => {
            if (ids && ids.hasOwnProperty(val.Name) && val.Name !== "MSH") {
                var variant = Message.colors[val.Name];
                acc[val.Name] = (acc[val.Name] || { Name: ids[val.Name], variant: variant, count: 0 });
                acc[val.Name].count++;
            }
            return acc;
        }, {});
    }


    async postApprove(approve) {
        var response = await fetch(`api/message/approve`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + getAccessTokenAsync() },
                body: JSON.stringify({
                    Id: this.props.value.id,
                    Approve: approve,
                    Notes: this.messageStatusNotes
                })
            });

        const result = await response.json();
        this.setState({ modalMessage: false, buttonClicked: false, showWarning: false });

        if (result === true) {
            this.props.messageChanged(this.props.value.id);
            return;
        }

        console.warn("Server error for this message");
    }

    async approveClick(approve) {
        this.setState({ buttonClicked: true });
        if (!approve && this.props.value.status !== 2) {
            this.messageStatusNotes = "";
            this.setState({ modalMessage: true });
        }
        else {
            await this.postApprove(approve);
        }
    }

    modalMessageChanged(e) {
        this.messageStatusNotes = e.target.value;
    }

    render() {
        return (
            <div className="col-md-4 mb-3">
                <div className={"card " + (this.props.value.status === 1 ? "border-success" : this.props.value.status === 2 ? "border-danger" : "")}>
                    <div className="card-body">
                        <h5 className="card-title d-flex">
                            <span className="d-flex flex-column">
                                <span><strong>{this.orderNumber}</strong></span>
                                <span><a href={"/vas/home?stainname=" + encodeURIComponent(this.props.value.universalServiceId)}>{this.props.value.universalServiceId}</a></span>
                                <small className="text-muted">
                                    <Moment format="LLLL">
                                        {this.props.value.messageDate}
                                    </Moment>
                                </small>
                            </span>
                            <div className="ml-auto d-flex flex-column text-right" id={"buttons_" + this.props.value.id}>
                                <div>
                                    <Button color="link" className="" onClick={this.approveClick.bind(this, true)}>
                                        <FontAwesomeIcon spin={this.state.buttonClicked} className={this.props.value.status === 1 ? 'text-success' : 'text-muted'} icon={!this.state.buttonClicked ? faThumbsUp : faSpinner}></FontAwesomeIcon>
                                    </Button>
                                    <Button color="link" className="" onClick={this.approveClick.bind(this, false)}>
                                        <FontAwesomeIcon spin={this.state.buttonClicked} className={this.props.value.status === 2 ? 'text-danger' : 'text-muted'} icon={!this.state.buttonClicked ? faThumbsDown : faSpinner}></FontAwesomeIcon>
                                    </Button>
                                </div>
                            </div>
                        </h5>

                        <div className="card-text">
                            <div>O-number: <a href={"/vas/home?onumber=" + encodeURIComponent(this.props.value.oNumber)}>{this.props.value.oNumber}</a></div>
                            <div>Stainer type: {this.props.value.stainer}</div>
                            <div>Stainer serial number: <a href={"/vas/home?stainer=" + encodeURIComponent(this.props.value.stainerSerial)}>{this.props.value.stainerSerial}</a></div>
                            <div>Institution: <a href={"/vas/home?institution=" + encodeURIComponent(this.props.value.institution)}>{this.props.value.institution || "n/a"}</a></div>
                            <div>Requester: {this.props.value.requester || "n/a"}</div>
                            <div>Stain finished time: <Moment className="ml-1" parse="YYYYMMDDHHmm" format="LLLL">{this.stainingRunCompletedTime}</Moment></div>
                            <div>TAT time: {this.tat || "n/a"}</div>
                            {this.props.value.status !== 0 &&
                                <div>
                                    <strong>Status </strong>{this.props.value.status === 1 ? <span className="text-success">OK</span> : <span className="text-danger">FAILED</span>}
                                    <small className="pl-1">
                                        <strong>{this.props.value.status === 1 ? " - approved " : " - disapproved "} by </strong>{this.props.value.statusChangedBy}
                                        <strong className="pl-1"> on </strong><Moment subtract={{ minutes: (new Date()).getTimezoneOffset() }} format="LLLL">{this.props.value.statusChangedOn}</Moment>
                                    </small>
                                    <div>{this.props.value.statusNotes}</div>
                                </div>}

                            <div className="btn-group mt-2" role="group">
                                <Link className="btn btn-link" to={{
                                    pathname: '/vas/message/' + this.props.value.id,
                                    state: {
                                        message: this.props.value,
                                        identifiers: this.props.identifiers
                                    }
                                }}> Details</Link>

                                {this.caseNumber &&
                                    <a href={"/vas/slides/" + this.caseNumber} className="btn btn-link">Slides</a>
                                }

                                {this.props.value.lotNumbers && this.props.value.lotNumbers.length > 0 &&
                                    <UncontrolledDropdown tag="button" className="btn btn-link">
                                        <DropdownToggle tag="span" data-toggle="dropdown" caret>Lot numbers</DropdownToggle>
                                        <DropdownMenu>
                                            {this.props.value.lotNumbers.map(l =>
                                                <DropdownItem tag="a" href={"/vas/home?lotnumber=" + encodeURIComponent(l)} className="py-1" key={l}>{l}</DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.modalMessage &&
                    <Modal isOpen={this.state.modalMessage} size='lg'>
                        <ModalHeader>Provide explanation why this stain didn't meet the criteria for approval</ModalHeader>
                        <Formik
                            initialValues={{ reason: "" }}
                            enableReinitialize={true}
                            validate={values => {
                                const errors = {};
                                if (!values.reason) {
                                    errors.reason = 'Please provide a reason!';
                                }
                                return errors;
                            }}

                            onSubmit={(values, { setSubmitting, setStatus }) => {
                                this.messageStatusNotes = values.reason;
                                if (this.messageStatusNotes === "") {
                                    this.setState({ showWarning: true });
                                }
                                else {
                                    this.postApprove(false);
                                }
                            }}>
                            {({ isSubmitting, submitForm, status, isValid }) => (
                                <><ModalBody>
                                    <Form>
                                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                            <AutoCompleteField autocompleteValues={this.props.disapprovalReasons.map(r => r.reason)} fieldName="reason" placeholder="Reason" invalid={`${(!isValid || this.state.showWarning)}`} ></AutoCompleteField>
                                            <ErrorMessage name="reason" component="div" className="text-danger" />
                                            <FormFeedback>Please provide a reason!</FormFeedback>
                                        </FormGroup>
                                        {status && <div className="text-danger">{status}</div>}
                                    </Form>
                                </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" onClick={() => { submitForm(); }}>Save</Button>{' '}
                                        <Button color="secondary" onClick={() => this.setState({ modalMessage: false, buttonClicked: false, showWarning: false })}>Cancel</Button>
                                    </ModalFooter>
                                </>)}
                        </Formik>
                    </Modal>}
            </div>
        );
    }
}
