import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAccessTokenAsync } from '../../actions/AuthActions';
import './Slides.css'
import { getWsiIframeUrl } from './Helpers';

const iframeStyle = {
    border: 0
}

export const Slides = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { caseNumber } = useParams();
    const [state, setState] = useState({
        id: null,
        loaded: false,
        link: null,
        serverCreds: {}
    });
    const slideInformation = location.search.replace("?", "");

    const fetchServerSettings = async () => {
        const res = await fetch(`api/Settings`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (res.status === 404) {
            return;
        }

        var screds = await res.json();

        const res1 = await fetch(`api/PmaCoreSessionId`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res1.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (res1.status === 404) {
            return;
        }

        var sessionIdResult = await res1.json();
        setState({ ...state, serverCreds: { username: screds.username, serverUrl: screds.serverUrl, path: screds.path, sessionId: sessionIdResult.sessionId } });
    }

    useEffect(() => {
        document.title = "Slides viewer - Verification of automated staining";
        fetchServerSettings();
    }, []);

    useEffect(() => {
        if (state.serverCreds && state.serverCreds.serverUrl && !state.loaded && caseNumber) {
            let lnk = getWsiIframeUrl(caseNumber, state.serverCreds.serverUrl, state.serverCreds.sessionId, state.serverCreds.path[0]);
            setState({ ...state, loaded: true, id: caseNumber, link: lnk });
        }

    }, [state.serverCreds, state.loaded]);

    return (
        !state.loaded ?
            <p><em>Loading...</em></p>
            :
            <iframe title="WSI" src={state.link} style={iframeStyle} className="w-100 flex-grow-1" allowFullScreen={true}></iframe>
    );
}