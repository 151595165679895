export const getWsiIframeUrl = (caseNumber, serverUrl, sessionId, serverPath, slideInfo) => {
    const urlKey = encodeURIComponent(caseNumber.substring(0, 3)) + "/" + encodeURIComponent(caseNumber.substring(0, 5)) + "/" + encodeURIComponent(caseNumber.substring(0, 7)) + "/" + encodeURIComponent(caseNumber.substring(0, 9));
    let lnk;
    if (slideInfo) {
        lnk = `/slides.html?serverUrl=${encodeURIComponent(serverUrl)}&sessionId=${sessionId}&sb=true&path=${serverPath}${urlKey}&slideInfo=${slideInfo}`;
    }
    else {
        lnk = `/slides.html?serverUrl=${encodeURIComponent(serverUrl)}&sessionId=${sessionId}&sb=true&path=${serverPath}${urlKey}`;
    }

    return lnk;
}

