import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { getAccessTokenAsync } from "../../../actions/AuthActions";
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Form, FormGroup, Label, Input, FormFeedback, Alert } from "reactstrap";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Queries = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [modalOpen, setModalOpen] = useState(false);
    const [queriesList, setQueriesList] = useState([]);
    const [rowId, setRowId] = useState(null);
    const [nameValid, setNameValid] = useState(true);
    const [queryValid, setQueryValid] = useState(true);
    const [visible, setVisible] = useState(false);
    const [error, setError] = useState(null);

    const onDismiss = () => {
        setVisible(false);
    }

    useEffect(() => {
        setTimeout(() => {
            onDismiss();
        }, 2500);

        clearTimeout();
    }, [visible]);


    // Toggle modal state
    const toggleModal = () => setModalOpen(!modalOpen);

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            sort: false,
            headerStyle: (column, colIndex) => {
                return { width: '70px', textAlign: 'left' };
            },
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: false,
            headerStyle: (column, colIndex) => {
                return { width: '400px', textAlign: 'left' };
            },
        },
        {
            dataField: 'query',
            text: 'Query',
            sort: false,
            headerStyle: (column, colIndex) => {
                return { width: '500px', textAlign: 'left' };
            },
        },
        {
            isDummyField: true,
            dataField: '',
            text: 'Actions',
            sort: false,
            headerStyle: (column, colIndex) => {
                return { width: '150px', textAlign: 'left' };
            },
            formatter: (cell, row, rowIndex) => {
                return <>
                    <ButtonGroup size="sm" className="ml-auto">
                        <Button color="primary" onClick={() => {
                            toggleModal();
                            setRowId(row.id);
                        }}>Edit</Button>
                        <Button color="danger" onClick={() => {
                            deleteQuery(row.id);
                        }}>Delete</Button>
                    </ButtonGroup>
                </>
            },

            style: () => {
                return { display: "flex" }
            }
        }];


    useEffect(() => {
        document.title = "Queries - Pathology Image Management System";
        handleTableChange();
        fetchQueries();
    }, []);


    const handleTableChange = async () => {
        let url = `api/WsiQueries`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        setQueriesList(result);
    }


    const fetchQueries = async () => {
        const resp = await fetch('api/WsiQueries',
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        let result = await resp.json();
        setQueriesList(result);
    }

    const createNew = async (name, query) => {
        let url = `api/WsiQueries/`;
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    Name: name,
                    Query: query
                })
            });

        let result = await resp.text();
        if (resp.status !== 200) {
            if (resp.status === 400) {
                setError(result);
                setVisible(true);
            }

            return;
        }
        
        setNameValid(true);
        setQueryValid(true);
        handleTableChange();
    }

    const editQuery = async (id, name, query) => {
        if (!window.confirm("Are you sure you want to edit this query?")) {
            return;
        }
        let url = `api/WsiQueries/${id}`;
        const resp = await fetch(url,
            {
                method: "PUT",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    Name: name,
                    Query: query
                })
            });
        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.text();
        if (resp.status !== 200) {
            if (resp.status === 400) {
                setError(result);
                setVisible(true);
            }

            return;
        }

        setRowId(null);
        setNameValid(true);
        setQueryValid(true);
        handleTableChange();
    }

    const deleteQuery = async (id) => {
        if (id === null || !window.confirm("Are you sure you want to delete this query?")) {
            return;
        }

        let url = `api/WsiQueries/${id}`;
        const resp = await fetch(url,
            {
                method: "DELETE",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" }
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status !== 200) {
            alert("Error deleting query!");
            return;
        }

        handleTableChange();
    }

    const validateForm = () => {
        let isValid = false;
        let queryName = document.getElementById("name").value;
        let queryString = document.getElementById("query").value;

        setNameValid(queryName.length !== 0);
        setQueryValid(queryString.length !== 0);
        isValid = queryName.length !== 0 && queryString.length !== 0;

        if (!isValid) {
            return;
        }

        if (rowId) {
            editQuery(rowId, queryName, queryString);
        }
        else {
            createNew(queryName, queryString);
        }
        toggleModal();
    }

    return (
        <Container>
            <Row>
                <Col style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h4>WSI queries list</h4>
                    <Button color="primary" onClick={() => { toggleModal() }}><FontAwesomeIcon icon={faPlus} />&nbsp;Create</Button>
                </Col>
                <Modal isOpen={modalOpen} toggle={toggleModal} centered={true} size="lg" backdrop="static">
                    <ModalHeader>
                        {!rowId && <>Create a query</>}
                        {rowId && <>Edit query</>}
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Label>Name</Label>
                                <Input defaultValue={queriesList.find(x => x.id === rowId)?.name ?? ''} invalid={!nameValid} type="text" name="name" id="name" className="form-control" />
                                <FormFeedback>Required</FormFeedback>
                                {nameValid && <FormFeedback tooltip></FormFeedback>}
                            </FormGroup>
                            <FormGroup>
                                <Label>Query</Label>
                                {rowId &&
                                    <Input defaultValue={queriesList.find(x => x.id === rowId)?.query ?? ''} invalid={!queryValid} type="textarea" name="query" id="query" className="form-control" />
                                }
                                {!rowId &&
                                    <Input defaultValue={`SELECT TOP 100\nWsis.Name, Cases.CaseNumber\nFROM Wsis\nINNER JOIN Slides ON Wsis.SlideId = Slides.Id\nINNER JOIN Cases ON Slides.CaseId = Cases.Id\nORDER BY Wsis.Id DESC` ?? ""}
                                        invalid={!queryValid} type="textarea" name="query" id="query" className="form-control" />
                                }
                                <FormFeedback>Required</FormFeedback>
                                {queryValid && <FormFeedback tooltip></FormFeedback>}
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {!rowId &&
                            <Button color="primary" onClick={() => { validateForm(); }}>Create</Button>
                        }
                        {rowId &&
                            <Button color="primary" onClick={() => { validateForm(); }}>Save</Button>
                        }
                        <Button color="secondary" onClick={() => { toggleModal(); setRowId(null); }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </Row>
            <Row>
                <Col>
                    {queriesList.length > 0 &&
                        < BootstrapTable
                            bootstrap4
                            remote
                            keyField="id"
                            data={queriesList}
                            columns={columns}
                            bordered={true}
                            condensed={true}
                            headerClasses="table-light"
                            onTableChange={handleTableChange}
                        >
                        </BootstrapTable>
                    }

                    <Alert color="danger" isOpen={visible} toggle={onDismiss} id="queryAlert">
                        {error}
                    </Alert>

                </Col>
            </Row>
        </Container >
    );
}