import React, { Component, useContext, useState } from 'react';
import { Container, Navbar, NavbarBrand, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../State/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import logo from "../../../img/logo.svg";

const NavMenu = () => {
    const context = useContext(AppContext);

    return <header>




        <Navbar className="navbar-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
            <Container className="d-sm-inline-flex flex-sm-row" style={{ maxWidth: "100%" }}>
                <NavLink className="text-dark" tag={(props) => <Link {...props} />} to="/"><img style={{ height: "50px" }} src={logo} alt="Genesys Pixelator" /> </NavLink>
                <NavbarBrand style={{ flexGrow: 1, marginLeft: "auto", marginRight: "auto" }} tag={(props) => <Link {...props} />} to="/pulz/home">PIMS</NavbarBrand>


                {context?.state?.isLogin &&
                    <NavLink tag={(props) => <Link {...props} />} className="text-dark" style={{ marginTop: 9.5 }} to="/signout">
                        <FontAwesomeIcon icon={faUser} />&nbsp;
                        <span className="pl-1">{context?.state?.user ?? "Signout"}</span>
                    </NavLink>
                }

            </Container>
        </Navbar>

    </header>;
}

export { NavMenu };