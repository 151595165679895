import React, { useEffect, useState } from 'react';
import { getAccessTokenAsync } from '../../../actions/AuthActions';
import { FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, Container } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";

export const Mappings = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        data: [],
        modalOpen: false,
        error: ""
    });

    const [formInitialValues, setInitialValues] = useState({ id: "", name: "", inputPath: "", outputPath: "", algorithm: "0" });

    const columns = [{
        dataField: 'name',
        text: 'Name',
        sort: true
    },
    {
        dataField: 'algorithm',
        text: 'Algorithm',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            if (cell === -1) {
                return "";
            }
            else if (cell === 0) {
                return "Routine sorting";
            }
            else if (cell === 1) {
                return "Six character sorting";
            }
        }
    },
    {
        dataField: 'inputPath',
        text: 'Source',
        sort: true
    },
    {
        dataField: 'outputPath',
        text: 'Destination',
        sort: true
    },
    {
        isDummyField: true,
        dataField: '',
        text: 'Actions',
        sort: false,
        headerStyle: (column, colIndex) => {
            return { width: '180px', textAlign: 'left' };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                <ButtonGroup size="sm" className="ml-auto">
                    <Button color="primary" onClick={() => {
                        onEdit(row.id);
                    }}>Edit</Button>
                    <Button color="danger" onClick={() => {
                        onDelete(row.id);
                    }}>Delete</Button>
                </ButtonGroup>
            </>
        },
        style: () => {
            return { display: "flex" }
        }
    }];

    const handleTableChange = async (type, props) => {
        let url = `api/PimsMappings`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        setState(prvState => { return { ...prvState, data: result } });
    }

    useEffect(() => {
        document.title = "PULZ - Directory mappings";
        handleTableChange();
    }, []);

    const createNew = () => {
        setInitialValues({ id: "", name: "", inputPath: "", outputPath: "", algorithm: "0" });
        setState(prvState => { return { ...prvState, modalOpen: true } });
    }

    const onEdit = (id) => {
        let cq = state.data.find(d => d.id === id);
        if (cq == null) {
            return;
        }

        setInitialValues({ id: id, name: cq.name, inputPath: cq.inputPath, outputPath: cq.outputPath, algorithm: cq.algorithm });
        setState(prvState => { return { ...prvState, modalOpen: true } });
    }

    const onDelete = async (id) => {
        if (id == null || !window.confirm("Are you sure you want to delete this mapping?")) {
            return;
        }

        let url = `api/PimsMappings/${id}`;
        const resp = await fetch(url,
            {
                method: "DELETE",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" }
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status !== 200) {
            setState({ ...state, error: "Error deleting mapping" });
        }

        handleTableChange();
    }

    const tryParseInt = (v) => {
        try {
            return parseInt(v);
        }
        catch (Exception) {
            return 0;
        }
    }

    const submitHandler = async (values, setStatus) => {
        let url = `api/PimsMappings/`;
        if (values.id) {
            url += `${values.id}`;
        }

        setStatus(null);
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    Name: values.name,
                    InputPath: values.inputPath,
                    OutputPath: values.outputPath,
                    Algorithm: tryParseInt(values.algorithm)
                })
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }
        if (resp.status !== 200) {
            setStatus(await resp.text());
            return;
        }

        let res = await resp.json();

        setState(prvState => { return { ...prvState, modalOpen: false } });
        handleTableChange();
    }

    return (<Container>
        <div className="col">
            <h4 className="pull-left"><small><Link to="/pims/home">[<FontAwesomeIcon icon={faArrowLeft} /> back]</Link></small> PIMS mappings</h4>
            <Button className="pull-right mb-3" type="default" onClick={() => createNew()}><FontAwesomeIcon icon={faPlus} /> Create</Button>
            <BootstrapTable
                bootstrap4
                remote
                keyField="id"
                data={state.data}
                columns={columns}
                bordered={false}
                condensed={true}
                // sort={{ dataField: state.sortField, order: state.sortOrder }}
                // pagination={paginationFactory({ page: state.page, sizePerPage: state.sizePerPage, totalSize: state.totalSize, showTotal: true, withFirstAndLast: true })}
                onTableChange={handleTableChange}
                classes="customqueries-table"
            />
        </div>
        {state.modalOpen &&
            <Modal isOpen={state.modalOpen} size="xl">
                <ModalHeader>
                    {!formInitialValues.id && <>Create a new mapping</>}
                    {formInitialValues.id && <>Edit mapping</>}
                </ModalHeader>
                <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    validate={values => {
                        const errors = {};
                        if (!values.name) {
                            errors.name = 'Required';
                        }

                        if (!values.inputPath) {
                            errors.inputPath = 'Required';
                        }

                        if (!values.outputPath) {
                            errors.outputPath = 'Required';
                        }

                        if (values.algorithm === "-1") {
                            errors.algorithm = 'Required';
                        }

                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting, setStatus }) => {
                        submitHandler(values, setStatus);
                        setSubmitting(false);
                    }}>
                    {({ isSubmitting, submitForm, status }) => (
                        <><ModalBody>
                            <Form>
                                <Field type="hidden" name="id" />
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <label>Name</label>
                                    <Field type="text" name="name" className="form-control" />
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <label>Input path</label>
                                    <Field type="text" name="inputPath" className="form-control" />
                                    <ErrorMessage name="inputPath" component="div" className="text-danger" />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <label>Output path</label>
                                    <Field type="text" name="outputPath" className="form-control" />
                                    <ErrorMessage name="outputPath" component="div" className="text-danger" />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <label>Algorithm</label>
                                    <Field as="select" name="algorithm" className="form-control" >
                                        <option value="-1"></option>
                                        <option value="0">Routine sorting</option>
                                        <option value="1">Six character sorting</option>
                                    </Field>
                                    <ErrorMessage name="algorithm" component="div" className="text-danger" />
                                </FormGroup>
                                {status && <div className="text-danger">{status}</div>}
                            </Form>
                        </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={() => { submitForm(); }}>Save</Button>{' '}
                                <Button type="button" color="secondary" onClick={() => setState({ ...state, modalOpen: false })}>Cancel</Button>
                            </ModalFooter>
                        </>
                    )}
                </Formik>
            </Modal>}
    </Container>);
}