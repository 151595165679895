import React, { useEffect, useState } from 'react';
import { getAccessTokenAsync } from '../../../actions/AuthActions';
import { DropdownToggle, DropdownItem, DropdownMenu, FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, ButtonGroup, Container, Dropdown } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

export const CQIndex = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [state, setState] = useState({
        data: [],
        modalOpen: false,
        error: "",
        categories: [],
    });

    const startDate = moment(new Date());
    const initialQuery = `SELECT * FROM [Cases] INNER JOIN [Orders] o on o.CaseId = Id WHERE o.DateReceived > '${startDate.subtract(14, "days").format("YYYY-MM-DD")}'`
    const [formInitialValues, setInitialValues] = useState({ id: "", name: "", category: "", query: initialQuery, active: true });

    const columns = [{
        dataField: 'name',
        text: 'Name',
        sort: true
    },
    {
        dataField: 'category',
        text: 'Category',
        sort: true
    },
    {
        dataField: 'active',
        text: 'Active',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return (<input type="checkbox" readOnly checked={cell == true}></input>)
        }
    },
    {
        isDummyField: true,
        dataField: '',
        text: 'Actions',
        sort: false,
        headerStyle: (column, colIndex) => {
            return { width: '180px', textAlign: 'right' };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                <ButtonGroup size="sm" className="ml-auto">
                    <Button color="primary" onClick={() => {
                        onEdit(row.id);
                    }}>Edit</Button>
                    <Button color="danger" onClick={() => {
                        onDelete(row.id);
                    }}>Delete</Button>
                </ButtonGroup>
            </>
        },
        style: () => {
            return { display: "flex" }
        }
    }];

    const handleTableChange = async (type, props) => {
        let url = `api/CustomQueries`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        if (result && props && props.sortField) {
            let f = props.sortOrder == "desc" ? -1 : 1;
            result = result.sort((a, b) => {
                return f * ((a[props.sortField] > b[props.sortField]) ? 1 : (a[props.sortField] < b[props.sortField]) ? -1 : 0);
            });
        }

        setState(prvState => { return { ...prvState, data: result } });
    }

    const fetchCategories = async () => {
        const resp = await fetch('/api/CustomQueries/Categories',
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });
        if (resp.status != 200) {
            return;
        }

        let knownCategories = await resp.json();
        setState(prvState => { return { ...prvState, categories: knownCategories } });
    }

    useEffect(() => {
        document.title = "Custom queries - Case workflow module";
        handleTableChange();
        fetchCategories();
    }, []);

    const createNew = () => {
        setInitialValues({ id: "", name: "", category: "", query: initialQuery, active: true });
        setState(prvState => { return { ...prvState, modalOpen: true } });
    }

    const onEdit = (id) => {
        let cq = state.data.find(d => d.id == id);
        if (cq == null) {
            return;
        }

        setInitialValues({ id: id, name: cq.name, category: cq.category, query: cq.sql, active: cq.active });
        setState(prvState => { return { ...prvState, modalOpen: true } });
    }

    const onDelete = async (id) => {
        if (id == null || !window.confirm("Are you sure you want to delete this query?")) {
            return;
        }

        let url = `api/CustomQueries/${id}`;
        const resp = await fetch(url,
            {
                method: "DELETE",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" }
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status != 200) {
            setState({ ...state, error: "Error deleting query" });
        }

        handleTableChange();
    }

    const submitHandler = async (values, setStatus) => {
        let url = `api/CustomQueries/`;
        if (values.id) {
            url += `${values.id}`;
        }

        setStatus(null);
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    Name: values.name,
                    Category: values.category,
                    Sql: values.query,
                    Active: values.active,
                })
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status != 200) {
            setStatus(await resp.text());
            return;
        }

        let res = await resp.json();

        setState(prvState => { return { ...prvState, modalOpen: false } });
        handleTableChange();
    }

    return (<Container>
        <div className="col">
            <h4 className="pull-left"><small><Link to="/pulz/admin">[<FontAwesomeIcon icon={faArrowLeft} /> back]</Link></small> Custom queries</h4>
            <Button className="pull-right mb-3" type="primary" onClick={() => createNew()}><FontAwesomeIcon icon={faPlus} /> Create</Button>
            <BootstrapTable
                bootstrap4
                remote
                keyField="id"
                data={state.data}
                columns={columns}
                bordered={false}
                condensed={true}
                // sort={{ dataField: state.sortField, order: state.sortOrder }}
                // pagination={paginationFactory({ page: state.page, sizePerPage: state.sizePerPage, totalSize: state.totalSize, showTotal: true, withFirstAndLast: true })}
                onTableChange={handleTableChange}
                classes="customqueries-table"
            />
        </div>
        {state.modalOpen &&
            <Modal isOpen={state.modalOpen} size="xl">
                <ModalHeader>
                    {!formInitialValues.id && <>Create a new custom query</>}
                    {formInitialValues.id && <>Edit custom query</>}
                </ModalHeader>
                <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    validate={values => {
                        const errors = {};
                        if (!values.name) {
                            errors.name = 'Required';
                        }
                        if (!values.query) {
                            errors.query = 'Required';
                        }
                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting, setStatus }) => {
                        submitHandler(values, setStatus);
                        setSubmitting(false);
                    }}>
                    {({ isSubmitting, submitForm, status }) => (
                        <><ModalBody>
                            <Form>
                                <Field type="hidden" name="id" />
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <label>Name</label>
                                    <Field type="text" name="name" className="form-control" />
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                </FormGroup>
                                <FormGroup check className="mb-2 mt-2 mr-sm-2">
                                    <Field type="checkbox" id="active" name="active" className="form-check-input" />
                                    <Label check for="active">Active</Label>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <label>Category</label>
                                    <AutoCompleteField autocompleteValues={state.categories} />
                                    <ErrorMessage name="category" component="div" className="text-danger" />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <label>Query</label>
                                    <Field component="textarea" name="query" className="form-control" />
                                    <ErrorMessage name="query" component="div" className="text-danger" />
                                </FormGroup>
                                {status && <div className="text-danger">{status}</div>}
                            </Form>
                        </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={() => { submitForm(); }}>Save</Button>{' '}
                                <Button type="button" color="secondary" onClick={() => setState({ ...state, modalOpen: false })}>Cancel</Button>
                            </ModalFooter>
                        </>
                    )}
                </Formik>
            </Modal>}
    </Container>);
}

const AutoCompleteField = ({ autocompleteValues }) => {
    const { values, setFieldValue } = useFormikContext();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [focused, setFocused] = React.useState(false)
    const onFocus = () => setFocused(true)

    useEffect(() => {
        if (!autocompleteValues) {
            return;
        }

        if (!focused) {
            setDropdownOpen(false);
            return;
        }

        let filteredValues = getFilteredValues();
        if (filteredValues.length > 0) {
            setDropdownOpen(true);
        }
        else {
            setDropdownOpen(false);
        }
    }, [focused, autocompleteValues, values.category]);

    const getFilteredValues = () => {
        if (!autocompleteValues) {
            return [];
        }

        if (!values?.category) {
            return autocompleteValues.filter(v => v);
        }

        return autocompleteValues.filter(v => v && v.toLocaleLowerCase().indexOf(values.category.toLocaleLowerCase()) > -1);
    }
    return (<>

        <Dropdown isOpen={dropdownOpen} toggle={() => { setDropdownOpen(!dropdownOpen) }}>
            <DropdownToggle tag="div" onClick={() => { setDropdownOpen(!dropdownOpen) }}>
                <Field type="text" name="category" className="form-control" onFocus={onFocus} onBlur={onFocus} />
            </DropdownToggle>
            <DropdownMenu style={{ width: "100%" }}>
                {getFilteredValues().map(v => <DropdownItem key={v} onClick={() => { setFieldValue("category", v); setDropdownOpen(false); }}>{v}</DropdownItem>)}
            </DropdownMenu>
        </Dropdown>
    </>);
}