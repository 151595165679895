import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Layout as VasLayout } from './components/vas/Layout';
import { Layout as CawLayout } from './components/pulz/ui/Layout';
import { Home } from './components/Home';
import { HomeConf } from './components/HomeConf';
import { Home as VasHome } from './components/vas/Home';
import { Home as CawHome } from './components/pulz/pages/Home';
import { Macro as CawMacro } from './components/pulz/pages/Macro';
import { Admin as CawAdmin } from './components/pulz/pages/Admin';
import { Case as CawCaseDetails } from './components/pulz/pages/Case';
import { Home as PimsHome } from './components/pims/Home';
import { Tasks as PimsTasks } from './components/pims/Tasks';
import { Viewer as PimsViewer } from './components/pims/Viewer';
import { Layout as PimsLayout } from './components/pims/Layout';
import { Timeline } from './components/vas/Timeline';
import { MessageDetails } from './components/vas/MessageDetails';
import { Slides } from './components/vas/Slides';
import { ReasonsIndex } from './components/vas/disapprovalReason/ReasonsIndex';
import { CawSlides } from './components/pulz/pages/Slides';
import { CQIndex } from './components/pulz/pages/CQIndex';
import { Mappings as PimsMappings } from './components/pims/conf/Mappings';
import { Queries as PimsQueries } from './components/pims/conf/Queries';
import { ActionsIndex } from './components/pulz/pages/ActionsIndex';
import { StatusIndex } from './components/pulz/pages/StatusIndex';
import { TagsIndex } from './components/pulz/pages/TagsIndex';
import './custom.css';
import { Signin } from './components/auth/Signin';
import { RequiredAuth } from './components/RequiredAuth';
import { Signout } from './components/auth/Signout';
import { AppContext } from './State/AppContext';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fas, far);

export default class App extends Component {
    static displayName = App.name;
    static contextType = AppContext;

    render() {
        return (
            <Routes>
                <Route exact path="/home" element={<RequiredAuth><Home /></RequiredAuth>} />
                <Route exact path="/home-conf" element={<RequiredAuth><HomeConf></HomeConf></RequiredAuth>} />
                <Route exact path="/signin" element={<Signin></Signin>} />
                <Route exact path="/signout" element={<RequiredAuth><Signout></Signout></RequiredAuth>} />
                <Route exact path="/vas/home/:page?" element={<RequiredAuth><VasLayout><VasHome></VasHome></VasLayout></RequiredAuth>} />
                <Route exact path='/vas/timeline' element={<RequiredAuth><VasLayout><Timeline></Timeline></VasLayout></RequiredAuth>} />
                <Route exact path='/vas/message/:messageId' element={<RequiredAuth><VasLayout><MessageDetails></MessageDetails></VasLayout></RequiredAuth>} />
                <Route exact path='/vas/slides/:caseNumber' element={<RequiredAuth><VasLayout><Slides></Slides></VasLayout></RequiredAuth>} />
                <Route exact path='/vas/reasons' element={<RequiredAuth><VasLayout><ReasonsIndex></ReasonsIndex></VasLayout></RequiredAuth>} />
                <Route exact path="/pulz/home/:page?" element={<RequiredAuth><CawLayout><CawHome></CawHome></CawLayout></RequiredAuth>} />
                <Route exact path="/pulz/case/:caseId?" element={<RequiredAuth><CawLayout><CawCaseDetails></CawCaseDetails></CawLayout></RequiredAuth>} />
                <Route exact path='/pulz/slides/:id?' element={<RequiredAuth><CawLayout><CawSlides></CawSlides></CawLayout></RequiredAuth>} />
                <Route exact path='/pulz/customqueries' element={<RequiredAuth><CawLayout><CQIndex></CQIndex></CawLayout></RequiredAuth>} />
                <Route exact path='/pulz/macro' element={<RequiredAuth><CawLayout><CawMacro></CawMacro></CawLayout></RequiredAuth>} />
                <Route exact path='/pulz/actions' element={<RequiredAuth><CawLayout><ActionsIndex></ActionsIndex></CawLayout></RequiredAuth>} />
                <Route exact path='/pulz/statuses' element={<RequiredAuth><CawLayout><StatusIndex></StatusIndex></CawLayout></RequiredAuth>} />
                <Route exact path='/pulz/tags' element={<RequiredAuth><CawLayout><TagsIndex></TagsIndex></CawLayout></RequiredAuth>} />
                <Route exact path="/pims/home" element={<RequiredAuth><PimsLayout><PimsHome></PimsHome></PimsLayout></RequiredAuth>} />
                <Route exact path='/pims/viewer' element={<RequiredAuth><PimsLayout><PimsViewer></PimsViewer></PimsLayout></RequiredAuth>} />
                <Route exact path='/pims/tasks' element={<RequiredAuth><PimsLayout><PimsTasks></PimsTasks></PimsLayout></RequiredAuth>} />
                <Route exact path='/pims/mappings' element={<RequiredAuth><PimsLayout><PimsMappings></PimsMappings></PimsLayout></RequiredAuth>} />
                <Route exact path='/pims/queries' element={<RequiredAuth><PimsLayout><PimsQueries></PimsQueries></PimsLayout></RequiredAuth>} />
                <Route exact path="/pulz/admin" element={<RequiredAuth><CawLayout><CawAdmin></CawAdmin></CawLayout></RequiredAuth>} />
                <Route exact path="/" element={<Navigate to="/home" replace></Navigate>} />
            </Routes>
        );
    }
}
