import React from "react";
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

const Admin = () => {
    return (
        <Container>
            <div className="col">
                <h4>Configuration options</h4>
                <ul className="list-group">
                    <li className="list-group-item"><Link to="/pulz/customqueries">Custom queries</Link></li>
                    <li className="list-group-item"><Link to="/pulz/actions">Actions</Link></li>
                    <li className="list-group-item"><Link to="/pulz/statuses">Statuses</Link></li>
                    <li className="list-group-item"><Link to="/pulz/tags">Tags</Link></li>
                    <li className="list-group-item"><Link to="/home-conf">Home page configuration</Link></li>
                    <li className="list-group-item"><Link to="/pims/mappings">PIMS directory mappings</Link></li>
                </ul>
            </div>
        </Container>);
}

export { Admin };
