import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ModalHeader, ModalFooter, ModalBody, FormGroup, Modal, Row, Col, Input, ListGroup, ListGroupItem, Button, FormFeedback, Form, Label } from 'reactstrap';
import Moment from 'react-moment';
import moment from 'moment';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../../custom.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlask, faVial, faVials, faArrowRight, faAngleRight, faThumbsUp, faThumbsDown, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../../State/AppContext';
import { getAccessTokenAsync, userLogout } from "../../actions/AuthActions";
import { Formik, ErrorMessage } from 'formik';
import { AutoCompleteField } from './AutoCompleteField';
import { getWsiIframeUrl } from './Helpers';

const classes = [
    "vertical-timeline-element--work",
    "vertical-timeline-element--education",
];

const iconStyles = [
    { background: 'rgb(33, 150, 243)', color: '#fff' },
    { background: 'rgb(233, 30, 99)', color: '#fff' }
];

const icons = [
    faFlask,
    faVial,
    faVials
];

export class Timeline extends Component {
    static displayName = Timeline.name;
    static contextType = AppContext;


    constructor(props) {
        super(props);

        const someTimeAgo = new Date(new Date() - (3600 * 1000 * 24 * 5));
        const someTimeAgoStr = `${someTimeAgo.getFullYear()}-${(someTimeAgo.getMonth() + 1).toString().padStart(2, "0")}-${someTimeAgo.getDate().toString().padStart(2, "0")}`;

        this.state = {
            messages: [],
            loading: true,
            identifiers: null,
            filteredMessages: [],
            from: someTimeAgoStr,
            to: '',
            buttonClicked: null,
            modalMessage: false,
            messageFilter: "",
            serverFrom: "",
            serverTo: "",
            statusFilter: "",
            scrollYStorage: null,
            disapprovalReasons: null,
        };

        this.timeout = null;
        this.modalChange = null;
        this.modalHL7Message = null;
        this.form = React.createRef();
        this.messageStatusNotes = "";
        this.currentMsg = null;

        this.closeBtn = <button className="close" onClick={() => this.setState({ modalMessage: false, buttonClicked: null })}>&times;</button>;
    }

    filterChanged(e) {
        let val = e.target.value;
        if (e.target.name === "filter") {
            this.setState({ messageFilter: val });
        }
        if (e.target.name === "status") {
            this.setState({ statusFilter: val });
        }
    }

    dateFromChanged(e) {
        var val = e.target.value;
        this.setState({ from: val });
    }

    dateToChanged(e) {
        var val = e.target.value;
        this.setState({ to: val });
    }

    async populateData(force = false) {
        let messages = this.state.messages;
        let from = this.state.from;
        let to = this.state.to;

        if (from !== this.state.serverFrom || to !== this.state.serverTo || force) {
            this.setState({ loading: true });
            const response = await fetch('api/message/ReagentChanges?from=' + from + (to ? ("&to=" + to) : ""), {
                headers: { "Authorization": "Bearer " + await getAccessTokenAsync() }
            });
            if (response.status !== 200) {
                userLogout(this.context);
            }
            messages = await response.json();
            this.setState({ serverFrom: from, serverTo: to })
        }

        let filteredMessages = messages;
        if (this.state.messageFilter) {
            filteredMessages = messages.filter(h =>
                h.messages.filter(msg =>
                    msg.stainer.toLowerCase().includes(this.state.messageFilter.trim().toLowerCase()) ||
                    msg.changes.filter(ch =>
                        ch.reagent.toLowerCase().includes(this.state.messageFilter.trim().toLowerCase()) ||
                        ch.lotNumber.toLowerCase().includes(this.state.messageFilter.trim().toLowerCase())
                    ).length > 0).length > 0);
        }

        if (this.state.statusFilter !== "") {
            filteredMessages = messages.filter(h =>
                h.messages.filter(msg =>
                    msg.status.toString() === this.state.statusFilter
                ).length > 0);
        }

        this.setState({ messages: messages, filteredMessages: filteredMessages, loading: false }, () => {
            if (this.state.scrollYStorage != null) {
                window.scrollTo({
                    top: this.state.scrollYStorage,
                    left: 0,
                    behavior: 'smooth'
                });
                this.setState({ scrollYStorage: null });
            }
        });
    }

    async fetchServerSettings() {
        const res = await fetch(`api/Settings`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res.status === 401) {
            return;
        }

        if (res.status === 404) {
            return;
        }

        var screds = await res.json();

        const res1 = await fetch(`api/PmaCoreSessionId`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res1.status === 401) {
            return;
        }

        if (res1.status === 404) {
            return;
        }

        var sessionIdResult = await res1.json();
        this.setState({ serverCreds: { username: screds.username, serverUrl: screds.serverUrl, path: screds.path, sessionId: sessionIdResult.sessionId } });
    }

    async fetchIdentifiers() {
        if (!this.state.disapprovalReasons) {
            const response = await fetch("api/DisapprovalReason", {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

            if (response.status === 401) {
                userLogout(this.context);
                return;
            }

            var reasons = await response.json();

            this.setState({ disapprovalReasons: reasons, loading: true });
        }
    }

    async applyFilter() {
        await this.populateData();
    }

    componentDidMount() {
        document.title = "Timeline - Verification of automated staining";
        this.fetchIdentifiers();
        this.populateData();
        this.fetchServerSettings();
    }

    modalMessageChanged(e) {
        this.messageStatusNotes = e.target.value;
    }

    submitHandler(e) {
        e.preventDefault();
    }

    async postApprove(approve) {
        this.setState({ scrollYStorage: window.scrollY });
        var response = await fetch(`api/message/approve`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + getAccessTokenAsync() },
                body: JSON.stringify({
                    Id: this.currentMsg.id,
                    Approve: approve,
                    Notes: this.messageStatusNotes
                })
            });

        if (response.status !== 200) {
            userLogout(this.context);
        }
        const result = await response.json();
        this.setState({ modalMessage: false });
        this.currentMsg.buttonClicked = false;

        if (result === true) {
            this.populateData(true);
            return;
        }

        console.warn("Server error for this message");
    }

    async approveClick(msg, approve) {
        msg.buttonClicked = true;
        this.currentMsg = msg;
        if (!approve && msg.status !== 2) {
            this.setState({ modalMessage: true });
        }
        else {
            await this.postApprove(approve);
        }
    }

    getCaseNumberPad(caseNumber) {
        if (caseNumber.length < 8) {
            return caseNumber.substring(0, 3) + caseNumber.substring(3).padStart(5, '0');
        }

        return caseNumber;
    }

    getURL(messageId) {
        var urlKey = encodeURIComponent(messageId.substring(0, 3)) + "/" + encodeURIComponent(messageId.substring(0, 5)) + "/" + encodeURIComponent(messageId.substring(0, 7)) + "/" + encodeURIComponent(messageId.substring(0, 9));
        var lnk = `/slides.html?serverUrl=${encodeURIComponent(this.state.serverCreds.serverUrl)}&sessionId=${this.state.serverCreds.sessionId}&sb=true&path=${this.state.serverCreds.path[0]}${urlKey}$slideInfo=${this.state.messages.slideInformation}`;
        return lnk;
    }

    renderTimeLine(filteredMessages) {
        return (filteredMessages.length > 0 ?
            <VerticalTimeline>
                {filteredMessages.map((h, i) => {
                    let index = i % 2; //Math.floor(Math.random() * 2);
                    return <VerticalTimelineElement key={h.date}
                        className={classes[index]}
                        contentArrowStyle={{ borderRight: '7px solid  #ddd' }}
                        date={moment(h.date).format("LLLL")}
                        iconStyle={iconStyles[index]}
                        icon={<FontAwesomeIcon icon={icons[index]} style={{ width: '24px' }} />}>
                        {h.messages.map(msg =>
                            <>
                                <ListGroup key={h.date + "_" + msg.id}>
                                    {msg.changes.map((ch, ii) =>
                                        <ListGroupItem key={ch.id + "_" + ch.reagent} className="d-flex justify-content-between p-0 align-items-stretch">
                                            <div className="m-3 mr-auto">
                                                <h6>{ch.substanceType}</h6>
                                                <span>{ch.reagent} </span>
                                                {ch.otherName && <small className="text-muted"> ({ch.otherName}) </small>}
                                                {ch.previousLotNumber && <><small>{ch.previousLotNumber}</small> <FontAwesomeIcon size="xs" icon={faArrowRight} /> </>}
                                                <small><Link to={"/vas/home?lotnumber=" + ch.lotNumber} target="_blank">{ch.lotNumber}</Link></small>
                                            </div>
                                        </ListGroupItem>
                                    )}
                                    <ListGroupItem disabled><strong>Order number: </strong> <i>{msg.orderNumber} - {msg.uzbRecipientId} - {msg.uzbBlockId}</i></ListGroupItem>
                                    <ListGroupItem disabled><strong>Stain: </strong> <i>{msg.stainName}</i></ListGroupItem>
                                    <ListGroupItem disabled><strong>Stainer: </strong> <i>{msg.stainer} {msg.stanerSerial}</i></ListGroupItem>
                                    <ListGroupItem disabled><strong>Requester: </strong> <i>{msg.requester ? msg.requester.replace("^", " ") : "n/a"}</i></ListGroupItem>
                                    {msg.status !== 0 &&
                                        <>
                                            <ListGroupItem disabled className={msg.status === 1 ? "text-success" : "text-danger"}>
                                                <strong>Status: </strong> <i>{msg.status === 1 ? "Approved " : "Failed "}</i>
                                                <small className="pl-1">
                                                    <strong>by </strong>{msg.statusChangedBy}
                                                    <strong className="pl-1"> on </strong><Moment subtract={{ minutes: (new Date()).getTimezoneOffset() }} format="LLLL">{msg.statusChangedOn}</Moment>
                                                </small>
                                                <div>{msg.statusNotes}</div>
                                            </ListGroupItem>
                                        </>
                                    }
                                    <ListGroupItem disabled><strong>Stain finished: </strong> <i>
                                        <Moment parse='YYYYMMDDHHmm'
                                            subtract={{ minutes: (new Date()).getTimezoneOffset() }} format="LLLL">{msg.stainingRunCompletedTime}</Moment></i></ListGroupItem>
                                    <ListGroup className="list-group-horizontal">
                                        <ListGroupItem tag="a" href={"/vas/message/" + msg.id} target="_blank" action color="light" className="d-flex justify-content-between">Details <FontAwesomeIcon icon={faAngleRight} size="lg" /></ListGroupItem>
                                        <ListGroupItem tag="a" href={"/vas/slides/" + this.getCaseNumberPad(msg.orderNumber) + `?${msg.slideInformation}`} target="_blank" action color="light" className="d-flex justify-content-between">Slides <FontAwesomeIcon icon={faAngleRight} size="lg" /></ListGroupItem>
                                        <ListGroupItem tag="button" onClick={this.approveClick.bind(this, msg, true)} action color="light" className="d-flex justify-content-between" style={{ width: "20%" }}><FontAwesomeIcon spin={msg.buttonClicked} className={msg.status === 1 ? "text-success" : "text-muted"} icon={!msg.buttonClicked ? faThumbsUp : faSpinner} size="lg" /></ListGroupItem>
                                        <ListGroupItem tag="button" onClick={this.approveClick.bind(this, msg, false)} action color="light" className="d-flex justify-content-between" style={{ width: "20%" }}><FontAwesomeIcon spin={msg.buttonClicked} className={msg.status === 2 ? "text-danger" : "text-muted"} icon={!msg.buttonClicked ? faThumbsDown : faSpinner} size="lg" /></ListGroupItem>
                                    </ListGroup>
                                </ListGroup>
                                <span className="vertical-timeline-element-date my-4 h-100">
                                    {msg.slideInformation && < iframe title="WSI" src={getWsiIframeUrl(this.getCaseNumberPad(msg.orderNumber), this.state.serverCreds.serverUrl, this.state.serverCreds.sessionId, this.state.serverCreds.path[0], msg.slideInformation)} style={{ border: 0 }} className="w-100 h-100" allowFullScreen={true}></iframe>}
                                    {!msg.slideInformation && < iframe title="WSI" src={getWsiIframeUrl(this.getCaseNumberPad(msg.orderNumber), this.state.serverCreds.serverUrl, this.state.serverCreds.sessionId, this.state.serverCreds.path[0])} style={{ border: 0 }} className="w-100 h-100" allowFullScreen={true}></iframe>}
                                </span >
                            </>
                        )}
                    </VerticalTimelineElement>
                })}
            </VerticalTimeline>
            : <p>No substance changes found</p>
        );
    }

    render() {
        return (
            <>
                <Form>
                    <FormGroup>
                        <Row className="justify-content-end">
                            <Col>
                                <h3>Substance Changes</h3>
                            </Col>
                            <Col xs="auto">
                                <Label style={{ fontSize: "110%", marginTop: 5 }}>Date range</Label>
                            </Col>
                            <Col xs="auto" >
                                <Input type="date" name="from" placeholder="From" value={this.state.from} onChange={this.dateFromChanged.bind(this)} />
                            </Col>
                            <Col xs="auto">
                                <Input type="date" name="to" placeholder="To" value={this.state.to} onChange={this.dateToChanged.bind(this)} />

                            </Col>
                            <Col xs="auto">
                                <Input type="text" name="filter" id="status" placeholder="Filter" value={this.state.messageFilter} onChange={this.filterChanged.bind(this)} />
                            </Col>
                            <Col xs="auto">
                                <select className="form-select" name="status" id="status" title="Status" onChange={this.filterChanged.bind(this)} value={this.state.statusFilter}>
                                    <option value="">All</option>
                                    <option value="0">Not set</option>
                                    <option value="1">Approved</option>
                                    <option value="2">Disapproved</option>
                                </select>
                            </Col>
                            <Col xs="auto">
                                <Button disabled={this.state.loading} color="primary" onClick={this.applyFilter.bind(this)}>Apply</Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
                <Row className="justify-content-md-center">
                    <Col sm="9">
                        {this.state.loading
                            ? <p><em>Loading...</em></p>
                            : this.renderTimeLine(this.state.filteredMessages)
                        }
                    </Col>
                </Row>
                {this.state.modalMessage &&
                    <Modal isOpen={this.state.modalMessage} size='lg'>
                        <ModalHeader>Please provide a reason</ModalHeader>
                        <Formik
                            initialValues={{ reason: "" }}
                            enableReinitialize={true}
                            validate={values => {
                                const errors = {};
                                if (!values.reason) {
                                    errors.reason = 'Please provide a reason!';
                                }
                                return errors;
                            }}

                            onSubmit={(values, { setSubmitting, setStatus }) => {
                                this.messageStatusNotes = values.reason;
                                this.postApprove(false);
                            }}>
                            {({ isSubmitting, submitForm, status, isValid }) => (
                                <><ModalBody>
                                    <Form>
                                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                            <AutoCompleteField autocompleteValues={this.state.disapprovalReasons.map(r => r.reason)} fieldName="reason" placeholder="Reason" invalid={`${(!isValid || this.state.showWarning)}`} ></AutoCompleteField>
                                            <ErrorMessage name="reason" component="div" className="text-danger" />
                                            <FormFeedback>Please provide a reason!</FormFeedback>
                                        </FormGroup>
                                        {status && <div className="text-danger">{status}</div>}
                                    </Form>
                                </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" onClick={() => { submitForm(); }}>Save</Button>{' '}
                                        <Button color="secondary" onClick={() => { this.setState({ modalMessage: false }); this.currentMsg.buttonClicked = false; }}>Cancel</Button>
                                    </ModalFooter>
                                </>)}
                        </Formik>
                    </Modal>}
            </>
        );
    }
}
