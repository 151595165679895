import { DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import React from 'react';
import useSyncDataContext from "../state/useSyncData";

export const PageLinksDropdown = ({ className, block }) => {
    const syncDataContext = useSyncDataContext();

    return (
        <UncontrolledDropdown className={className}>
            <DropdownToggle color="primary" caret block={!!block}>Open</DropdownToggle>
            <DropdownMenu end className="w-100">
                <DropdownItem onClick={() => window.open("/pulz/home", "_blank")}>Cases</DropdownItem>
                <DropdownItem disabled={!syncDataContext.data.caseNumber} onClick={() => { if (!syncDataContext.data.caseNumber) { return; } window.open(`pulz/case/${syncDataContext.data.caseNumber}`, "_blank") }}>Case Details</DropdownItem>
                <DropdownItem onClick={() => window.open(`pulz/slides`, "_blank")}>Slide Viewer</DropdownItem>
                <DropdownItem onClick={() => window.open(`pulz/macro`, "_blank")}>Macro Browser</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}
