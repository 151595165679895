import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../State/AppContext';
import { getAccessTokenAsync } from '../../../actions/AuthActions';
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";

let SyncDataContext = createContext({
    data: null,
    setSyncDisabled: () => { },
    getSyncDisabled: () => { },
    setSyncData: () => { },
});

export const SyncDataContextProvider = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const appContext = useContext(AppContext);
    const [syncData, setSyncData] = useState({
        caseNumber: null,
        selectedSlideNames: null,
        selectedSlidePaths: null,
        oNumber: null,
        lastUpdated: '1900-01-01',
        index: -1,
        lastImportDate: null,
        searchModel: null,
        loadedFromServer: false,
    });

    const syncDataRef = useRef(syncData);
    syncDataRef.current = syncData;

    const syncDisabled = useRef(false);
    syncDisabled.current = false;

    const getSyncDisabled = () => {
        return syncDisabled.current;
    };

    const setSyncDisabled = (value) => {
        syncDisabled.current = !!value;
    };

    const areEqualArrays = (arr1, arr2) => {
        if ((!arr1 && arr2) || (arr1 && !arr2)) {
            return false;
        }

        if (!arr1 && !arr2) {
            return true;
        }

        if ((arr1.length !== arr2.length)) {
            return false;
        }

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        return true;
    };

    const isDataDifferent = (data1, data2) => {
        if (!data1 ||
            data1.caseNumber !== data2.caseNumber ||
            !areEqualArrays(data1.selectedSlideNames, data2.selectedSlideNames) ||
            !areEqualArrays(data1.selectedSlidePaths, data2.selectedSlidePaths) ||
            data1.oNumber !== data2.oNumber ||
            data1.lastUpdated !== data2.lastUpdated ||
            data1.index !== data2.index ||
            data1.lastImportDate !== data2.lastImportDate ||
            data1.loadedFromServer !== data2.loadedFromServer ||
            JSON.stringify(data1.searchModel) !== JSON.stringify(data2.searchModel)) {
            return true;
        }

        return false;
    }

    const fetchSyncData = async () => {
        if (!appContext.state.isLogin || getSyncDisabled()) {
            return;
        }

        let url = `api/Sync/Get`;
        const resp = await fetch(url, { headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" } });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let sd = null;
        if (resp.status == 204) {
            sd = {
                caseNumber: null,
                selectedSlideNames: null,
                selectedSlidePaths: null,
                oNumber: null,
                lastUpdated: '1900-01-01',
                index: -1,
                lastImportDate: null,
                loadedFromServer: true,
                searchModel: null
            };
        }
        else {
            sd = await resp.json();
            if (sd.searchModel) {
                sd.searchModel.dateFromFilter = sd.searchModel?.dateFromFilter?.substring(0, 10) ?? null;
                sd.searchModel.dateToFilter = sd.searchModel?.dateToFilter?.substring(0, 10) ?? null;
            }
        }

        if (isDataDifferent(syncDataRef.current, sd)) {
            sd.loadedFromServer = true;
            setSyncData(sd);
        }
    };

    const setServerSyncData = async (data) => {
        if (!appContext.state.isLogin || getSyncDisabled()) {
            return;
        }
        if (!isDataDifferent(syncDataRef.current, data)) {
            return;
        }

        setSyncDisabled(true);
        setSyncData(data);

        const url = `api/Sync/Set`;
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify(data),
            });

        setSyncDisabled(false);

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }
    };

    useEffect(() => {
        const timeout = setInterval(() => fetchSyncData(), 1000);
        return () => clearInterval(timeout);
    }, [fetchSyncData]);

    const value = {
        data: syncData,
        getSyncDisabled,
        setSyncDisabled,
        setSyncData: setServerSyncData
    };

    return (
        <SyncDataContext.Provider value={value}>{props.children}</SyncDataContext.Provider>
    );
}

export default function useSyncDataContext() {
    return useContext(SyncDataContext);
}