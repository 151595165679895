import React, { useContext, useEffect, useState } from 'react';
import { Container, FormGroup, Input, Button } from 'reactstrap';
import { AppContext } from '../../State/AppContext';
import { loginUser } from '../../actions/AuthActions';
import { useNavigate } from 'react-router-dom';
import logo from "../../img/logo.svg";
import { useLocation } from 'react-router';

export const Signin = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const context = useContext(AppContext);
    const [state, setState] = useState({
        username: "",
        password: "",
        working: false
    });

    useEffect(() => {
        document.title = "Pathology Lab Workspace";
    }, []);

    const handleFormSubmit = async (evt) => {
        evt.preventDefault();
        evt.persist();
        setState({ working: true });
        loginUser(context, state.username, state.password).then(onSigninComplete);
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setState({ ...state, [name]: value });
    };

    const onSigninComplete = () => {
        setState({ working: false });

        let redirectURI = decodeURIComponent(location?.state?.from ?? "/");
        if (redirectURI === "/signout" || redirectURI === "/signin") {
            redirectURI = "/";
        }

        navigate(redirectURI);
    }

    return (
        <Container fluid>
            <form onSubmit={handleFormSubmit} autoComplete="on" className="form align-self-center col-md-4">
                <img className="w-100" src={logo} alt="Genesys Pixelator" />
                <h1>Sign in</h1>
                <br />
                <FormGroup>
                    <div>
                        <Input required name="username" type="text" placeholder="Username" onChange={handleInputChange} />
                    </div>
                </FormGroup>
                <FormGroup >
                    <div>
                        <Input required name="password" type="password" placeholder="Password" onChange={handleInputChange} />
                    </div>
                </FormGroup>
                <FormGroup>
                    <span style={{ color: 'red' }}>
                        {context.state?.login_error}
                    </span>
                </FormGroup>
                <FormGroup>
                    <Button color="primary" disabled={state.working} type="submit">Sign in</Button>
                </FormGroup>
            </form>
        </Container>
    );
}