import React, { useEffect, useState } from 'react';
import { getAccessTokenAsync } from '../../../actions/AuthActions';
import { TabPane, TabContent, Nav, NavItem, NavLink, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, Input, Button, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, DropdownItem } from 'reactstrap';
import { groupBy } from "../../../util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import useSyncDataContext from "../state/useSyncData";
import Clipboard from 'react-clipboard.js';
import moment from "moment";
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { PageLinksDropdown } from '../ui/PageLinksDropdown';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";

const getStainings = (slides) => {
    let res = groupBy(slides, "recipient");
    for (let r in res) {
        res[r] = groupBy(res[r], "block");
        for (let rr in res[r]) {
            res[r][rr] = groupBy(res[r][rr], "universalServiceId");

            res[r][rr] = Object.keys(res[r][rr]).map(x => { return res[r][rr][x][0] })
            res[r][rr] = res[r][rr].sort((a, b) => { return parseInt(a.slideId) - parseInt(b.slideId) });
        }
    }

    return res;
}

const SlideTypes = {
    Staining: 0,
    NonStaining: 1,
    WithoutTask: 2,
}

const Case = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [cs, setCase] = useState({
        id: "",
        caseNumber: "Loading...",
        originalCaseNumber: "",
        oNumber: "",
        stainingCount: 0,
        stainedCount: 0,
        stainings: [],
        requestForms: [],
        macroscopicImages: [],
        nonstainings: [],
        slidesWithoutTasks: [],
        status: "",
        recipients: [],
        blocks: [],
        diagnosticCodes: null,
    });

    const { caseId } = useParams();
    const syncDataContext = useSyncDataContext();
    const [modalMessage, setModalMessage] = useState(false);
    const [actions, setActions] = useState([]);
    const [activeTab, setActiveTab] = useState('1');
    const [selection, setSelection] = useState([]);
    const [actionRequestResult, setActionRequestResult] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const [hl7Messages, setHl7Messages] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [messageFilter, setMessageFilter] = useState("");
    const [fetching, setFetching] = useState(false);
    const [loadingFromClick, setLoadingFromClick] = useState(false);

    const toggle = tab => { if (activeTab !== tab) setActiveTab(tab); }
    const gotoCaseRef = React.createRef();

    useEffect(() => {
        if (activeTab == 2 && hl7Messages == null) {
            fetchMessages(caseId);
        }

    }, [activeTab, hl7Messages]);

    const fetchMessages = async (cid) => {
        const res = await fetch(`api/cases/messages?cid=${encodeURIComponent(cid)}`,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (res.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        setHl7Messages(await res.json());
    }

    const fetchCase = async (cid, index) => {
        let url = `api/cases/details?cid=${encodeURIComponent(cid)}`;
        if (index != null) {
            url = `api/cases/details?cid=${encodeURIComponent(cid)}&indexInOrder=${index}`;
        }

        const res = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (res.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }


        if (res.status == 404) {
            setCase({
                id: "",
                caseNumber: "Case not found",
                originalCaseNumber: "",
                oNumber: "",
                stainingCount: 0,
                stainedCount: 0,
                stainings: [],
                requestForms: [],
                macroscopicImages: [],
                nonstainings: [],
                slidesWithoutTasks: [],
                status: "",
                recipients: [],
                blocks: []
            });

            return;
        }

        return await res.json();
    }

    const fetchActions = async () => {
        let url = `api/Actions`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        setActions(result.filter(x => x.active));
    }

    const fetchStatuses = async () => {
        let url = `api/StatusDescriptions`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        result.forEach(s => { s.icon = s.icon.split("_") });
        setStatuses(result);
    }

    const initPage = () => {
        if (cs.id != "") {
            setCase({
                id: "",
                caseNumber: "Loading...",
                originalCaseNumber: "",
                oNumber: "",
                stainingCount: 0,
                stainedCount: 0,
                stainings: [],
                requestForms: [],
                macroscopicImages: [],
                nonstainings: [],
                slidesWithoutTasks: [],
                status: "",
                recipients: [],
                blocks: []
            });
        }

        setFetching(true);
        setActiveTab('1');
        setHl7Messages(null);
        setModalMessage(false);


        fetchCase(syncDataContext.data.caseNumber, syncDataContext.data.index).then(x => {
            setFetching(false);
            if (!x) {
                return;
            }


            let st = getStainings(x.slides?.filter(m => m.slideType != SlideTypes.NonStaining) ?? []);
            let recipients = Array.from(new Set(Object.keys(st).concat(x.slidesWithoutTasks.map(swt => swt.recipient))));
            recipients = recipients.sort();
            let blocksArray = x.slidesWithoutTasks.map(swt => swt.block);
            Object.keys(st).map(k => Object.keys(st[k]).forEach(bl => { blocksArray.push(bl) }));
            let blocks = Array.from(new Set(blocksArray));
            x.slidesWithoutTasks.forEach(swt => swt.uid = swt.id);

            let originalCaseNumber = x.caseNumber.substring(3);
            while (originalCaseNumber.startsWith("0")) {
                originalCaseNumber = originalCaseNumber.substring(1);
            }

            originalCaseNumber = x.caseNumber.substring(0, 3) + originalCaseNumber;
            navigate(`/pulz/case/${x.caseNumber}`, { replace: true, state: { index: syncDataContext.data.index } });

            setCase({
                id: x.id,
                caseNumber: x.caseNumber,
                originalCaseNumber: originalCaseNumber,
                oNumber: x.oNumber,
                stainingCount: x.stainingCount,
                stainedCount: x.stainedCount,
                tissueType: x.tissueType,
                subspecialism: x.subspecialism,
                dateReceived: x.dateReceived,
                mountedCount: x.mountedCount,
                scannedCount: x.scannedCount,
                diagnosticCodes: x.diagnosticCodes,
                validatedCount: x.validatedCount,
                totalCount: x.totalCount,
                orderCount: x.orderCount,
                relatedCases: x.relatedCases,
                orderNumbers: x.orderNumbers,
                stainings: st,
                nonstainings: x.slides.filter(m => m.slideType == SlideTypes.NonStaining),
                patientId: x.patientId,
                slides: x.slides.sort(function (a, b) { return b.recipient - a.recipient }),
                status: x.stainingCount === x.stainedCount ? "Completely scanned" : "Incomplete",
                requestForms: x.requestForms,
                macroscopicImages: x.macroscopicImages,
                slidesWithoutTasks: x.slidesWithoutTasks,
                recipients: recipients,
                blocks: blocks,
                previousCase: x.previousCase,
                nextCase: x.nextCase,
            });

            setLoadingFromClick(false);

            let fk = Object.keys(st)[0];
            if (!fk) {
                return;
            }

            let fk1 = Object.keys(st[fk])[0];
            if (!fk1) {
                return;
            }

            let fk2 = Object.keys(st[fk][fk1])[0];
            if (!fk2) {
                return;
            }

            if (gotoCaseRef.current && gotoCaseRef.current.value) {
                gotoCaseRef.current.value = "";
            }
        });

        fetchActions();
        fetchStatuses();
    }

    useEffect(() => {
        document.title = `Case ${syncDataContext.data.caseNumber} - PIMS`;
        if (fetching) {
            return;
        }

        /* Check for not returning bad request when page reloads*/
        if (!syncDataContext.data.caseNumber) {
            return;
        }


        if (cs.caseNumber.toUpperCase() != syncDataContext.data.caseNumber.toUpperCase()) {
            initPage();
        }

    }, [syncDataContext.data]);

    const goBack = (evt) => {
        evt.preventDefault();
        navigate(-1);
    }

    const gotoCase = (evt) => {
        if (!gotoCaseRef.current) {
            return;
        }

        var caseId = gotoCaseRef.current.value;
        if (!caseId) {
            return;
        }

        if (gotoCaseRef.current && gotoCaseRef.current.value) {
            gotoCaseRef.current.value = "";
        }

        caseId = caseId.trim();
        if (caseId.length < 8) {
            caseId = caseId.substring(0, 3) + caseId.substring(3).padStart(5, '0');
        }

        setCaseForSlideViewer(caseId, null, -1, false);
    }

    const slideClick = (st, oNumber) => {
        setSelection(prvState => {
            let s = new Set(prvState);
            s.has(st) ? s.delete(st) : s.add(st);
            return Array.from(s);
        })
    }

    const setCaseForSlideViewer = async (caseNumber, oNumber, index, isPrevNext) => {
        if (isPrevNext === false) {
            await syncDataContext.setSyncData({
                ...syncDataContext.data,
                caseNumber: caseNumber,
                oNumber: oNumber,
                index: 0,
                searchModel: {
                    ...syncDataContext.data.searchModel,
                    caseFilter: caseNumber,
                    orderNumberFilter: "",
                    tissueTypeFilter: "",
                    subSpecialismFilter: "",
                    dateFromFilter: null,
                    dateToFilter: null,
                    diagnosticCodesFilter: "",
                    customQueryFilter: "",
                    page: 1
                }
            });
        }
        else {
            await syncDataContext.setSyncData({
                ...syncDataContext.data,
                caseNumber: caseNumber,
                oNumber: oNumber,
                index: index
            });
        }
    }

    const slideActionClick = async (action) => {
        if (selection.length == 0) {
            return;
        }

        if (!window.confirm(`Are you sure you want to perform action ${action.name} on ${selection.length} slides?`)) {
            return;
        }

        let url = `api/Actions/Perform`;
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    Id: action.id,
                    SlideIds: selection.map(s => `${s.id}`),
                })
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status == 200) {
            let result = await resp.json();
            setActionRequestResult({ message: `Action completed successfully, affected ${result} rows`, error: false });
            initPage();
            return;
        }

        setActionRequestResult({ message: `${await resp.text()}`, error: resp.status != 200 });
    }

    const caseActionClick = async (action) => {
        if (!window.confirm(`Are you sure you want to perform action ${action.name} on this case?`)) {
            return;
        }

        let url = `api/Actions/Perform`;
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    Id: action.id,
                    CaseId: cs.caseNumber,
                })
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        if (resp.status == 200) {
            setActionRequestResult({ message: `Action completed successfully, affected ${result} rows`, error: false });
            initPage();
            return;
        }

        setActionRequestResult({ message: `${result}`, error: resp.status != 200 });
    }

    useEffect(() => {
        if (actionRequestResult != null) {
            setTimeout(() => {
                setActionRequestResult(null);
            }, 10000);
        }
    }, [actionRequestResult]);

    const fetchStatusById = (status) => {
        if (!statuses) {
            return;
        }

        let s = statuses.find(ss => ss.id == status);
        if (!s) {
            return;
        }

        return s;
    }

    useEffect(() => {
        if (tooltipOpen) {
            setTimeout(() => {
                setTooltipOpen(false);
            }, 5000);
        }
    }, [tooltipOpen]);

    const messagesFilterChange = (e) => {
        var val = e.target.value;
        if (e.target.name == "messageFilter") {
            setMessageFilter(val);
        }
    }

    const clickNext = () => {
        setCaseForSlideViewer(cs.nextCase, null, Math.max(0, syncDataContext.data.index + 1), true);
    }

    const clickPrevious = () => {
        setCaseForSlideViewer(cs.previousCase, null, Math.max(0, syncDataContext.data.index - 1), true);
    }

    return (<>
        <Row className="cases-main" style={{ height: 'calc(100% - 20px)' }}>
            <div className="col-10 h-100">
                <div className="d-flex flex-column h-100">
                    <div className="d-flex flex-column h-100">
                        <h4 className="d-flex mb-0" style={{ alignItems: "baseline" }}>
                            <div className="col-9">
                                {!loadingFromClick && <span className="d-flex flex-column"><span>
                                    <span style={{ fontWeight: "lighter" }}>Case: </span> {cs.caseNumber}
                                    <small><Clipboard className="btn btn-link"
                                        onSuccess={() => setTooltipOpen(true)} data-clipboard-text={cs.caseNumber} title="Copy">
                                        <FontAwesomeIcon icon="fa-regular fa-clipboard" />
                                    </Clipboard>

                                        {cs.originalCaseNumber != cs.caseNumber &&
                                            <Clipboard className="btn btn-link"
                                                onSuccess={() => setTooltipOpen(true)} data-clipboard-text={cs.originalCaseNumber} title="Davinci copy">
                                                <FontAwesomeIcon icon="fa-solid fa-clipboard" />
                                            </Clipboard>
                                        }
                                        {tooltipOpen && <small className="text-success">Copied!</small>}
                                    </small>

                                    <span style={{ fontWeight: "lighter" }}>Description: </span> {cs.tissueType}
                                </span>
                                </span>}
                            </div>

                            <div className="col-3">
                                {loadingFromClick && <span className="font-weight-light mr-1">Loading...</span>}
                                <small className="ml-auto d-flex">
                                    <KeyboardEventHandler
                                        handleKeys={['down', 'up']}
                                        onKeyEvent={(key, e) => { key == "up" ? clickPrevious() : clickNext(); }} />
                                    <div className="btn-group mb-3 mr-1" role="group" aria-label="navigation">
                                        <button disabled={!cs.previousCase} className="btn btn-outline-secondary" onClick={clickPrevious} title={cs.previousCase ? `Previous ${cs.previousCase}` : ""}><FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon></button>
                                        <button disabled={!cs.nextCase} className="btn btn-outline-secondary" onClick={clickNext} title={cs.nextCase ? `Next ${cs.nextCase}` : ""}><FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon></button>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input ref={gotoCaseRef} type="text" className="form-control" placeholder="Go to case" aria-label="Go to case" id="gotocase"
                                            onKeyPress={event => {
                                                if (event.key === "Enter") {
                                                    gotoCase();
                                                }
                                            }} />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button" onClick={gotoCase}>Go</button>
                                        </div>
                                    </div>
                                    <a href="#" className="btn btn-link" onClick={goBack}>Back</a>
                                </small>
                            </div>
                        </h4>
                        <div className="row mb-1">
                            <div className="col">
                                <div className="card d-flex flex-row">
                                    <strong className="col" style={{ flexGrow: "1.1" }}>Subspecialism <small><i className="font-weight-light">{cs.subspecialism}</i></small></strong>
                                    <strong className="col">Date <small><i className="font-weight-light">{cs.dateReceived ? moment(cs.dateReceived).format("ddd, DD MMM YYYY") : "N/A"}</i></small></strong>
                                    <strong className="col">Validated <small><i className="font-weight-light">{cs.validatedCount} / {cs.orderCount}</i></small></strong>
                                    <strong className="col">Coverslipped <small><i className="font-weight-light">{cs.mountedCount} / {cs.totalCount}</i></small></strong>
                                    <strong className="col">Scanned <small><i className="font-weight-light">{cs.scannedCount} / {cs.totalCount}</i></small></strong>
                                    {cs.diagnosticCodes && <strong className="col">Diagnostic codes <small><i className="font-weight-light">{cs.diagnosticCodes}</i></small></strong>}
                                </div>
                            </div>
                        </div>
                        <Nav tabs>
                            <NavItem style={{ cursor: "pointer" }}>
                                <NavLink className={activeTab === '1' ? "active" : ""} onClick={() => { toggle('1'); }}>Main</NavLink>
                            </NavItem>
                            <NavItem style={{ cursor: "pointer" }}>
                                <NavLink className={activeTab === '2' ? "active" : ""} onClick={() => { toggle('2'); }}>Messages</NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} className="h-100">
                            <TabPane tabId="1" className="h-100">
                                <div key="tab-1" className="overflow-auto mb-3">

                                    {cs.nonstainings && cs.nonstainings.length > 0 &&
                                        <React.Fragment key="non-staining-fragment">
                                            <div key="non-staining" className="card-header p-1 pl-2 font-weight-light" style={{ backgroundColor: "#dbdbdb" }}>
                                                Non stainings
                                            </div>
                                            <ul className="list-group list-group-flush list-group-horizontal overflow-auto">
                                                {cs.nonstainings.map(m => {
                                                    return (
                                                        <li key={m.id} className="list-group-item d-flex flex-row">
                                                            <div className="mr-1 align-self-center">
                                                                <FontAwesomeIcon style={{ color: fetchStatusById(m.status)?.color ?? "inherit" }} icon={fetchStatusById(m.status)?.icon ?? "check"} size="lg" />
                                                            </div>
                                                            <div className="d-flex flex-column">
                                                                <strong>{m.slideId.substring(2)}</strong><span>{fetchStatusById(m.status)?.name ?? "Ready"}</span>
                                                            </div>
                                                        </li>);
                                                })}
                                            </ul>
                                        </React.Fragment>
                                    }

                                    {cs.recipients.map(recipient => {
                                        return (
                                            <React.Fragment key={recipient}>
                                                <div key={`${recipient}_div`} className="card-header p-1 pl-2 font-weight-light" style={{ backgroundColor: "#dbdbdb" }}>
                                                    Recipient: <span className="font-weight-normal">{recipient}</span>
                                                </div>
                                                <div className="recipient-contents" key={`${recipient}_content`}>
                                                    {cs.blocks.sort().filter(x => {
                                                        return (cs.slidesWithoutTasks && cs.slidesWithoutTasks.length > 0 && cs.slidesWithoutTasks.filter(swt => swt.recipient == recipient && swt.block == x)) ||
                                                            (cs.stainings[recipient] && cs.stainings[recipient][x]);
                                                    }).map(block => {
                                                        if ((!cs.stainings[recipient] || !cs.stainings[recipient][block] || cs.stainings[recipient][block].length == 0) &&
                                                            (!cs.slidesWithoutTasks || cs.slidesWithoutTasks.filter(swt => swt.recipient == recipient && swt.block == block).length == 0)) {
                                                            return (<></>);
                                                        }

                                                        return (
                                                            <div key={`${recipient}_${block}_header`} className="case-block d-flex flex-row">
                                                                <div className="title d-flex flex-column">
                                                                    <span className="font-weight-normal">{recipient}-{block}</span>
                                                                    <span className="pl-1 text-muted">{cs.stainings[recipient] && cs.stainings[recipient][block] && cs.stainings[recipient][block][0].blockInformation}</span>
                                                                </div>

                                                                <ul key={`${recipient}_${block}_list`} className="list-group list-group-flush list-group-horizontal overflow-auto flex-wrap case-block-stainings" style={{ alignItems: "stretch" }}>
                                                                    {cs.stainings[recipient] && cs.stainings[recipient][block] && cs.stainings[recipient][block].map(st => {
                                                                        let n = `${recipient}-${block}-${st.slideId?.substring(0, 2)}: ${st?.slideId?.substring(2)}`;
                                                                        return (<li key={st.id} style={{ cursor: "pointer" }} className={"slide-item list-group-item d-flex flex-row" + (selection.indexOf(st) != -1 ? " active" : "")}
                                                                            onClick={() => slideClick(st, cs.oNumber)}>
                                                                            <div className="mr-1 align-self-center">
                                                                                <FontAwesomeIcon style={{ color: fetchStatusById(st.status)?.color ?? "inherit" }} icon={fetchStatusById(st.status)?.icon ?? "check"} size="lg" />
                                                                            </div>
                                                                            {st.scanned && <div style={{ order: 2 }} title="Scanned">
                                                                                <FontAwesomeIcon className="text-success" icon={["fas", "microscope"]} size="sm" />
                                                                            </div>}
                                                                            <div className="d-flex flex-column slide-header">
                                                                                <span title={n} style={{ color: fetchStatusById(st.status)?.color ?? "inherit" }} className="slide-id">{n}</span>
                                                                                <span className={"" + (selection.indexOf(st) != -1 ? " text-light" : " text-muted")}>{fetchStatusById(st.status)?.name ?? '\u00A0'}</span>
                                                                            </div>
                                                                        </li>);
                                                                    })}
                                                                    {cs.slidesWithoutTasks && cs.slidesWithoutTasks.length > 0 && cs.slidesWithoutTasks.filter(swt => swt.recipient == recipient && swt.block == block).map(swt => {
                                                                        return (<li key={swt.id} style={{ cursor: "pointer" }} className={"slide-item list-group-item d-flex flex-row" + (selection.indexOf(swt) != -1 ? " active" : "")}
                                                                            onClick={() => slideClick(swt, cs.oNumber)}
                                                                        >
                                                                            <div className="mr-1 align-self-center">
                                                                                <FontAwesomeIcon style={{ color: fetchStatusById(swt.status)?.color ?? "inherit" }} icon={fetchStatusById(swt.status)?.icon ?? "check"} size="lg" />
                                                                            </div>
                                                                            {swt.scanned && <div style={{ order: 2 }} title="Scanned">
                                                                                <FontAwesomeIcon className="text-success" icon={["fas", "microscope"]} size="sm" />
                                                                            </div>}
                                                                            <div className="d-flex flex-column slide-header">
                                                                                <span style={{ color: fetchStatusById(swt.status)?.color ?? "inherit" }} className="slide-id">{recipient}-{block}-{swt.slideId?.substring(0, 2)}: {swt.slideId?.substring(2)}</span>
                                                                                <span className={"" + (selection.indexOf(swt) != -1 ? " text-light" : " text-muted")}>{fetchStatusById(swt.status)?.name ?? '\u00A0'}</span>
                                                                            </div>
                                                                        </li>)
                                                                    }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                    }

                                    {cs.requestForms.length > 0 &&
                                        <>
                                            <div className="card-header p-1 pl-2 font-weight-light" style={{ backgroundColor: "#dbdbdb" }}>
                                                Documents
                                            </div>
                                            <ul className="list-group list-group-flush overflow-auto">
                                                {cs.requestForms.map(x =>
                                                    <li key={x} className="list-group-item p-1 px-4"><a target="_blank" href={`api/cases/file?onumber=${encodeURIComponent(cs.oNumber)}&filename=${encodeURIComponent(x)}`} rel="noreferrer">{x}</a></li>
                                                )}
                                            </ul>

                                        </>}

                                    {cs.macroscopicImages.length > 0 &&
                                        <>
                                            <div className="card-header p-1 pl-2 font-weight-light" style={{ backgroundColor: "#dbdbdb" }}>
                                                Images
                                            </div>
                                            <ul className="list-group list-group-flush list-group-horizontal overflow-auto flex-wrap macroscopic-images" style={{ alignItems: "stretch" }}>
                                                {cs.macroscopicImages.map(x => {
                                                    return (<li key={x} className="list-group-item p-1 px-4"><a target="_blank" href={`api/cases/file?onumber=${encodeURIComponent(cs.oNumber)}&filename=${encodeURIComponent(x)}`} rel="noreferrer">{x.split(".").slice(0, -1).join()}</a></li>)
                                                })}
                                            </ul>
                                        </>}

                                    <div key="Related" className="card-header p-1 pl-2 font-weight-light" style={{ backgroundColor: "#dbdbdb" }}>
                                        Related
                                    </div>
                                    <div key="OrderNumber" className="card-header p-1 pl-3 font-weight-light">
                                        From same case
                                    </div>
                                    <ul key="same-case" className="list-group list-group-flush">
                                        {cs.orderNumbers && cs.orderNumbers.map(x => <li key={x} style={{ alignItems: "baseline" }} className="list-group-item p-1 px-3 d-flex">{x}</li>)}
                                        {(!cs.orderNumbers || cs.orderNumbers.length == 0) && <li className='list-group-item p-1 px-3 d-flex text-secondary'>None</li>}
                                    </ul>
                                    <div key="PatientId" className="card-header p-1 pl-3 font-weight-light">
                                        From same patient {cs.patientId ? `(${cs.patientId})` : ""}
                                    </div>
                                    <ul key="same-patient" className="list-group list-group-flush overflow-auto">
                                        {cs.relatedCases && Object.keys(cs.relatedCases).map(x =>
                                            <React.Fragment key={x}>
                                                <a onClick={(e) => { e.preventDefault(); setCaseForSlideViewer(x, null, 0, false); }} href='#' style={{ alignItems: "baseline" }} className="list-group-item p-1 px-3 d-flex">
                                                    <span className='font-weight-light pr-1'>Case: </span>{x}</a>
                                                {cs.relatedCases[x] && cs.relatedCases[x].map(xx =>
                                                    <li key={xx} className="list-group-item p-1 px-4">{xx}</li>
                                                )}
                                            </React.Fragment>
                                        )}
                                        {(!cs.relatedCases || Object.keys(cs.relatedCases).length == 0) && <li className='list-group-item p-1 px-3 d-flex text-secondary'>None</li>}
                                    </ul>
                                </div>
                            </TabPane>

                            <TabPane tabId="2" className="h-100">
                                <div className="card h-100 overflow-auto">
                                    <div className="card-content h-100 overflow-auto">
                                        <div className="card-header p-1 pl-3 font-weight-light">
                                            Messages
                                        </div>
                                        <div className="form-group col">
                                            <InputGroup>
                                                <Input className="form-control" onChange={messagesFilterChange} value={messageFilter} type="text" name="messageFilter" placeholder="Filter" />
                                                <div className="input-group-append">
                                                    <Button color="primary" onClick={() => { setMessageFilter(""); }}>
                                                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                                    </Button>
                                                </div>
                                            </InputGroup>
                                        </div>
                                        <ul className="list-group list-group-flush">
                                            {hl7Messages && hl7Messages.filter(x => !messageFilter || x.messageText.toLowerCase().includes(messageFilter.toLowerCase())).map(x => <li key={x.id} style={{ maxHeight: "4em", cursor: "pointer", alignItems: "baseline" }} className="list-group-item p-1 px-3 d-flex">
                                                <div className="flex-column align-items-start">
                                                    <span>{x.id}</span>
                                                    <small className="ml-1 text-muted">{moment(x.Date).format("ddd, DD MMM YYYY")}</small>
                                                    <small className="ml-1 text-muted">{x.orderStatus}</small>
                                                    <p className="mb-0">{x.shortText}</p>
                                                </div>
                                                <a href="#" onClick={(e) => { e.preventDefault(); setModalMessage(x) }} className="ml-auto">Details</a>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>
            <div className="col-2">
                <Row>
                    <ButtonGroup vertical>
                        <UncontrolledDropdown className="w-100 mb-2">
                            <DropdownToggle color="primary" caret block>Case actions</DropdownToggle>
                            <DropdownMenu className="w-100">
                                {actions && actions.filter(a => a.level == 1).map(a => <DropdownItem key={a.name} onClick={() => caseActionClick(a)}>{a.name}</DropdownItem>)}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown className="w-100 mb-2" disabled={selection.length == 0}>
                            <DropdownToggle disabled={selection.length == 0} color="primary" caret block>Slide actions</DropdownToggle>
                            <DropdownMenu className="w-100">
                                {actions && actions.filter(a => a.level == 0).map(a => <DropdownItem key={a.name} onClick={() => slideActionClick(a)}>{a.name}</DropdownItem>)}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <PageLinksDropdown className="w-100 mb-2" block></PageLinksDropdown>
                    </ButtonGroup>
                </Row>
                <span className={"form-text " + (actionRequestResult && actionRequestResult.error ? "text-danger" : "text-success")}>
                    {actionRequestResult && actionRequestResult.message}
                </span>
            </div>
        </Row >
        {
            modalMessage &&
            <Modal isOpen={!!modalMessage} size="xl">
                <ModalHeader>Message details</ModalHeader>
                <ModalBody>
                    <div className="card p-0 overflow-auto" readOnly={true} style={{ height: "500px", wordWrap: "break-word" }}>{
                        modalMessage.messageText
                    }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setModalMessage(false)}>Close</Button>
                </ModalFooter>
            </Modal>
        }
    </>);
}

export { Case };
