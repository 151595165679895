import React, { Component, useEffect, useState } from 'react';
import { FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { Formik, FieldArray, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import { getAccessTokenAsync } from '../../../actions/AuthActions';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

export const QueryEdit = ({ modalOpen, setModalOpen }) => {
    const [state, setState] = useState([]);
    const [formInitialValues, setFormInitialValues] = useState({
        queries: []
    });
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (modalOpen) {
            fectchQueries();
        }
    }, [modalOpen]);

    useEffect(() => {
        if (!state || state.length == 0) {
            return
        }

        setFormInitialValues({
            queries: state.map(q => {
                return {
                    id: q.id,
                    visible: !!q.order,
                    order: q.order ? q.order : 0,
                    name: q.name,
                    category: q.category
                }
            })
        });

    }, [state]);

    const fectchQueries = async (type, props) => {
        let url = `api/CustomQueries`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        result = result.filter(x => x.active == true);
        if (result && props && props.sortField) {
            let f = props.sortOrder == "desc" ? -1 : 1;
            result = result.sort((a, b) => {
                return f * ((a[props.sortField] > b[props.sortField]) ? 1 : (a[props.sortField] < b[props.sortField]) ? -1 : 0);
            });
        }

        setState(result);
    }

    const submitHandler = async (values, setStatus) => {
        if (!values || !values.queries) {
            return;
        }

        let url = `api/CustomQueries/SetOrder`;

        setStatus(null);
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify(values.queries)
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }
        if (resp.status != 200) {
            setStatus(await resp.text());
            return;
        }

        let res = await resp.json();
        setModalOpen(false);
    }

    return (
        <Modal isOpen={modalOpen} size="xl">
            <ModalHeader>Edit queries order</ModalHeader>
            <Formik
                initialValues={formInitialValues}
                enableReinitialize={true}
                validate={values => {
                }}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    submitHandler(values, setStatus);
                    setSubmitting(false);
                }}>
                {({ isSubmitting, submitForm, status, values }) => (
                    <><ModalBody>
                        <Form>
                            <FieldArray name="queries">
                                {({ insert, remove, push }) => (
                                    <Table size="sm">
                                        <thead><tr>
                                            <th>Name</th>
                                            <th>Category</th>
                                            <th>Visible</th>
                                            <th style={{ width: "80px" }}>Order</th>
                                        </tr></thead>
                                        <tbody>
                                            {values.queries.length > 0 && values.queries
                                                .sort((a, b) => { return a.category < b.category }).map((query, index) => (
                                                    <tr key={index}>
                                                        <td>{query.name}</td>
                                                        <td>{query.category}</td>
                                                        <td>
                                                            <Field type="checkbox" name={`queries.${index}.visible`} className="" />
                                                            <ErrorMessage name="{`queries.${index}.visible`}" component="div" className="text-danger" />
                                                        </td>
                                                        <td>
                                                            <Field type="number" min="0" name={`queries.${index}.order`} className="form-control" />
                                                            <ErrorMessage name="{`queries.${index}.order`}" component="div" className="text-danger" />
                                                        </td>
                                                        <td className="d-none"><Field type="hidden" name={`queries.${index}.id`}></Field></td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                )}
                            </FieldArray>
                            {status && <div className="text-danger">{status}</div>}
                        </Form>
                    </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => { submitForm(); }}>Save</Button>{' '}
                            <Button type="button" color="secondary" onClick={() => setModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </>
                )}
            </Formik>
        </Modal>);
}
