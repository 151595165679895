import React, { useContext, useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";
import { getAccessTokenAsync } from '../../actions/AuthActions';
import { Context as UIContext, ComponentEvents, SessionLogin, Tree } from "@pathomation/pma.ui/src/index"
import {
    Card, CardBody, Row, Col, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu, CardHeader, CardFooter, CardTitle, InputGroup,
    Input, Alert, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Table
} from 'reactstrap';
import classnames from 'classnames';
import { createDirectory } from '../../PmaCoreClient';
import useSyncDataContext from '../pulz/state/useSyncData';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

export const Tasks = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const syncDataContext = useSyncDataContext();
    const previousSyncData = React.useRef(null);
    const [statuses, setStatuses] = useState([]);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [searching, setSearching] = useState(false);
    const context = useRef(new UIContext({ caller: "Laba" }));
    const [serverCreds, setServerCreds] = useState({});
    const treeSourceRef = useRef(null);
    const treeSourceInstance = useRef(null);
    const treeDestinationRef = useRef(null);
    const treeDestinationInstance = useRef(null);
    const [selectedSlides, setSelectedSlides] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [customQueriesSelect, setCustomQueriesSelect] = useState([]);
    const [caseList, setCaseList] = useState({ cases: [], total: 0 });
    const [forceReload, setForceReload] = useState(false);
    const casesTable = useRef(null);
    const selectedCasesTimeout = useRef(null);
    const casesTimeout = useRef(null);
    const selectedImagesTimeout = useRef(null);
    const [visible, setVisible] = useState(false);
    const [keepStructure, setKeepStructure] = useState(false);
    const [rename, setRename] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [tableActive, setTableActive] = useState(false);
    const [pimsQueriesSelect, setPimsQueriesSelect] = useState([]);
    const [querySlidesList, setQuerySlidesList] = useState([]);
    const queryCasesTable = useRef(null);
    const selectedQuerySlidesTimeout = useRef(null);
    const [keepStructureModalOpen, setKeepStructureModalOpen] = useState(false);
    const selectedTaskType = useRef(-1);
    const [commonPath, setCommonPath] = useState("");

    // Toggle modal state
    const toggle = () => setModal(!modal);

    // State for current active Tab
    const [currentActiveTab, setCurrentActiveTab] = useState('1');

    // Toggle active state for Tab
    const toggleTab = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }

    const getUrlKey = (value) => {
        return encodeURIComponent(value.substring(0, 3)) + "/" + encodeURIComponent(value.substring(0, 5)) + "/" + encodeURIComponent(value.substring(0, 7)) + "/" + encodeURIComponent(value.substring(0, 9));
    }


    const getDirectories = path => {
        return new Promise((resolve, reject) => {
            context.current.getDirectories(serverCreds.serverUrl, path, (sessionId, directories) => { resolve(directories) }, reject);
        });
    }

    const getSlides = path => {
        return new Promise((resolve, reject) => {
            context.current.getSlides({
                serverUrl: serverCreds.serverUrl,
                path: path,
                success: (sessionId, files) => { resolve(files) },
                failure: () => { resolve([]) }
            });
        });
    };


    const getSlidesFromCase = async (cases) => {
        setSearching(true);
        if (!cases || cases.length === 0) {
            setSearching(false);
            return;
        }

        let slides = [];
        for (let j = 0; j < serverCreds.path.length; j++) {
            for (let i = 0; i < cases.length; i++) {
                let casePath = `${serverCreds.path[j]}${getUrlKey(cases[i])}`;
                let caseSlides = await getSlides(casePath);
                slides = slides.concat(caseSlides);

                casePath = `${casePath}/WSI`;
                caseSlides = await getSlides(casePath);
                slides = slides.concat(caseSlides);
            }
        }

        setSearching(false);
        setSelectedSlides(slides.map(x => { return { path: x }; }));
        return slides;
    }

    const getSlidesFromQuery = async (cases) => {
        setSearching(true);
        if (!cases || cases.length === 0) {
            setSearching(false);
            return;
        }

        let slides = [];
        let selectedSlidesFromQuery = [];
        for (let j = 0; j < serverCreds.path.length; j++) {
            for (let i = 0; i < cases.length; i++) {
                let sldName = querySlidesList[cases[i]].name;
                let casePath = `${serverCreds.path[j]}${getUrlKey(querySlidesList[cases[i]].caseNumber)}`;
                let caseSlides = await getSlides(casePath);
                slides = slides.concat(caseSlides);
                casePath = `${casePath}/WSI`;
                caseSlides = await getSlides(casePath);
                slides = slides.concat(caseSlides);
                selectedSlidesFromQuery = selectedSlidesFromQuery.concat(caseSlides.filter((sld) => sld.includes(sldName)));
            }
        }

        setSearching(false);
        setSelectedSlides(selectedSlidesFromQuery.map(x => { return { path: x }; }));
        return selectedSlidesFromQuery.map(x => { return { path: x }; });
    }

    const oNumberFormatter = (cell, row, rowIndex) => {
        return <span>{row.uzbTissueNumber}</span>;
    }


    const openCaseDetails = (uzbTissueNumber, oNumber, rowIndex) => {
        let index = ((syncDataContext.data.searchModel.page - 1) * syncDataContext.data.searchModel.sizePerPage) + rowIndex;
        setCaseForCaseDetailsPage(uzbTissueNumber, oNumber, index);
    }

    const setCaseForCaseDetailsPage = async (caseNumber, oNumber, index) => {
        await syncDataContext.setSyncData({
            ...syncDataContext.data,
            caseNumber: caseNumber,
            oNumber: oNumber,
            index: index
        });
    }


    const fixSortField = (sortField) => {
        if (!sortField) {
            return "";
        }

        return sortField == "uzbTissueNumber" ? "UZBTissueNumber" : sortField.substring(0, 1).toUpperCase() + sortField.substring(1);
    }

    const unfixSortField = (sortField) => {
        if (!sortField) {
            return "";
        }

        if (sortField == "UZBTissueNumber") {
            return sortField;
        }

        return sortField == "UZBTissueNumber" ? "uzbTissueNumber" : sortField.substring(0, 1).toLowerCase() + sortField.substring(1);
    }

    const queryColumns = [
        {
            dataField: 'caseNumber',
            text: 'Case',
            sort: false,
            headerStyle: { width: "110px" }
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: false,
            headerStyle: { width: "110px" }
        }
    ];

    const pulzColumns = [
        {
            dataField: 'UZBTissueNumber',
            text: 'Case',
            sort: true,
            formatter: oNumberFormatter,
            headerStyle: { width: "110px" }
        },
        {
            dataField: 'tissueType',
            text: 'Case description',
            sort: true,
            headerStyle: { width: "200px" }
        },
        {
            dataField: 'scannedCount',
            text: 'WSI created',
            sort: true,
            formatter: (x, row) => { return `${x} / ${row["totalCount"]}`; },
            headerStyle: { width: "90px" }
        }
    ];

    const rowStyle = (row, rowIndex) => {
        if (!statuses || statuses.length == 0) {
            return;
        }

        let s = statuses.find(s => s.id == row.caseStatus);
        if (!s) {
            return;
        }

        return { color: s.color };
    };

    const fetchStatuses = async () => {
        let url = `api/StatusDescriptions`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status == 401) {
            navigate('/signin', {
                state: { from: encodeURIComponent(location.pathname) }
            });

            return;
        }

        let result = await resp.json();
        setStatuses(result);
    }


    useEffect(() => {
        document.title = `Tasks - Pathology Image Management System`;
        fetchStatuses();
        fetchPimsQueries();
        fetchCustomQueries();
        if (location.search) {
            let sqs = new URLSearchParams(location.search);
            let sizePerPage = parseInt(sqs.get("sizePerPage"));
            let sortOrder = sqs.get("sortOrder");
            let sortField = sqs.get("sortField");
            let page = sqs.get("page") ?? 1;

            let searchModel = {
                sortField: fixSortField(sortField),
                sortOrder: sortOrder,
                customQueryFilter: "",
                dateToFilter: null,
                dateFromFilter: null,
                subSpecialismFilter: "",
                tissueTypeFilter: "",
                orderNumberFilter: "",
                caseFilter: "",
                diagnosticCodesFilter: "",
                page: page,
                sizePerPage: sizePerPage,
            };

            let keys = Object.keys(searchModel);
            for (let i = 0; i < keys.length; i++) {
                let k = keys[i];
                const val = sqs.get(k);

                switch (k) {
                    case "page":
                    case "sizePerPage":
                        searchModel[k] = parseInt(val);
                        searchModel[k] = parseInt(val);
                        break;
                    case "dateToFilter":
                    case "dateFromFilter":
                        if (!val || val == "") {
                            searchModel[k] = null;
                        }
                        else {
                            searchModel[k] = val;
                        }
                        break;
                    default:
                        searchModel[k] = val ?? "";
                        break;
                }
            }

            syncDataContext.setSyncData({
                ...syncDataContext.data,
                searchModel: searchModel
            });
        }
        else {
            setForceReload(true);
        }
    }, []);

    const [loadDataTimeout, setLoadDataTimeout] = useState(0);
    useEffect(() => {
        const prevLastImportDate = (previousSyncData.current && previousSyncData.current.lastImportDate) ? previousSyncData.current.lastImportDate : syncDataContext.data.lastImportDate;
        if (syncDataContext.data.lastImportDate > prevLastImportDate) {
            setPopoverOpen(true);
        }

        if (!syncDataContext.data.loadedFromServer) {
            return;
        }

        if (!previousSyncData.current || (forceReload || JSON.stringify(previousSyncData.current.searchModel) !== JSON.stringify(syncDataContext.data.searchModel))) {
            clearTimeout(loadDataTimeout);

            const tmp = setTimeout(() => {
                fetchCases(syncDataContext.data.searchModel.page, syncDataContext.data.searchModel.sizePerPage, syncDataContext.data.searchModel.sortOrder, syncDataContext.data.searchModel.sortField, syncDataContext.data.searchModel).then(x => {
                    fixQueryString(syncDataContext.data.page, syncDataContext.data.sizePerPage, syncDataContext.data.sortOrder, fixSortField(syncDataContext.data.sortField));
                    setCaseList(x);

                });
            }, 500);

            setLoadDataTimeout(tmp);
        }

        previousSyncData.current = syncDataContext.data;
        setForceReload(false);
    }, [syncDataContext.data, forceReload]);


    const setSearchModelParameter = (parameter, value) => {
        syncDataContext.setSyncData({
            ...syncDataContext.data,
            searchModel: {
                ...syncDataContext.data.searchModel,
                [parameter]: value,
                page: 1,
            }
        });
    }


    const handleTableChange = (type, props) => {

        if (type == "sort") {
            syncDataContext.setSyncData({
                ...syncDataContext.data,
                searchModel: {
                    ...syncDataContext.data.searchModel,
                    sortOrder: props.sortOrder,
                    sortField: fixSortField(props.sortField)
                }
            });
        }

        else if (type == "pagination") {
            syncDataContext.setSyncData({
                ...syncDataContext.data,
                searchModel: {
                    ...syncDataContext.data.searchModel,
                    page: props.page,
                    sizePerPage: props.sizePerPage
                }
            });
        }
    }


    const filterChanged = (e) => {
        var val = e.target.value;
        if (e.target.name == "case") {
            setSearchModelParameter("caseFilter", val);
        }
        if (e.target.name == "tissueType") {
            setSearchModelParameter("tissueTypeFilter", val);
        }
    }

    const fixQueryString = (page, sizePerPage, sortOrder, sortField) => {
        let sqs = new URLSearchParams();
        sqs.set("page", page);
        sqs.set("sizePerPage", sizePerPage);
        sqs.set("sortOrder", sortOrder);
        sqs.set("sortField", fixSortField(sortField));

        let keys = Object.keys(syncDataContext.data.searchModel);
        for (let i = 0; i < keys.length; i++) {
            let k = keys[i];

            if (syncDataContext.data.searchModel[k] && syncDataContext.data.searchModel[k] !== "undefined") {
                sqs.set(`${k}`, syncDataContext.data.searchModel[k]);
            }
            else if (sqs.get(`${k}`)) {
                sqs.delete(`${k}`);
            }
        }
    }


    const fetchCases = async (page, pageSize, sortOrder, sortField, filters) => {
        setSearching(true);
        let serverSideSortField = sortField;
        if (sortField) {
            serverSideSortField = fixSortField(sortField);
        }
        else {
            serverSideSortField = "DateReceived";
        }

        let url = `api/cases?page=${page}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortField=${serverSideSortField}`;
        if (filters) {
            let keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                let k = keys[i];
                if (k == "needsRefresh") {
                    continue;
                }

                if (filters[k]) {
                    url += `&${k}=${filters[k]}`;
                }
            }
        }

        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', {
                state: { from: encodeURIComponent(location.pathname) }
            });

            return;
        }

        let result = await resp.json();
        result.cases.forEach(x => {
            x.status = x.stainingCount === x.stainedCount ? 2 : 5;
        });

        setSearching(false);

        return {
            cases: result.cases,
            total: result.total
        };
    }


    const fetchServerSettings = async () => {
        const res = await fetch(`api/Settings`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (res.status === 404) {
            return;
        }

        var screds = await res.json();

        const res1 = await fetch(`api/PmaCoreSessionId`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res1.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (res1.status === 404) {
            return;
        }

        var sessionIdResult = await res1.json();

        setServerCreds({ username: screds.username, serverUrl: screds.serverUrl, path: screds.path, sessionId: sessionIdResult.sessionId });
    }

    useEffect(() => {
        fetchServerSettings();
    }, []);

    useEffect(() => {
        if (!serverCreds || !serverCreds.serverUrl) {
            return;
        }
        createSourceTree(serverCreds.serverUrl, serverCreds.sessionId);
        createDestinationTree(serverCreds.serverUrl, serverCreds.sessionId);
    }, [serverCreds]);


    const createDirectoryAction = () => {
        if (typeof (selectedFolder) === "string") {

            const folderName = window.prompt(`Create sub directory under ${selectedFolder}.\nPlease type the new folder's name.`);
            const pathName = selectedFolder.concat('/', folderName);

            if (!folderName) {
                return;
            }

            createDirectory(serverCreds.sessionId, pathName, serverCreds.serverUrl)
                .then(() => {
                    treeDestinationInstance.current.refresh("PIMS/" + selectedFolder);
                });
        }
    }


    const actionSelectedClick = (selectedAction) => {
        if (!selectedSlides || selectedSlides.length === 0) {
            return;
        }

        let taskType = selectedAction;
        if (selectedAction === 0) {
            setSelectedAction(taskType);
            if (selectedSlides.length > 100) {
                if (!window.confirm(`Are you sure you want to add ${selectedSlides.length} tasks? It is a large number of tasks.`)) {
                    return;
                }
            }

            if (rename) {
                setModal(true);
                return;
            }
        }
        else if (selectedAction === 1) {
            setSelectedAction(taskType);
            if (selectedSlides.length > 100 && !window.confirm(`Are you sure you want to add ${selectedSlides.length} tasks? It is a large number of tasks.`)) {
                return;
            }

            if (!window.confirm(`Are you sure you want to move these ${selectedSlides.length} images to ${selectedFolder}? This action cannot be reverted.`)) {
                return;
            }
            else if (rename) {
                setModal(true);
                return;
            }
        }
        else if (selectedAction === 2) {
            setSelectedAction(taskType);
            if (selectedSlides.length > 100 && !window.confirm(`Are you sure you want to add ${selectedSlides.length} tasks? It is a large number of tasks.`)) {
                return;
            }

            if (!window.confirm(`Are you sure you want to permanently delete these ${selectedSlides.length} images? This action cannot be reverted.`)) {
                return;
            }
        }
        else {
            return;
        }

        simpleTaskActions(taskType);
    }


    const simpleTaskActions = (taskType) => {
        createServerTasks(selectedSlides, selectedFolder, taskType, keepStructure);
    }

    const executeRenameTask = () => {
        if (!selectedSlides.some(x => x.hasOwnProperty("rename"))) {
            alert("Please perform a test first!");
        }
        else {
            createServerTasks(selectedSlides, selectedFolder, selectedAction, keepStructure);
        }
    }

    const renameTaskActions = () => {
        let mask = document.getElementById("rename").value;
        let localSelectedSlides = [].concat(selectedSlides);

        for (let i = 0; i < localSelectedSlides.length; i++) {
            const slidePath = localSelectedSlides[i].path;
            const fullName = slidePath.split("/").pop().split('.');
            const slideName = fullName[0];
            let destName = mask.replaceAll("#slide-path#", slidePath).replaceAll("#slide-name#", slideName);

            let j = 0;
            let replacements = [];
            while (j < destName.length) {

                if (destName[j] === '[') {
                    let regexStart = j + 1;
                    j = regexStart;

                    while (j < destName.length && destName[j] !== ']') {
                        j++;
                    }

                    if (j >= destName.length) {
                        continue;
                    }

                    let regexEnd = j;
                    let regexEntry = destName.substring(regexStart, regexEnd);
                    let regexResult = regexEntry.split('|');
                    let regex = regexResult[0];
                    let regexValue = regexResult[1];
                    let rx = new RegExp(regex);
                    let result = regexValue.match(rx);
                    if (!result) {
                        replacements.push({ input: regexEntry, output: "" });
                    }
                    else if (result && result.length > 0) {
                        replacements.push({ input: regexEntry, output: result[0] });
                    }
                }
                j++;
            }
            for (let r = 0; r < replacements.length; r++) {
                destName = destName.replace(`[${replacements[r].input}]`, replacements[r].output);
            }

            localSelectedSlides[i].rename = destName;
        }
        setSelectedSlides(localSelectedSlides);
    }

    const createServerTasks = (slides, destinationDirectory, taskType, keepStructure, fromKeepStructureModal) => {
        if (keepStructure && fromKeepStructureModal !== true) {
            let paths = slides.map(x => x.path);
            let chars = paths[0].split('');
            let common = [];

            for (let i = 0; i < chars.length; i++) {
                let flag = false;
                for (let j = 1; j < paths.length; j++) {

                    if (chars[i] !== paths[j][i]) {
                        flag = true;
                        break;
                    }
                }

                if (flag) {
                    break;
                }

                common.push(chars[i]);
            }

            common = common.join('').split('/');

            if (common.length > 0 && common[common.length - 1] !== '/') {
                common.pop();
            }

            let commonPathTmp = common.join('/');
            if (!commonPathTmp.endsWith("/")) {
                commonPathTmp += "/";
            }

            selectedTaskType.current = taskType;
            setCommonPath(commonPathTmp);
            setKeepStructureModalOpen(true);
            return;
        }

        slides.map(async (slide) => {
            if (keepStructure) {
                let destPath = slide.path.replace(commonPath, `${selectedFolder}/`);
                destinationDirectory = destPath.slice(0, destPath.lastIndexOf('/'));
            }

            const resp = await fetch(`api/PimsTasks/Create`,
                {
                    method: "POST",
                    headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                    body: JSON.stringify({
                        pimsTaskType: taskType,
                        sourceImagePath: slide.path,
                        destinationPath: destinationDirectory || "empty",
                        rename: slide.rename ?? ""
                    })
                });


            if (resp.status !== 200) {
                return;
            }
        });

        showTasks();
        handleSelectionAfterTask();
    };

    const fetchCustomQueries = async () => {
        let url = `api/CustomQueries/HomePage`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', {
                state: { from: encodeURIComponent(location.pathname) }
            });

            return;
        }

        let result = await resp.json();
        setCustomQueriesSelect(result);
    }


    const findImagesRecursively = async (directory, result) => {
        const subDirs = await getDirectories(directory);
        for (let j = 0; j < subDirs.length; j++) {
            await findImagesRecursively(subDirs[j], result);
        }

        const slides = await getSlides(directory);

        for (let i = 0; i < slides.length; i++) {
            if (result.indexOf(slides[i]) === -1) {
                result.push(slides[i]);
            }
        }
    }


    const selectImages = async () => {
        const selectedSourceDirectories = treeSourceInstance.current.getMultiSelection("directories");
        const selectedImages = treeSourceInstance.current.getMultiSelection("slides").map(x => { return { path: x.path }; });

        let results = [];
        for (let i = 0; i < selectedSourceDirectories.length; i++) {
            await findImagesRecursively(selectedSourceDirectories[i].path, results);
        }

        for (let i = 0; i < selectedImages.length; i++) {
            if (results.indexOf(selectedImages[i].path) === -1) {
                results.push(selectedImages[i].path);
            }
        }

        setSelectedSlides(results.map(x => { return { path: x }; }));
    }


    const createSourceTree = (serverUrl, sessionId) => {
        new SessionLogin(context.current, [{ serverUrl, sessionId }]);
        treeSourceInstance.current = new Tree(context.current, {
            servers: [
                {
                    name: "PIMS",
                    url: serverUrl,
                }
            ],
            element: treeSourceRef.current,
            autoExpandNodes: true,
            checkboxes: true,
            search: true,
        });

        treeSourceInstance.current.listen(ComponentEvents.MultiSelectionChanged, function () {
            if (selectedImagesTimeout.current) {
                clearTimeout(selectedImagesTimeout.current);
            }
            selectedImagesTimeout.current = setTimeout(() => { selectImages(); }, 1000);
        });
    }

    const createDestinationTree = (serverUrl, sessionId) => {
        new SessionLogin(context.current, [{ serverUrl, sessionId }]);
        treeDestinationInstance.current = new Tree(context.current, {
            servers: [
                {
                    name: "PIMS",
                    url: serverUrl,
                    showFiles: false,
                }
            ],
            element: treeDestinationRef.current,
            autoExpandNodes: true,
        });

        treeDestinationInstance.current.listen(ComponentEvents.DirectorySelected, function () {
            setSelectedFolder(treeDestinationInstance.current.getSelectedDirectory()?.path ?? null);
        });

    }

    const getWSIS = async (cases) => {
        let url = 'api/cases/GetWsis';
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify(cases),
            });

        if (resp.status !== 200) {
            return;
        }

        await !!resp.json();
    }

    const fetchPimsQueries = async () => {
        const resp = await fetch('api/WsiQueries',
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" }
            }
        );

        if (resp.status === 401) {
            navigate('/signin', {
                state: { from: encodeURIComponent(location.pathname) }
            });

            return;
        }

        if (resp.status !== 200) {
            return;
        }

        let result = await resp.json();
        setPimsQueriesSelect(result);
    }

    const fetchCasesFromQueries = async (queryId) => {
        setSearching(true);
        let url = `api/WsiQueries/${queryId}`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" }
            }
        );

        if (resp.status === 401) {
            navigate('/signin', {
                state: { from: encodeURIComponent(location.pathname) }
            });

            return;
        }

        if (resp.status !== 200) {
            return;
        }

        let result = await resp.json();
        result = result.map((x, i) => { return Object.assign({ id: i }, x) });
        setSearching(false);
        setQuerySlidesList(result);
    }


    useEffect(() => {
        if (!casesTable || !casesTable.current || !casesTable.current.selectionContext || !casesTable.current.selectionContext.selected) {
            return;
        }

        if (selectedCasesTimeout.current) {
            clearTimeout(selectedCasesTimeout.current);
        }

        if (casesTimeout.current) {
            clearTimeout(casesTimeout.current);
        }

        if (casesTable.current.selectionContext.selected.length !== 0) {
            casesTimeout.current = setTimeout(() => { getSlidesFromCase(casesTable?.current?.selectionContext?.selected) }, 500);
        }

        const cases = [].concat(casesTable.current.selectionContext.selected);
        if (cases.length !== 0) {
            selectedCasesTimeout.current = setTimeout(() => { getWSIS(cases) }, 500);
        }
        if (cases.length === 0) {
            setSelectedSlides([]);
        }
    }, [casesTable?.current?.selectionContext?.selected]);


    useEffect(() => {
        if (!queryCasesTable || !queryCasesTable.current || !queryCasesTable.current.selectionContext || !queryCasesTable.current.selectionContext.selected) {
            return;
        }

        if (selectedQuerySlidesTimeout.current) {
            clearTimeout(selectedQuerySlidesTimeout.current);
        }

        if (queryCasesTable.current.selectionContext.selected.length !== 0) {
            selectedQuerySlidesTimeout.current = setTimeout(() => { getSlidesFromQuery(queryCasesTable?.current?.selectionContext?.selected) }, 500);
        }

        const cases = [].concat(queryCasesTable.current.selectionContext.selected);
        if (cases.length === 0) {
            setSelectedSlides([]);
        }
    }, [queryCasesTable?.current?.selectionContext?.selected]);


    const handleSelectionAfterTask = () => {
        setTimeout(() => {
            if (currentActiveTab === '1') {
                treeSourceInstance.current.clearMultiSelection();
            }
            else if (currentActiveTab === '2') {
                casesTable.current.selectionContext.selected = [];
            }
            else {
                queryCasesTable.current.selectionContext.selected = [];
            }
        }, 2000);

    }

    // Alert functions
    const showTasks = () => {
        setVisible(true);
        setTimeout(() => {
            setVisible(false);
        }, 2000);

    }

    const onDismiss = () => setVisible(false)


    return (

        <Row className="h-100">
            <Col style={{ maxHeight: "100%" }} className="w-50">
                <Card color="secondary" outline className="cardHeight">
                    <CardHeader className="d-flex">
                        <CardTitle tag="h5">Select Images</CardTitle>
                    </CardHeader>
                    <CardBody className="cardScroll">
                        <Row>
                            <Col>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: currentActiveTab === '1' }, "tabPointers")}
                                            onClick={() => {
                                                toggleTab('1');
                                                setSelectedSlides([]);
                                                if (currentActiveTab !== '1') {
                                                    if (casesTable.current !== null) {
                                                        casesTable.current.selectionContext.selected = [];
                                                    }
                                                    if (queryCasesTable.current !== null) {
                                                        queryCasesTable.current.selectionContext.selected = [];
                                                    }
                                                }
                                            }}>Folder
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: currentActiveTab === '2' }, "tabPointers")}
                                            onClick={() => {
                                                toggleTab('2');
                                                setSelectedSlides([]);
                                                if (queryCasesTable.current !== null) {
                                                    queryCasesTable.current.selectionContext.selected = [];
                                                }
                                            }}>PULZ
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: currentActiveTab === '3' }, "tabPointers")}
                                            onClick={() => {
                                                toggleTab('3');
                                                setSelectedSlides([]);
                                                if (casesTable.current !== null) {
                                                    casesTable.current.selectionContext.selected = [];
                                                }
                                            }}>Queries
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                        <Row hidden={currentActiveTab !== '1'}>
                            <Col>
                                <div id="treeview" ref={treeSourceRef}>
                                </div>
                            </Col>
                        </Row>
                        <Row hidden={currentActiveTab !== '2'}>
                            <Col>
                                <CardTitle tag="h5">Select a query</CardTitle>
                                <InputGroup>
                                    <select value={syncDataContext?.data?.searchModel?.customQueryFilter ?? ""} className="form-select" name="pulzQuery" id="pulzQuery" title="pulzQuery" onChange={e => setSearchModelParameter("customQueryFilter", e.target.value)}>
                                        <option value={""}>
                                            No query selected
                                        </option>
                                        {customQueriesSelect &&
                                            customQueriesSelect.sort((a, b) => a.category === b.category ? a.order > b.order : a.category < b.category).map((cq) =>
                                                <option value={cq.id.toString()} key={cq.id}>
                                                    {cq.name}
                                                </option>)}
                                    </select>
                                </InputGroup>
                            </Col>
                        </Row>
                        {syncDataContext.data.loadedFromServer &&
                            <Row hidden={currentActiveTab !== '2'} style={{ height: "calc(100% - 110px)" }}>
                                <Col className="h-100">
                                    <BootstrapTable
                                        ref={n => casesTable.current = n}
                                        bootstrap4
                                        remote
                                        keyField="uzbTissueNumber"
                                        data={caseList.cases}
                                        columns={pulzColumns}
                                        selectRow={{ mode: 'checkbox', clickToSelect: true }}
                                        defaultSorted={[{ dataField: unfixSortField(syncDataContext.data.searchModel.sortField), order: syncDataContext.data.searchModel.sortOrder }]}
                                        sort={{ dataField: unfixSortField(syncDataContext.data.searchModel.sortField), order: syncDataContext.data.searchModel.sortOrder }}
                                        pagination={paginationFactory({ sizePerPageList: [20, 50, 100], page: parseInt(syncDataContext.data.searchModel.page), sizePerPage: syncDataContext.data.searchModel.sizePerPage, totalSize: caseList.total, showTotal: true, withFirstAndLast: true })}
                                        onTableChange={handleTableChange}
                                        classes="cases-table h-100 overflow-auto"
                                        rowStyle={rowStyle}
                                        headerClasses="table-light"
                                        wrapperClasses="overflow-auto h-table"
                                    >
                                    </BootstrapTable>
                                    {searching ? <div className="ui-loadingBlock"></div> : <></>}
                                </Col>
                            </Row>
                        }
                        <Row hidden={currentActiveTab !== '3'}>
                            <Col>
                                <CardTitle tag="h5">Select a query</CardTitle>
                                <InputGroup>
                                    <select className='form-select' name="pimsQuery" id="pimsquery" title="pimsQuery" onChange={e => e.target.value ? fetchCasesFromQueries(e.target.value) : setQuerySlidesList([])}>
                                        <option value={""}>
                                            No query selected
                                        </option>
                                        {pimsQueriesSelect &&
                                            pimsQueriesSelect.map((q) =>
                                                <option value={q.id} key={q.id}>
                                                    {q.name}
                                                </option>
                                            )
                                        }
                                    </select>
                                </InputGroup>
                            </Col>
                        </Row>
                        {querySlidesList.length > 0 &&
                            <Row hidden={currentActiveTab !== '3'} style={{ height: "calc(100% - 110px)" }}>
                                <Col className="h-100">
                                    <BootstrapTable
                                        ref={n => queryCasesTable.current = n}
                                        bootstrap4
                                        remote
                                        keyField="id"
                                        data={querySlidesList}
                                        columns={queryColumns}
                                        selectRow={{ mode: 'checkbox', clickToSelect: true }}
                                        classes="cases-table h-100 overflow-auto"
                                        headerClasses="table-light"
                                        wrapperClasses="overflow-auto h-table"
                                    >
                                    </BootstrapTable>
                                    {searching ? <div className="ui-loadingBlock"></div> : <></>}
                                </Col>
                            </Row>
                        }
                    </CardBody>
                    <CardFooter>
                        {/* <span>{selectedSlides.length} images selected, {selectedSlides.reduce((total, item) => total + item.size, 0)} GB</span> */}
                        <span>{selectedSlides.length} images selected</span>
                    </CardFooter>
                </Card>
            </Col>

            <Col style={{ maxHeight: "100%" }} className="w-50">
                <Card color="secondary" outline className="cardHeight">
                    <Row>
                        <Col>
                            <CardHeader className='d-flex'>
                                <Col>
                                    <CardTitle tag="h5">Select Destination</CardTitle>
                                </Col>
                                <Col xs={2} style={{ marginRight: -15 }}>
                                    <Label for="keepStructure" style={{ fontSize: "110%", marginTop: 7.5 }}>Keep Structure&nbsp;
                                        <Input disabled={currentActiveTab === '2'} type="switch" value={keepStructure} name="keepStructure" label="Keep structure" onChange={() => { setKeepStructure(p => !p) }} />
                                    </Label>
                                </Col>
                                <Col xs={2} style={{ marginRight: -65 }}>
                                    <Label for="rename" style={{ fontSize: "110%", marginTop: 7.5 }}>Rename&nbsp;
                                        <Input disabled={currentActiveTab === '2'} type="switch" value={rename} name="rename" label="Rename" onChange={() => { setRename(p => !p) }} />
                                    </Label>
                                </Col>
                                <Col xs={2} style={{ marginRight: -30 }}>
                                    <Button disabled={typeof (selectedFolder) !== "string"} color="light" onClick={() => actionSelectedClick(0)}>Copy Selected</Button>
                                </Col>
                                <Col xs={1} style={{ marginRight: 10 }}>
                                    <Nav>
                                        <UncontrolledDropdown nav style={{ fontSize: "110%" }}>
                                            <DropdownToggle nav caret className="text-dark">Actions</DropdownToggle>
                                            <DropdownMenu end>
                                                <DropdownItem disabled={typeof (selectedFolder) !== "string"} onClick={() => createDirectoryAction()}>Create directory</DropdownItem>
                                                <DropdownItem disabled={typeof (selectedFolder) !== "string"} onClick={() => actionSelectedClick(1)}>Move selected</DropdownItem>
                                                <DropdownItem onClick={() => actionSelectedClick(2)}>Delete selected</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </Nav>
                                </Col>
                            </CardHeader>
                        </Col>
                    </Row>
                    <CardBody className="cardScroll">
                        <Row>
                            <Col>
                                <div id="treeview" ref={treeDestinationRef}>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>

            <Modal isOpen={modal} toggle={toggle} size="xl" centered={true} scrollable={true} backdrop="static">
                <ModalHeader>Rename slides</ModalHeader>
                <ModalBody>

                    <Form>
                        <Row>
                            <Col md={4}>
                                <FormGroup row>
                                    <Col>
                                        <Label tag="h5" for="rename">Rename Mask</Label>
                                        <Input className="form-control" type="text" name="rename" id="rename" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md={9}>
                                        <div><Label>Special fields: <ul><li>#slide-name#</li><li>#slide-path#</li></ul></Label></div>
                                    </Col>
                                    <Col md={3}>
                                        <Button block color="info" className="text-light" onClick={() => { renameTaskActions(); setTableActive(true) }}>Test</Button>
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <div><Label>Regular expression syntax: [regexp|value]</Label></div>
                                    <div><Label>Example: HE_#slide-name#_[\d+|#slide-path#]</Label></div>
                                </FormGroup>
                                <FormGroup>
                                    <span>{selectedSlides.length} images selected to be renamed</span>
                                </FormGroup>
                            </Col>
                            <Col md={8}>
                                {selectedSlides &&
                                    <Table data={selectedSlides} bordered responsive striped size="sm" hidden={!tableActive}>
                                        <thead>
                                            <tr>
                                                <th>Original</th>
                                                <th>Renamed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedSlides.map((sld) => (
                                                <tr key={sld.path}>
                                                    <td>{sld.path.slice(sld.path.lastIndexOf('/'), sld.path.lastIndexOf('.')).replace('/', '')}</td>
                                                    <td>{sld.rename}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                }
                            </Col>
                        </Row>
                    </Form>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => { executeRenameTask(); toggle() }}>OK</Button>
                    <Button color="secondary" onClick={() => toggle()}>Close</Button>
                </ModalFooter>
            </Modal>
            <Alert className="alertActions" hidden={selectedSlides.length > 1} isOpen={visible} toggle={onDismiss} color="success">
                {selectedSlides.length} task has been created!
            </Alert>
            <Alert className="alertActions" hidden={selectedSlides.length <= 1} isOpen={visible} toggle={onDismiss} color="success">
                {selectedSlides.length} tasks have been created!
            </Alert>


            <Modal isOpen={keepStructureModalOpen} toggle={toggle} size="xl" centered={true} scrollable={true} backdrop="static">
                <ModalHeader>Preview move / copy</ModalHeader>
                <ModalBody>

                    <Form>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Col>
                                        <Label tag="h5" for="common-source-path">Common source path</Label>
                                        <Input className="form-control" type="text" name="common-source-path" id="common-source-path" value={commonPath} onChange={(evt) => setCommonPath(evt.target.value)} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {selectedSlides &&
                                    <Table data={selectedSlides} bordered responsive striped size="sm">
                                        <thead>
                                            <tr>
                                                <th>Source</th>
                                                <th>Target</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedSlides.map((sld) => {
                                                let destPath = sld.path.replace(commonPath, `${selectedFolder}/`);
                                                let destinationDirectory = `${destPath.slice(0, destPath.lastIndexOf('/'))}/${sld.path.split("/").pop()}`.replaceAll("//", "/");

                                                return (
                                                    <tr key={sld.path}>
                                                        <td>{sld.path}</td>
                                                        <td>{destinationDirectory}</td>
                                                    </tr>);
                                            })}
                                        </tbody>
                                    </Table>
                                }
                            </Col>
                        </Row>
                    </Form>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => { setKeepStructureModalOpen(false); createServerTasks(selectedSlides, selectedFolder, selectedTaskType.current, true, true); }}>OK</Button>
                    <Button color="secondary" onClick={() => setKeepStructureModalOpen(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Row>

    );
}
