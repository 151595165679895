import React, { useEffect, useRef, useState } from "react";
import {
    Row, Col, FormGroup, Input, Button, InputGroup, Label, DropdownToggle, DropdownMenu, DropdownItem,
    Modal, ModalHeader, ModalBody, Form, ModalFooter, ButtonGroup, Dropdown
} from 'reactstrap';
import { getAccessTokenAsync } from '../../actions/AuthActions';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import { faTimesCircle, faSearch, faTimes, faImages, faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";


const statusSwitcher = (v) => {
    // eslint-disable-next-line default-case
    switch (v) {
        case 0: return "New";
        case 1: return "Running";
        case 2: return "Finished";
        case 3: return "Error";
        case 4: return "Cancelled";
    }
}
export const Home = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({
        page: 1,
        data: [],
        totalSize: 0,
        sizePerPage: 50,
        loading: true,
        sortField: "id",
        sortOrder: "desc",
        initialized: false,
        modalOpen: null,
    });
    const initialCaseNumberValue = { searchString: "UZBrussel", caseNumber: "", validationMessage: "" };
    const [serverCreds, setServerCreds] = useState({});
    const [pmaCoreSession, setPmaCoreSession] = useState(null);
    const [caseNumberForm, setCaseNumberForm] = useState(initialCaseNumberValue);
    const [filters, setFilters] = useState({
        status: [0, 1, 2, 3, 4], source: "", dateFrom: "", dateTo: "", needsRefresh: false,
        customQuery: "",
    });

    const [stateDropdownOpen, setStateDropdownOpen] = useState(false);
    const [queriesList, setQueriesList] = useState([]);
    const [nonSelectableArray, setNonSelectableArray] = useState(null);
    const [selection, setSelection] = useState([]);
    const [modalTaskId, setModalTaskId] = useState(null);
    const [modalRowIndex, setModalRowIndex] = useState(null);
    const dataRef = useRef([]);
    const filtersRef = useRef(null);
    const tasksTable = useRef(null);

    useEffect(() => {
        dataRef.current = state?.data ?? [];
    }, [state?.data]);

    useEffect(() => {
        filtersRef.current = filters;
    }, [filters]);

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            sort: true,
            headerStyle: { width: "70px" },
            formatter: (cell, row, rowIndex) => {
                return <>
                    <span style={getColor(row.status)}>{cell}</span>
                </>;
            }
        },
        {
            dataField: 'taskType',
            text: 'Type',
            sort: true,
            headerStyle: { width: "80px" },
            formatter: (cell, row, rowIndex) => {
                // eslint-disable-next-line default-case
                switch (cell) {
                    case 0: return <>
                        <span style={getColor(row.status)}>Copy</span>
                    </>;
                    case 1: return <>
                        <span style={getColor(row.status)}>Move</span>
                    </>;
                    case 2: return <>
                        <span style={getColor(row.status)}>Delete</span>
                    </>;
                    case 3: return <>
                        <span style={getColor(row.status)}>Identify</span>
                    </>;
                }
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            headerStyle: { width: "100px" },
            formatter: (cell, row, rowIndex) => {
                return <>
                    <span style={getColor(row.status)}>{statusSwitcher(cell)}</span>
                </>;
            }
        },
        {
            dataField: 'sourcePath',
            text: 'Source',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>
                    <span style={getColor(row.status)}>{cell}</span>
                </>;
            }
        },
        {
            dataField: 'destinationPath',
            text: 'Destination',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>
                    <span style={getColor(row.status)}>{cell}</span>
                </>;
            }
        },
        {
            dataField: 'createdOn',
            text: 'Created On',
            sort: true,
            headerStyle: { width: "156px" },
            formatter: (cell, row, rowIndex) => {
                return <>
                    <span style={getColor(row.status)}>{cell ? moment(cell).format("LLLL") : "N/A"}</span>
                </>;
            },
        },
        {
            dataField: 'updatedOn',
            text: 'Updated On',
            sort: true,
            headerStyle: { width: "156px" },
            formatter: (cell, row, rowIndex) => {
                return <>
                    <span style={getColor(row.status)}>{cell ? moment(cell).format("LLLL") : "N/A"}</span>
                </>;
            }
        },
        {
            isDummyField: true,
            dataField: '',
            text: 'Actions',
            sort: false,
            headerStyle: (column, colIndex) => {
                return { width: '130px' };
            },
            formatter: (cell, row, rowIndex) => {
                return <>
                    <ButtonGroup size="sm" className="ml-auto">
                        <Button className="text-light" color="info" onClick={() => {
                            onInfo(rowIndex);
                        }}>Info</Button>
                        {row.status === 3 && <Button color="danger" onClick={() => {
                            onDelete(row.id);
                        }}>Delete</Button>}
                        {row.status === 0 && <Button color="primary" onClick={() => {
                            onCancel(row.id);
                        }}>Cancel</Button>}
                    </ButtonGroup>
                </>
            },
            style: () => {
                return { display: "flex" }
            }
        }
    ];


    const getColor = (status) => {
        if (status === 0 || status === 1) {
            return;
        }
        else if (status === 2) {
            return { color: "#6c757d" };
        }
        else if (status === 3) {
            return { color: "#dc3545" };
        }
        else if (status === 4) {
            return { color: "#6c757d" };
        }
        return;
    }

    const fetchPmaCoreSessionId = async () => {
        let url = `api/PmaCoreSessionId`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status === 204) {
            return;
        }

        setPmaCoreSession(await resp.json());
    }

    const fetchTasks = async (page, pageSize, sortOrder, sortField, filters) => {
        let serverSideSortField = sortField;
        if (sortField) {
            serverSideSortField = sortField.substring(0, 1).toUpperCase() + sortField.substring(1);
        }
        else {
            serverSideSortField = "";
        }

        let url = `api/PimsTasks?page=${page}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortField=${serverSideSortField}`;
        if (filters) {
            let keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                let k = keys[i];
                if (k == "needsRefresh") {
                    continue;
                }

                if (filters[k]) {
                    if (k == "status") {
                        url += `&${k}Filter=${filters[k].join(",")}`;
                        continue;
                    }

                    url += `&${k}Filter=${filters[k]}`;
                }
            }
        }

        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        return await resp.json();
    }

    const fetchServerSettings = async () => {
        const res = await fetch(`api/Settings`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (res.status === 404) {
            return;
        }

        var screds = await res.json();

        const res1 = await fetch(`api/PmaCoreSessionId`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res1.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (res1.status === 404) {
            return;
        }

        var sessionIdResult = await res1.json();

        setServerCreds({ username: screds.username, serverUrl: screds.serverUrl, path: screds.path, sessionId: sessionIdResult.sessionId });
    }

    useEffect(() => {
        document.title = "Home - Pathology Image Management System";
        fetchPmaCoreSessionId();
        fetchCustomQueries();
        fetchServerSettings();
    }, []);

    const handleTableChange = async (type, props) => {
        let result = await fetchTasks(props.page, props.sizePerPage, props.sortOrder, props.sortField, filters);
        setState(prvState => {
            return {
                ...prvState,
                data: result.tasks, sizePerPage: props.sizePerPage, page: result.currentPage, totalSize: result.total,
                sortField: props.sortField,
                sortOrder: props.sortOrder,
            }
        });
    }

    const onCancel = async (id) => {
        if (!window.confirm("Are you sure you want to cancel this task?")) {
            return;
        }

        let url = `api/PimsTasks/Cancel/${id}`;
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        filterClick(true);
        return;
    };

    useEffect(() => {
        if (!modalTaskId || dataRef.current.length === 0) {
            setState({ ...state, modalOpen: null });
            return;
        }

        const idx = dataRef.current.findIndex(x => x.id === modalTaskId);
        if (idx < 0) {
            setState({ ...state, modalOpen: null });
            return;
        }

        onInfo(idx);
    }, [modalTaskId])

    const deleteCorrespondingSlide = async (path) => {
        let pmaUrl = `${serverCreds.serverUrl}api/json/DeleteSlide`;
        const resp = await fetch(pmaUrl,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    SessionID: pmaCoreSession.sessionId,
                    Path: path,
                    DeleteAsFile: false
                })
            });

        if (resp.status !== 200) {
            alert("Error deleting the corresponding slide!");
        }
    }

    const onDeleteTaskAndSlide = async (id) => {
        if (!window.confirm("Are you sure you want to delete this task and the corresponding slide?")) {
            return;
        }

        let url = `api/PimsTasks/Delete/${id}`;
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        const idx = dataRef.current.findIndex(x => x.id === id);
        if (idx !== -1) {
            if (idx + 1 === dataRef.current.length) {
                setModalTaskId(null);
            }
            else {
                setModalTaskId(dataRef.current[idx + 1]?.id);
            }
        }

        deleteCorrespondingSlide(dataRef.current[idx].sourcePath);
        filterClick(true);
        return;
    }

    const onDelete = async (id) => {
        if (!window.confirm("Are you sure you want to delete this task?")) {
            return;
        }

        let url = `api/PimsTasks/Delete/${id}`;
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        filterClick(true);
        return;
    };

    const onInfo = (taskIndex) => {
        setCaseNumberForm(initialCaseNumberValue);
        setState({ ...state, data: dataRef.current, modalOpen: state.data[taskIndex] });
        setModalTaskId(state?.data[taskIndex]?.id);
        setModalRowIndex(taskIndex);
    };

    const clickPrevious = () => {
        if (modalRowIndex === 0) {
            return;
        }

        onInfo(modalRowIndex - 1);
    }

    const clickNext = () => {
        if (modalRowIndex === state.data.length - 1) {
            return;
        }

        onInfo(modalRowIndex + 1);
    }

    const filterChanged = (e) => {
        var val = e.target.value;
        if (e.target.name === "source") {
            setFilters({ ...filters, source: val });
        }
        if (e.target.name === "dateFrom") {
            setFilters({ ...filters, dateFrom: val });
        }
        if (e.target.name === "dateTo") {
            setFilters({ ...filters, dateTo: val });
        }
    }

    const filterClick = (useCurrentPage) => {
        fetchTasks(useCurrentPage ? state.page : 1, state.sizePerPage, state.sortOrder, state.sortField, filtersRef.current).then(x => {
            setState(prvState => { return { ...prvState, data: x.tasks, page: x.currentPage, totalSize: x.total, pageSize: x.pageSize } });
        });
    }

    const statusClick = (v) => {
        let check = filters.status.indexOf(v) > -1;
        if (check) {
            setFilters({ ...filters, status: filters.status.filter(s => s !== v) });
        }
        else {
            setFilters({ ...filters, status: [...filters.status, v] });
        }
    }

    const fetchCustomQueries = async () => {
        let url = `api/PimsQueries`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        setQueriesList(result);
    }

    const getImageBarcodeUrl = (path) => {
        return pmaCoreSession.serverUrl + `barcode?sessionID=${pmaCoreSession.sessionId}&pathOrUid=${path}&rnd=${Math.random()}`;
    };

    const getImageThumbnailUrl = (path) => {
        return pmaCoreSession.serverUrl + `thumbnail?sessionID=${pmaCoreSession.sessionId}&pathOrUid=${path}&w=500&rnd=${Math.random()}`;
    };

    const getPathFromTask = (task) => {
        if (!task) {
            return "";
        }

        if (task.status !== 2) {
            return task.sourcePath;
        }

        let fName = state.modalOpen?.sourcePath.split("/").pop();
        try {
            let d = JSON.parse(state.modalOpen?.data);
            if (d.RenameTo) {
                let ext = fName.split(".").pop();
                fName = d.RenameTo + "." + ext;
            }
        }
        catch {
        }

        return task.destinationPath + fName;
    }

    const submitCaseNumber = async (id) => {
        if (!caseNumberForm.searchString || id == null) {
            return;
        }

        let url = `api/PimsTasks/CaseNumber/${id}?searchString=${caseNumberForm.searchString}&caseNumber=${caseNumberForm.caseNumber}`;
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        if (result && result.success === false) {
            setCaseNumberForm(p => { return { ...p, validationMessage: result.message } });
            return;
        }

        setState({ ...state, modalOpen: null });
        filterClick(true);
    }

    const stateDropdownToggle = (e) => {
        if (e.target && typeof e.target.className === "string" && e.target.className.indexOf("dropdown-item") > -1) {
            return;
        }

        setStateDropdownOpen(ps => !ps);
    }

    const fetchTaskTypeLabel = (taskType) => {
        // eslint-disable-next-line default-case
        switch (taskType) {
            case 0: return "Copy";
            case 1: return "Move";
            case 2: return "Delete";
            case 3: return "Identify";
        }

        return "";
    }

    useEffect(() => {
        if (!state || !state.data) {
            setNonSelectableArray(null);
            return;
        }

        let nonSelectableArray = [];
        for (let i = 0; i < state.data.length; i++) {
            if (state.data[i].status !== 3) {
                nonSelectableArray.push(state.data[i].id);
            }
        }

        setNonSelectableArray(nonSelectableArray);
        setSelection([]);
    }, [state]);

    const selectRow = () => {
        return {
            mode: 'checkbox',
            clickToSelect: false,
            nonSelectable: nonSelectableArray,
            onSelect: (row, isSelect, rowIndex, e) => {
                if (isSelect) {
                    setSelection([...selection, row.id]);
                } else {
                    setSelection(selection.filter(x => x !== row.id));
                }
            }
        }
    };

    const deleteSelection = async () => {
        if (!selection || selection.length === 0) {
            return;
        }

        if (!window.confirm(`Are you sure you want to delete ${selection.length} tasks?`)) {
            return;
        }

        for (let i = 0; i < selection.length; i++) {
            let url = `api/PimsTasks/Delete/${selection[i]}`;
            const resp = await fetch(url,
                {
                    method: "POST",
                    headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
                });

            if (resp.status === 401) {
                navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
                return;
            }
        }

        filterClick(true);
        return;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
    }

    return (<>
        <Row>
            {/*<div className="col-2 pr-0">
                 <Card>
                    <CardBody>
                        <CardTitle key="title" tag="h5">Queries</CardTitle>
                        <ListGroup flush key="list">
                            {<ListGroupItem key="(None)" className="py-1 text-left" tag="button" active={!filters.customQuery}
                                onClick={() => { setFilters(pF => { return { ...pF, customQuery: "", needsRefresh: true } }); }}
                            ><span className="pl-3">None</span>
                            </ListGroupItem>}
                            {queriesList &&
                                queriesList.sort((a, b) => a.category - b.category).map((cq, i, arr) =>
                                    <React.Fragment key={`${i}_${cq.id}`}>
                                        {(i == 0 || arr[i].category != arr[i - 1].category) &&
                                            <ListGroupItem key={cq.category ? cq.category : "(no category)"} disabled className="py-1">{cq.category ? cq.category : "(No category)"}</ListGroupItem>}
                                        <ListGroupItem key={cq.id} tag="button" className="py-1 text-left" active={filters.customQuery == cq.id}
                                            onClick={() => { setFilters(pF => { return { ...pF, customQuery: cq.id, needsRefresh: true } }); }}
                                        ><span className="pl-3">{cq.name}</span></ListGroupItem>
                                    </React.Fragment>
                                )}
                        </ListGroup>
                    </CardBody>
                </Card> 
            </div>*/}
            <div className="col">
                <Row>
                    <div className="col d-flex align-items-stretch flex-column">
                        <div className="col">
                            <div className="form-group row mb-4">
                                <div className="form-group col">
                                    <Label htmlFor="order-number" className="mb-0">Status</Label>
                                    <InputGroup >
                                        <Dropdown className="w-100 status-dropdown" isOpen={stateDropdownOpen} toggle={stateDropdownToggle} >
                                            <DropdownToggle tag="div">
                                                <Input type="text" name="status" className="form-select dropdown-input tabPointers" onChange={() => { }} value={filters.status?.sort().map(s => statusSwitcher(s))?.join(", ")} />
                                            </DropdownToggle>
                                            <DropdownMenu style={{ width: "100%", maxHeight: "400px" }} className="overflow-auto">
                                                <DropdownItem active={filters.status.indexOf(0) > -1} key={0} onClick={() => { statusClick(0); }}>New</DropdownItem>
                                                <DropdownItem active={filters.status.indexOf(1) > -1} key={1} onClick={() => { statusClick(1); }}>Running</DropdownItem>
                                                <DropdownItem active={filters.status.indexOf(2) > -1} key={2} onClick={() => { statusClick(2); }}>Finished</DropdownItem>
                                                <DropdownItem active={filters.status.indexOf(3) > -1} key={3} onClick={() => { statusClick(3); }}>Error</DropdownItem>
                                                <DropdownItem active={filters.status.indexOf(4) > -1} key={4} onClick={() => { statusClick(4); }}>Cancelled</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </InputGroup>
                                </div>

                                <div className="form-group col-2">
                                    <Label htmlFor="stain-name" className="mb-0">Source</Label>
                                    <InputGroup>
                                        <Input className="form-control" onChange={filterChanged} value={filters.source} type="text" name="source" placeholder="Source" onKeyDown={e => e.key === "Enter" ? filterClick() : null} />
                                        <div className="input-group-append">
                                            <Button color="primary" onClick={() => { setFilters(prev => { return { ...prev, source: "", } }); }}>
                                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                            </Button>
                                        </div>
                                    </InputGroup>
                                </div>
                                <div className="form-group col-2">
                                    <Label htmlFor="date" className="mb-0">From</Label>
                                    <Input className="form-control" max={filters.dateTo} onChange={filterChanged} value={filters.dateFrom} name="dateFrom" type="date" onKeyDown={e => e.key === "Enter" ? filterClick() : null} />
                                </div>
                                <div className="form-group col-2">
                                    <Label htmlFor="date" className="mb-0">Until</Label>
                                    <Input className="form-control" min={filters.dateFrom} onChange={filterChanged} value={filters.dateTo} name="dateTo" type="date" onKeyDown={e => e.key === "Enter" ? filterClick() : null} />
                                </div>

                                <div className="form-group col d-flex align-items-end" >
                                    <ButtonGroup className="d-block">
                                        <Button key="1" color="primary" id="submit" onClick={filterClick}><FontAwesomeIcon icon={faSearch}></FontAwesomeIcon></Button>
                                        <Button key="2" color="danger" id="clear" onClick={() => { setFilters({ status: [0, 1, 2, 3, 4], source: "", dateFrom: "", dateTo: "", needsRefresh: true }); }}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></Button>
                                    </ButtonGroup>
                                </div>

                                <div className="col-auto d-flex align-items-end">
                                    <div className="btn-group d-block" role="group" aria-label="navigation">
                                        {/* <button className="btn btn-outline-secondary" title="Create"><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Create</button> */}
                                        <a href="/pims/viewer" target="_blank" rel="noreferrer nofollow" className="btn btn-outline-secondary" title="View whole slide images"><FontAwesomeIcon icon={faImages}></FontAwesomeIcon> WSI viewer</a>
                                        <button className="btn btn-info text-light" title="Sync tasks" onClick={() => { filterClick(); tasksTable.current.selectionContext.selected = [] }}><FontAwesomeIcon icon="fa-solid fa-arrows-rotate" style={{ color: "#ffffff" }} /> Sync</button>
                                        <button className="btn btn-danger" title="Delete selected tasks" disabled={selection.length === 0} onClick={deleteSelection}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon> Delete</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </Row>
                <Row style={{ height: "calc(100% - 10px)" }}>
                    <div className="col h-100">
                        <BootstrapTable
                            ref={n => tasksTable.current = n}
                            bootstrap4
                            remote
                            keyField="id"
                            data={state.data}
                            columns={columns}
                            defaultSorted={[{ dataField: state.sortField, order: state.sortOrder }]}
                            sort={{ dataField: state.sortField, order: state.sortOrder }}
                            pagination={paginationFactory({ sizePerPageList: [20, 50, 100], page: state.page, sizePerPage: state.sizePerPage, totalSize: state.totalSize, showTotal: true, withFirstAndLast: true })}
                            onTableChange={handleTableChange}
                            classes="pims-table h-100 overflow-auto"
                            selectRow={selectRow()}
                            // rowClasses={rowClasses}
                            headerClasses="table-light"
                            wrapperClasses="overflow-auto"
                        />
                    </div>
                </Row>
            </div>
        </Row>
        <Modal isOpen={state.modalOpen != null} size="xl">
            <ModalHeader>
                <Row>
                    <Col md={8}>
                        Task information
                    </Col>
                    <Col md={4}>
                        <ButtonGroup>
                            <Button disabled={modalRowIndex === 0} outline color="secondary" onClick={() => { clickPrevious(); }}><FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon></Button>
                            <Button disabled={modalRowIndex === state.data.length - 1} outline color="secondary" onClick={() => { clickNext(); }}><FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon></Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <Col>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                            <label className="font-weight-bold col-3" htmlFor="name">Id</label>
                            <Col>
                                <div className="form-control-static">{state.modalOpen?.id}</div>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                            <label className="font-weight-bold col-3">Source Path</label>
                            <Col>
                                <div className="form-control-static">{state.modalOpen?.sourcePath}</div>
                            </Col>
                        </FormGroup>
                        {state.modalOpen?.destinationPath &&
                            <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                                <label className="font-weight-bold col-3">Destination Path</label>
                                <Col>
                                    <div className="form-control-static">{state.modalOpen?.destinationPath}</div>
                                </Col>
                            </FormGroup>
                        }
                        <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                            <label className="font-weight-bold col-3">Type (Status)</label>
                            <Col>
                                <div className="form-control-static">{fetchTaskTypeLabel(state.modalOpen?.taskType)} ({statusSwitcher(state.modalOpen?.status)})</div>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                            <label className="font-weight-bold col-3">Data</label>
                            <Col>
                                <div className="form-control-static">
                                    <code><pre>
                                        {state.modalOpen?.data && JSON.stringify(JSON.parse(state.modalOpen?.data), null, 2)}
                                    </pre></code>
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                            <Col>
                                <div className="form-control-static text-danger">{state.modalOpen?.errorMessage}</div>
                            </Col>
                        </FormGroup>
                        {state.modalOpen?.taskType === 3 && state.modalOpen?.status === 3 &&
                            <>
                                <hr />
                                <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                                    <label className="font-weight-bold col-3">WSI name</label>
                                    <Col>
                                        <div className="input-group">
                                            <input pattern="^UZBrussel.*" title="The string must start with UZBrussel" type="text" className="form-control" placeholder="e.g. UZBrusselXXXXXX-X-X-X " value={caseNumberForm.searchString}
                                                onChange={(e) => { setCaseNumberForm({ ...caseNumberForm, searchString: e.target.value, validationMessage: e.target.validationMessage }); }} onKeyDown={e => e.key === "Enter" ? submitCaseNumber(state?.modalOpen?.id) : null} />
                                            <div className="input-group-append">
                                                <button disabled={caseNumberForm.searchString && caseNumberForm.validationMessage} className="btn btn-primary" type="button" onClick={() => { submitCaseNumber(state?.modalOpen?.id); }}>Set WSI name</button>
                                            </div>
                                        </div>
                                        <small className="form-text text-danger">{caseNumberForm.validationMessage}</small>
                                        <small className="form-text text-muted">(Enter the filename or barcode of the WSI that starts with UZBrussel)</small>
                                    </Col>
                                </FormGroup>
                                <hr />
                            </>
                        }
                        <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                            <Col className="d-flex flex-column">
                                <label className="text-center">Thumbnail</label>
                                {pmaCoreSession != null && state.modalOpen?.sourcePath &&
                                    <img src={getImageThumbnailUrl(getPathFromTask(state?.modalOpen))} alt="No thumbnail found"
                                        style={{ display: "block", width: "auto", height: "auto", maxHeight: "300px", maxWidth: "100%", margin: "0 auto" }} />
                                }
                            </Col>
                            <Col className="d-flex flex-column">
                                <label className="text-center">Barcode</label>
                                {pmaCoreSession != null && state.modalOpen?.sourcePath &&
                                    <img src={getImageBarcodeUrl(getPathFromTask(state?.modalOpen))} alt="No barcode found"
                                        style={{ display: "block", width: "auto", height: "auto", maxHeight: "300px", maxWidth: "100%", margin: "0 auto" }} />}
                            </Col>
                        </FormGroup>
                    </Form>
                </Col>
            </ModalBody>
            <ModalFooter>
                {state.modalOpen?.taskType === 3 && state.modalOpen?.status === 3 &&
                    <Button type="button" color="danger" onClick={() => { onDeleteTaskAndSlide(modalTaskId); }}>Delete task & slide</Button>
                }
                <Button type="button" color="secondary" onClick={() => {
                    setState({ ...state, modalOpen: null });
                    setModalTaskId(null);
                    setModalRowIndex(null);
                }}>Close</Button>
            </ModalFooter>
        </Modal>
    </>);
}