import React, { useEffect, useState } from "react";
import { Row, Popover, PopoverBody, Input, Button, InputGroup, Label, Card, CardBody, CardTitle, ButtonGroup, ListGroup, ListGroupItem } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getAccessTokenAsync } from '../../../actions/AuthActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { faTimesCircle, faTimes, faCogs, faSync } from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { QueryEdit } from "./QueryEdit";
import { PageLinksDropdown } from "../ui/PageLinksDropdown";
import useSyncDataContext from "../state/useSyncData";
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import logo2 from "../../../img/logo2.svg";

const Home = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const syncDataContext = useSyncDataContext();

    const [caseList, setCaseList] = useState({ cases: [], total: 0 });
    const [searching, setSearching] = useState(false);
    const [customQueriesList, setCustomQueriesList] = useState([]);
    const previousSyncData = React.useRef(null);
    const [statuses, setStatuses] = useState([]);
    const [queryEditModalOpen, setQueryEditModalOpen] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [forceReload, setForceReload] = useState(false);

    const oNumberFormatter = (cell, row, rowIndex) => {
        return <Button color="link" size="sm" onClick={(e) => { e.preventDefault(); openCaseDetails(row.uzbTissueNumber, row.oNumber, rowIndex); }}>{row.uzbTissueNumber}</Button>;
    }

    const fixSortField = (sortField) => {
        if (!sortField) {
            return "";
        }

        return sortField === "uzbTissueNumber" ? "UZBTissueNumber" : sortField.substring(0, 1).toUpperCase() + sortField.substring(1);
    }

    const unfixSortField = (sortField) => {
        if (!sortField) {
            return "";
        }

        if (sortField === "UZBTissueNumber") {
            return sortField;
        }

        return sortField === "UZBTissueNumber" ? "uzbTissueNumber" : sortField.substring(0, 1).toLowerCase() + sortField.substring(1);
    }

    const columns = [
        {
            dataField: 'UZBTissueNumber',
            text: 'Case',
            sort: true,
            formatter: oNumberFormatter,
            formatExtraData: syncDataContext.data,
            headerStyle: { width: "110px" }
        },
        {
            dataField: 'tissueType',
            text: 'Case description',
            sort: true,
            headerStyle: { width: "250px" }
        },
        {
            dataField: 'dateReceived',
            text: 'Date Received',
            sort: true,
            headerStyle: { width: "151px" },
            formatter: x => x ? moment(x).format("ddd, DD MMM YYYY") : "N/A"
        },
        {
            dataField: 'subspecialism',
            text: 'Subspecialism',
            sort: true,
            headerStyle: { width: "250px" }
        },
        {
            dataField: 'validatedCount',
            text: 'Protocols',
            sort: true,
            formatter: (x, row) => { return `${x} / ${row["orderCount"]} validated`; },
            headerStyle: { width: "150px" }
        },
        {
            dataField: 'mountedCount',
            text: 'Coverslipped',
            sort: true,
            formatter: (x, row) => { return `${x} / ${row["totalCount"]}`; },
            headerStyle: { width: "120px" }
        },
        {
            dataField: 'scannedCount',
            text: 'WSI created',
            sort: true,
            formatter: (x, row) => { return `${x} / ${row["totalCount"]}`; },
            headerStyle: { width: "110px" }
        },
        {
            dataField: 'analyzedCount',
            text: 'Analyzed',
            sort: false,
            headerStyle: { width: "120px" }
        },
        {
            dataField: 'diagnosticCodes',
            text: 'Diagnostic Codes',
            sort: true,
            headerStyle: { width: "120px" },
            formatter: (x, row) => { return row["diagnosticCodes"].join(", "); }
        },
        {
            dataField: 'otherTasks',
            text: 'Other tasks',
            sort: true,
            formatter: (x, row) => { return x.map(s => s.split("^")[1]).join(", "); },
            style: { whiteSpace: "nowrap" },
            title: function callback(cell, row, rowIndex, colIndex) { return cell; }
        }];

    const rowClasses = (row, rowIndex) => {
        if (!syncDataContext.data.caseNumber) {
            return;
        }
        if (row.uzbTissueNumber.toLowerCase() === syncDataContext.data.caseNumber.toLowerCase()) {
            return "table-active";
        }
    }

    const rowStyle = (row, rowIndex) => {
        if (!statuses || statuses.length === 0) {
            return;
        }

        let s = statuses.find(s => s.id === row.caseStatus);
        if (!s) {
            return;
        }

        return { color: s.color };
    };

    useEffect(() => {
        document.title = `PIMS`;
        fetchStatuses();
        fetchCustomQueries();
        if (location.search) {
            let sqs = new URLSearchParams(location.search);
            let sizePerPage = parseInt(sqs.get("sizePerPage"));
            let sortOrder = sqs.get("sortOrder");
            let sortField = sqs.get("sortField");
            let page = sqs.get("page") ?? 1;

            let searchModel = {
                sortField: fixSortField(sortField),
                sortOrder: sortOrder,
                customQueryFilter: "",
                dateToFilter: null,
                dateFromFilter: null,
                subSpecialismFilter: "",
                tissueTypeFilter: "",
                orderNumberFilter: "",
                caseFilter: "",
                diagnosticCodesFilter: "",
                page: page,
                sizePerPage: sizePerPage,
            };

            let keys = Object.keys(searchModel);
            for (let i = 0; i < keys.length; i++) {
                let k = keys[i];
                const val = sqs.get(k);

                switch (k) {
                    case "page":
                    case "sizePerPage":
                        searchModel[k] = parseInt(val);
                        searchModel[k] = parseInt(val);
                        break;
                    case "dateToFilter":
                    case "dateFromFilter":
                        if (!val || val === "") {
                            searchModel[k] = null;
                        }
                        else {
                            searchModel[k] = val;
                        }
                        break;
                    default:
                        searchModel[k] = val ?? "";
                        break;
                }
            }

            syncDataContext.setSyncData({
                ...syncDataContext.data,
                searchModel: searchModel
            });
        }
        else {
            setForceReload(true);
        }
    }, []);

    const [loadDataTimeout, setLoadDataTimeout] = useState(0);
    useEffect(() => {
        const prevLastImportDate = (previousSyncData.current && previousSyncData.current.lastImportDate) ? previousSyncData.current.lastImportDate : syncDataContext.data.lastImportDate;
        if (syncDataContext.data.lastImportDate > prevLastImportDate) {
            setPopoverOpen(true);
        }

        if (!syncDataContext.data.loadedFromServer) {
            return;
        }

        // check if the query has changed
        if (!previousSyncData.current || (forceReload || JSON.stringify(previousSyncData.current.searchModel) !== JSON.stringify(syncDataContext.data.searchModel))) {
            clearTimeout(loadDataTimeout);

            const tmp = setTimeout(() => {
                fetchCases(syncDataContext.data.searchModel.page, syncDataContext.data.searchModel.sizePerPage, syncDataContext.data.searchModel.sortOrder, syncDataContext.data.searchModel.sortField, syncDataContext.data.searchModel).then(x => {
                    fixQueryString(syncDataContext.data.page, syncDataContext.data.sizePerPage, syncDataContext.data.sortOrder, fixSortField(syncDataContext.data.sortField));
                    setCaseList(x);
                    if (x && x.data && x.data.length > 0 && !syncDataContext.data.caseNumber) {
                        openCaseDetails(x.data[0].uzbTissueNumber, x.data[0].oNumber, 0);
                    }
                });
            }, 500);

            setLoadDataTimeout(tmp);
        }

        previousSyncData.current = syncDataContext.data;
        setForceReload(false);
    }, [syncDataContext.data, forceReload]);

    const setSearchModelParameter = (parameter, value) => {
        syncDataContext.setSyncData({
            ...syncDataContext.data,
            searchModel: {
                ...syncDataContext.data.searchModel,
                [parameter]: value,
                page: 1,
            }
        });
    };

    const fetchStatuses = async () => {
        let url = `api/StatusDescriptions`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', {
                state: { from: encodeURIComponent(location.pathname) }
            });

            return;
        }

        let result = await resp.json();
        setStatuses(result);
    }

    const fetchCustomQueries = async () => {
        let url = `api/CustomQueries/HomePage`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', {
                state: { from: encodeURIComponent(location.pathname) }
            });

            return;
        }

        let result = await resp.json();
        setCustomQueriesList(result);
    }

    const fixQueryString = (page, sizePerPage, sortOrder, sortField) => {
        let sqs = new URLSearchParams();
        sqs.set("page", page);
        sqs.set("sizePerPage", sizePerPage);
        sqs.set("sortOrder", sortOrder);
        sqs.set("sortField", fixSortField(sortField));

        let keys = Object.keys(syncDataContext.data.searchModel);
        for (let i = 0; i < keys.length; i++) {
            let k = keys[i];

            if (syncDataContext.data.searchModel[k] && syncDataContext.data.searchModel[k] !== "undefined") {
                sqs.set(`${k}`, syncDataContext.data.searchModel[k]);
            }
            else if (sqs.get(`${k}`)) {
                sqs.delete(`${k}`);
            }
        }
    }

    const handleTableChange = (type, props) => {
        if (type === "sort") {
            syncDataContext.setSyncData({
                ...syncDataContext.data,
                searchModel: {
                    ...syncDataContext.data.searchModel,
                    sortOrder: props.sortOrder,
                    sortField: fixSortField(props.sortField)
                }
            });
        }
        else if (type === "pagination") {
            syncDataContext.setSyncData({
                ...syncDataContext.data,
                searchModel: {
                    ...syncDataContext.data.searchModel,
                    page: props.page,
                    sizePerPage: props.sizePerPage
                }
            });
        }
    }

    const filterChanged = (e) => {
        var val = e.target.value;
        if (e.target.name === "case") {
            setSearchModelParameter("caseFilter", val);
        }
        if (e.target.name === "orderNumber") {
            setSearchModelParameter("orderNumberFilter", val);
        }
        if (e.target.name === "tissueType") {
            setSearchModelParameter("tissueTypeFilter", val);
        }
        if (e.target.name === "subSpecialism") {
            setSearchModelParameter("subSpecialismFilter", val);
        }
        if (e.target.name === "diagnosticCodes") {
            setSearchModelParameter("diagnosticCodesFilter", val);
        }
        if (e.target.name === "dateFrom") {
            setSearchModelParameter("dateFromFilter", val);
        }
        if (e.target.name === "dateTo") {
            setSearchModelParameter("dateToFilter", val);
        }
    }

    const clearFilters = () => {
        syncDataContext.setSyncData({
            ...syncDataContext.data,
            searchModel: {
                ...syncDataContext.data.searchModel,
                caseFilter: "",
                orderNumberFilter: "",
                tissueTypeFilter: "",
                subSpecialismFilter: "",
                dateFromFilter: null,
                dateToFilter: null,
                diagnosticCodesFilter: ""
            }
        })
    }

    const filterClick = () => {
        setForceReload(true);
    }

    const fetchCases = async (page, pageSize, sortOrder, sortField, filters) => {
        setSearching(true);
        let serverSideSortField = sortField;
        if (sortField) {
            serverSideSortField = fixSortField(sortField);
        }
        else {
            serverSideSortField = "DateReceived";
        }

        let url = `api/cases?page=${page}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortField=${serverSideSortField}`;
        if (filters) {
            let keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                let k = keys[i];
                if (k == "needsRefresh") {
                    continue;
                }

                if (filters[k]) {
                    url += `&${k}=${filters[k]}`;
                }
            }
        }

        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', {
                state: { from: encodeURIComponent(location.pathname) }
            });

            return;
        }

        let result = await resp.json();
        result.cases.forEach(x => {
            x.status = x.stainingCount === x.stainedCount ? 2 : 5;
        });

        setSearching(false);

        return {
            cases: result.cases,
            total: result.total
        };
    }


    const goToPreviousNextCase = (direction) => {
        if (!syncDataContext.data.caseNumber) {
            return;
        }

        let i = 0;
        for (i = 0; i < caseList.cases.length; i++) {
            if (caseList.cases[i].uzbTissueNumber === syncDataContext.data.caseNumber) {
                break;
            }
        }

        if ((i === 0 && direction < 0) || (i >= (caseList.total - 1) && direction > 0)) {
            return;
        }

        const nextIndex = i >= caseList.cases.length - 1 ? 0 : i + direction;
        const nextPage = Math.floor((i + direction + (syncDataContext.data.searchModel.sizePerPage * (syncDataContext.data.searchModel.page - 1))) / syncDataContext.data.searchModel.sizePerPage);

        syncDataContext.setSyncData({
            ...syncDataContext.data,
            caseNumber: caseList.cases[nextIndex].uzbTissueNumber,
            oNumber: "",
            index: nextIndex,
            searchModel: {
                ...syncDataContext.data.searchModel,
                page: (nextPage + 1)
            }
        });
    }

    const openCaseDetails = (uzbTissueNumber, oNumber, rowIndex) => {
        let index = ((syncDataContext.data.searchModel.page - 1) * syncDataContext.data.searchModel.sizePerPage) + rowIndex;
        setCaseForCaseDetailsPage(uzbTissueNumber, oNumber, index);
    }

    const setCaseForCaseDetailsPage = async (caseNumber, oNumber, index) => {
        await syncDataContext.setSyncData({
            ...syncDataContext.data,
            caseNumber: caseNumber,
            oNumber: oNumber,
            index: index
        });
    }

    const queriesSettingsClick = () => {
        setQueryEditModalOpen(true);
    }

    useEffect(() => {
        if (queryEditModalOpen === false) {
            fetchCustomQueries();
        }
    }, [queryEditModalOpen]);

    if (!syncDataContext.data.loadedFromServer) {
        return <div>Loading...</div>;
    }

    return (<>
        <Row className="h-100">
            <div className="col-2 pr-0" style={{ height: "100%", overflow: "auto" }}>
                <img className="d-block w-100" src={logo2} alt="Genesys Pixelator" />
                <Card>
                    <CardBody>
                        <CardTitle key="title" tag="h5" className="d-flex flex-row justify-content-between">
                            <span>Queries</span>
                            <Button color="link" className="pt-0" onClick={queriesSettingsClick}><FontAwesomeIcon icon={faCogs}></FontAwesomeIcon></Button>
                        </CardTitle>
                        <ListGroup flush key="list">
                            {<ListGroupItem key="(None)" className="py-1 pl-0 text-left" tag="button" active={!syncDataContext.data.searchModel.customQueryFilter}
                                onClick={() => { setSearchModelParameter("customQueryFilter", "") }}
                            ><span className="pl-2">None</span>
                            </ListGroupItem>}
                            {customQueriesList &&
                                customQueriesList.sort((a, b) => a.category === b.category ? a.order > b.order : a.category < b.category).map((cq, i, arr) =>
                                    <React.Fragment key={`${i}_${cq.id}`}>
                                        {(i === 0 || arr[i].category !== arr[i - 1].category) &&
                                            <ListGroupItem key={cq.category ? cq.category : "(no category)"} disabled className="py- pl-0">{cq.category ? cq.category : "(No category)"}</ListGroupItem>}
                                        <ListGroupItem key={cq.id} tag="button" className="py-1 pl-0 text-left" active={syncDataContext.data.searchModel.customQueryFilter == cq.id}
                                            onClick={() => { setSearchModelParameter("customQueryFilter", JSON.stringify(cq.id)); }}
                                        ><span className="pl-2">{cq.name}</span></ListGroupItem>
                                    </React.Fragment>
                                )}
                        </ListGroup>
                    </CardBody>
                </Card>
            </div>
            <div className="col-10 h-100">
                <Row className="">
                    <div className="col">
                        <div className="row mb-3">
                            <div className="form-group col">
                                <Label for="order-number" className="mb-0">Case</Label>
                                <InputGroup>
                                    <Input className="form-control" onChange={filterChanged} value={syncDataContext.data.searchModel.caseFilter} type="text" name="case" placeholder="Case"
                                        onKeyPress={e => e.key === "Enter" ? filterClick() : null} />
                                    <div className="input-group-append">
                                        <Button color="primary" onClick={() => { setSearchModelParameter("caseFilter", ""); }}>
                                            <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                        </Button>
                                    </div>
                                </InputGroup>
                            </div>

                            <div className="form-group col">
                                <Label for="order-number" className="mb-0">Order number</Label>
                                <InputGroup>
                                    <Input className="form-control" onChange={filterChanged} value={syncDataContext.data.searchModel.orderNumberFilter} type="text" name="orderNumber" id="order-number" placeholder="Order number" onKeyPress={e => e.key === "Enter" ? filterClick() : null} />
                                    <div className="input-group-append">
                                        <Button color="primary" onClick={() => { setSearchModelParameter("orderNumberFilter", ""); }}>
                                            <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                        </Button>
                                    </div>
                                </InputGroup>
                            </div>

                            <div className="form-group col">
                                <Label for="stain-name" className="mb-0">Case description</Label>
                                <InputGroup>
                                    <Input className="form-control" onChange={filterChanged} value={syncDataContext.data.searchModel.tissueTypeFilter} type="text" name="tissueType" placeholder="Case description" onKeyPress={e => e.key === "Enter" ? filterClick() : null} />
                                    <div className="input-group-append">
                                        <Button color="primary" onClick={() => { setSearchModelParameter("tissueTypeFilter", ""); }}>
                                            <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                        </Button>
                                    </div>
                                </InputGroup>
                            </div>

                            <div className="form-group col">
                                <Label for="institution" className="mb-0">Subspecialism</Label>
                                <InputGroup>
                                    <Input className="form-control" onChange={filterChanged} value={syncDataContext.data.searchModel.subSpecialismFilter} type="text" name="subSpecialism" placeholder="Subspecialism" onKeyPress={e => e.key === "Enter" ? filterClick() : null} />
                                    <div className="input-group-append">
                                        <Button color="primary" onClick={() => { setSearchModelParameter("subSpecialismFilter", ""); }}>
                                            <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                        </Button>
                                    </div>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="form-group col-3">
                                <Label for="date" className="mb-0">From</Label>
                                <Input className="form-control" max={syncDataContext.data.searchModel.dateToFilter} onChange={filterChanged} value={syncDataContext.data.searchModel.dateFromFilter ?? ""} name="dateFrom" type="date" onKeyPress={e => e.key === "Enter" ? filterClick() : null} />
                            </div>
                            <div className="form-group col-3">
                                <Label for="date" className="mb-0">Until</Label>
                                <Input className="form-control" min={syncDataContext.data.searchModel.dateFromFilter} onChange={filterChanged} value={syncDataContext.data.searchModel.dateToFilter ?? ""} name="dateTo" type="date" onKeyPress={e => e.key === "Enter" ? filterClick() : null} />
                            </div>

                            <div className="form-group col-3">
                                <Label for="institution" className="mb-0">Diagnostic Code</Label>
                                <InputGroup>
                                    <Input className="form-control" onChange={filterChanged} value={syncDataContext.data.searchModel.diagnosticCodesFilter} type="text" name="diagnosticCodes" placeholder="Diagnostic Code" onKeyPress={e => e.key === "Enter" ? filterClick() : null} />
                                    <div className="input-group-append">
                                        <Button color="primary" onClick={() => { setSearchModelParameter("diagnosticCodesFilter", ""); }}>
                                            <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                                        </Button>
                                    </div>
                                </InputGroup>
                            </div>

                            <div className="form-group col d-flex align-items-end">
                                <ButtonGroup className="d-block">
                                    {!searching && <Button key="2" color="danger" id="clear" onClick={() => { clearFilters() }}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>&nbsp;Clear</Button>}
                                </ButtonGroup>
                            </div>

                            <div className="form-group col-auto d-flex align-items-end">
                                <ButtonGroup>
                                    <Button key="0" color="info" id="refresh" onClick={() => { setPopoverOpen(false); filterClick(); }}><FontAwesomeIcon icon="fa-solid fa-arrows-rotate" style={{ color: "#ffffff" }} /></Button>
                                    <Popover placement="top" isOpen={popoverOpen} target={'refresh'} toggle={() => { }}>
                                        <PopoverBody>Server completed an import of new data. Click refresh to reload.</PopoverBody>
                                    </Popover>

                                    <PageLinksDropdown className="btn-group"></PageLinksDropdown>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </Row>

                <Row style={{ height: "calc(100% - 160px)" }}>
                    <KeyboardEventHandler
                        handleKeys={['down', 'up']}
                        onKeyEvent={(key, e) => { goToPreviousNextCase(key === "up" ? -1 : 1); }} />
                    <div className="col h-100">
                        <BootstrapTable
                            bootstrap4
                            remote
                            keyField="uzbTissueNumber"
                            data={caseList.cases}
                            columns={columns}
                            defaultSorted={[{ dataField: unfixSortField(syncDataContext.data.searchModel.sortField), order: syncDataContext.data.searchModel.sortOrder }]}
                            sort={{ dataField: unfixSortField(syncDataContext.data.searchModel.sortField), order: syncDataContext.data.searchModel.sortOrder }}
                            pagination={paginationFactory({ sizePerPageList: [20, 50, 100], page: parseInt(syncDataContext.data.searchModel.page), sizePerPage: syncDataContext.data.searchModel.sizePerPage, totalSize: caseList.total, showTotal: true, withFirstAndLast: true })}
                            onTableChange={handleTableChange}
                            classes="cases-table h-100 overflow-auto"
                            rowStyle={rowStyle}
                            rowClasses={rowClasses}
                            headerClasses="table-light"
                            wrapperClasses="overflow-auto h-table"
                        />
                    </div>
                </Row>
            </div>
            {searching ? <div className="ui-block"></div> : <></>}
        </Row>
        <QueryEdit modalOpen={queryEditModalOpen} setModalOpen={setQueryEditModalOpen} ></QueryEdit>
    </>);
}

export { Home };