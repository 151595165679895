import React, { useEffect, useState } from 'react';
import { getAccessTokenAsync } from '../../../actions/AuthActions';
import { FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, Container } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";

export const ReasonsIndex = () => {
    const [state, setState] = useState({
        data: [],
        modalOpen: false,
        error: "",
    });

    const navigate = useNavigate();
    const location = useLocation();
    const [formInitialValues, setInitialValues] = useState({ id: "", reason: "" });

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            sort: true
        },
        {
            dataField: 'reason',
            text: 'Reason',
            sort: true
        },
        {
            isDummyField: true,
            dataField: '',
            text: 'Actions',
            sort: false,
            headerStyle: (column, colIndex) => {
                return { width: '180px', textAlign: 'right' };
            },
            formatter: (cell, row, rowIndex) => {
                return <>
                    <ButtonGroup size="sm" className="ml-auto">
                        <Button color="primary" onClick={() => {
                            onEdit(row.id);
                        }}>Edit</Button>
                        <Button color="danger" disabled={row.id <= 6} onClick={() => {
                            onDelete(row.id);
                        }}>Delete</Button>
                    </ButtonGroup>
                </>
            },
            style: () => {
                return { display: "flex" }
            }
        }];

    const handleTableChange = async (type, props) => {
        let url = `api/DisapprovalReason`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        setState(prvState => { return { ...prvState, data: result } });
    }

    useEffect(() => {
        document.title = "Disapproval Reasons - Verification of automated staining";
        handleTableChange();
    }, []);

    const createNew = () => {
        setInitialValues({ id: "", reason: "" });
        setState(prvState => { return { ...prvState, modalOpen: true } });
    }

    const onEdit = (id) => {
        let cq = state.data.find(d => d.id == id);
        if (cq == null) {
            return;
        }

        setInitialValues({ id: id, reason: cq.reason });
        setState(prvState => { return { ...prvState, modalOpen: true } });
    }

    const onDelete = async (id) => {
        if (id == null || !window.confirm("Are you sure you want to delete this reason?")) {
            return;
        }

        let url = `api/DisapprovalReason/${id}`;
        const resp = await fetch(url,
            {
                method: "DELETE",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" }
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status != 200) {
            setState({ ...state, error: "Error deleting tag" });
        }

        handleTableChange();
    }

    const submitHandler = async (values, setStatus) => {
        let url = `api/DisapprovalReason/`;
        if (values.id) {
            url += `${values.id}`;
        }

        setStatus(null);
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    reason: values.reason,
                })
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status != 200) {
            setStatus(await resp.text());
            return;
        }

        let res = await resp.json();

        setState(prvState => { return { ...prvState, modalOpen: false } });
        handleTableChange();
    }

    return (<Container>
        <div className="col">
            <h4 className="pull-left">Disapproval Reasons</h4>
            <Button className="pull-right mb-3" type="primary" onClick={() => createNew()}><FontAwesomeIcon icon={faPlus} /> Create</Button>
            <BootstrapTable
                bootstrap4
                remote
                keyField="id"
                data={state.data}
                columns={columns}
                bordered={false}
                condensed={true}
                // sort={{ dataField: state.sortField, order: state.sortOrder }}
                // pagination={paginationFactory({ page: state.page, sizePerPage: state.sizePerPage, totalSize: state.totalSize, showTotal: true, withFirstAndLast: true })}
                onTableChange={handleTableChange}
                classes="customqueries-table"
            />
        </div>
        {state.modalOpen &&
            <Modal isOpen={state.modalOpen} size="xl">
                <ModalHeader>
                    {!formInitialValues.id && <>Create a new reason</>}
                    {formInitialValues.id && <>Edit reason</>}
                </ModalHeader>
                <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    validate={values => {
                        const errors = {};
                        if (!values.reason) {
                            errors.reason = 'Required';
                        }
                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting, setStatus }) => {
                        submitHandler(values, setStatus);
                        setSubmitting(false);
                    }}>
                    {({ isSubmitting, submitForm, status }) => (
                        <><ModalBody>
                            <Form>
                                <Field type="hidden" name="id" />
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <label>Reason</label>
                                    <Field type="text" name="reason" className="form-control" />
                                    <ErrorMessage name="reason" component="div" className="text-danger" />
                                </FormGroup>
                                {status && <div className="text-danger">{status}</div>}
                            </Form>
                        </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={() => { submitForm(); }}>Save</Button>{' '}
                                <Button type="button" color="secondary" onClick={() => setState({ ...state, modalOpen: false })}>Cancel</Button>
                            </ModalFooter>
                        </>
                    )}
                </Formik>
            </Modal>}
    </Container>);
}