import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AppContextProvider } from './State/AppContext';
import { SyncDataContextProvider } from './components/pulz/state/useSyncData'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter basename={baseUrl}>
        <AppContextProvider>
            <SyncDataContextProvider>
                <App />
            </SyncDataContextProvider>
        </AppContextProvider>
    </BrowserRouter>);
