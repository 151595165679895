import React, { useContext, useEffect, useState } from 'react';
import { Container, Navbar, NavbarBrand, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '../State/AppContext';
import { getAccessTokenAsync } from '../actions/AuthActions';

const Home = () => {
    const context = useContext(AppContext);
    const [ready, setReady] = useState(false);
    const [content, setContent] = useState(["", "", "", ""]);

    const fetchContext = async () => {
        let url = `api/content`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 200) {
            const contents = await resp.json();

            for (let i = 0; i < contents.length; i++) {
                contents[i].text = contents[i].text.split('\n').join('<br>');
            }
            setContent(contents);
        }

        setReady(true);
    }


    useEffect(() => {
        document.title = `Pathology Lab Workspace`
        fetchContext();
    }, []);

    return !ready ? <div></div> : (
        <>
            <header>
                <Navbar className="navbar-expand-sm  ng-white border-bottom box-shadow mb-3" light>
                    <Container className="d-sm-inline-flex flex-sm-row" style={{ maxWidth: "100%" }}>
                        <NavbarBrand style={{ flexGrow: 1, marginLeft: "auto", marginRight: "auto" }} tag={Link} to="/">Pathology lab workspace</NavbarBrand>
                        {/* <NavbarToggler onClick={() => { setNavBarOpen(!navBarOpen) }} className="mr-2" /> */}
                        {/* <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={navBarOpen} navbar>
                            <ul className="navbar-nav flex-grow"> */}
                        {/* <NavItem> */}

                        <NavLink tag={Link} className="text-dark" style={{ marginTop: 9.5 }} to="/signout">
                            <FontAwesomeIcon icon={faUser} />&nbsp;
                            <span className="pl-1">{context?.state?.user ?? "Signout"}</span>
                        </NavLink>

                        {/* </NavItem> */}
                        {/* </ul>
                        </Collapse> */}
                    </Container>
                </Navbar>
            </header>

            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="card border-warning text-center">
                            <h5 className="card-header">{content[0].title}</h5>
                            <div className="card-body">
                                <h5 className="card-title">{content[0].subTitle}</h5>
                                <Link to="/vas/home" className="btn btn-warning">Enter</Link>
                                <p className="card-text" dangerouslySetInnerHTML={{ __html: content[0].text }}></p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-info text-center">
                            <h5 className="card-header">{content[1].title}</h5>
                            <div className="card-body">
                                <h5 className="card-title">{content[1].subTitle}</h5>
                                <Link to="/pims/home" className="btn btn-info">Enter</Link>
                                <p className="card-text" dangerouslySetInnerHTML={{ __html: content[1].text }}></p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-success text-center">
                            <h5 className="card-header">{content[2].title}</h5>
                            <div className="card-body">
                                <h5 className="card-title">{content[2].subTitle}</h5>
                                <Link to="/pulz/home" className="btn btn-success">Enter</Link>
                                <p className="card-text" dangerouslySetInnerHTML={{ __html: content[2].text }}></p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-secondary text-center">
                            <h5 className="card-header">{content[3].title}</h5>
                            <div className="card-body">
                                <h5 className="card-title">{content[3].subTitle}</h5>
                                <Link to="/pims/viewer" className="btn btn-secondary">Enter</Link>
                                <p className="card-text" dangerouslySetInnerHTML={{ __html: content[3].text }}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}

export { Home };
