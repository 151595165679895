import React, { useEffect, useState } from 'react';
import { getAccessTokenAsync } from '../actions/AuthActions';
import { FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Container } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";

export const HomeConf = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        data: [],
        modalOpen: false,
        error: ""
    });

    const [popupFormValues, setPopupFormValues] = useState({ id: "", title: "", subTitle: "", text: "" });

    const columns = [
        {
            dataField: 'title',
            text: 'Title'
        },
        {
            isDummyField: true,
            dataField: '',
            text: 'Actions',
            sort: false,
            headerStyle: (column, colIndex) => {
                return { width: '180px', textAlign: 'right' };
            },
            formatter: (cell, row, rowIndex) => {
                return <Button color="primary" onClick={() => {
                    onEdit(row.id);
                }}>Edit</Button>
            },
            style: () => {
                return { display: "flex" }
            }
        }];

    const handleTableChange = async (type, props) => {
        let url = `api/Content`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        if (props) {
            result = result.sort((a, b) => {
                if (a[props.sortField] > b[props.sortField]) {
                    return props.sortOrder === 'asc' ? 1 : -1;
                } else if (b[props.sortField] > a[props.sortField]) {
                    return props.sortOrder === 'asc' ? -1 : 1;
                }
                return 0;
            });
        }

        setState(prvState => { return { ...prvState, data: result } });
    }

    useEffect(() => {
        document.title = "Home page configuration";
        handleTableChange();
    }, []);

    const onEdit = (id) => {
        let x = state.data.find(d => d.id == id);
        if (x == null) {
            return;
        }

        setPopupFormValues({ id: id, title: x.title, subTitle: x.subTitle, text: x.text });
        setState(prvState => { return { ...prvState, modalOpen: true } });
    }

    const tryParseInt = (v) => {
        try {
            return parseInt(v);
        }
        catch (Exception) {
            return 0;
        }
    }

    const submitHandler = async (values, setStatus) => {
        let url = `api/Content`;

        setStatus(null);

        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    id: values.id,
                    title: values.title,
                    subTitle: values.subTitle,
                    text: values.text
                })
            });

        if (resp.status == 401) {
            navigate.push({
                pathname: '/signin',
                state: { from: encodeURIComponent(location.pathname) }
            });

            return;
        }
        if (resp.status != 200) {
            setStatus(await resp.text());
            return;
        }

        setState(prvState => { return { ...prvState, modalOpen: false } });
        handleTableChange();
    }

    return (<Container>
        <div className="col">
            <h4 className="pull-left"><small><Link to="/pulz/admin">[<FontAwesomeIcon icon={faArrowLeft} /> back]</Link></small> Home page content</h4>
            <BootstrapTable
                bootstrap4
                remote
                keyField="id"
                data={state.data}
                columns={columns}
                bordered={false}
                condensed={true}
                sort={{ dataField: state.sortField, order: state.sortOrder }}
                onTableChange={handleTableChange}
            // classes="customqueries-table"
            />
        </div>
        {state.modalOpen &&
            <Modal isOpen={state.modalOpen} size="xl">
                <ModalHeader>Edit content</ModalHeader>
                <Formik
                    initialValues={popupFormValues}
                    enableReinitialize={true}
                    validate={values => {
                        const errors = {};
                        if (!values.title) {
                            errors.title = 'Required';
                        }

                        if (!values.subTitle) {
                            errors.subTitle = 'Required';
                        }

                        if (!values.text) {
                            errors.text = 'Required';
                        }

                        return errors;
                    }}

                    onSubmit={(values, { setSubmitting, setStatus }) => {
                        submitHandler(values, setStatus);
                        setSubmitting(false);
                    }}>
                    {({ isSubmitting, submitForm, status }) => (
                        <><ModalBody>
                            <Form>
                                <Field type="hidden" name="id" />
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label>Title</Label>
                                    <Field type="text" name="title" className="form-control" />
                                    <ErrorMessage name="title" component="div" className="text-danger" />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label>Sub title</Label>
                                    <Field type="text" name="subTitle" className="form-control" />
                                    <ErrorMessage name="subTitle" component="div" className="text-danger" />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label>Text</Label>
                                    <Field as="textarea" name="text" className="form-control" rows={3} />
                                    <ErrorMessage name="text" component="div" className="text-danger" />
                                </FormGroup>
                                {status && <div className="text-danger">{status}</div>}
                            </Form>
                        </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={() => { submitForm(); }}>Save</Button>{' '}
                                <Button type="button" color="secondary" onClick={() => setState({ ...state, modalOpen: false })}>Cancel</Button>
                            </ModalFooter>
                        </>
                    )}
                </Formik>
            </Modal>}
    </Container>);
}
