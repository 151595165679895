import moment from 'moment';
export const REGISTER_USER_SUCCESS = 'register_user_success';
export const REGISTER_USER_FAIL = 'register_user_fail';
export const REGISTER_USER_START = 'register_user_start';

export const LOGIN_USER_START = 'login_user_start';
export const LOGIN_USER_SUCCESS = 'login_user_success';
export const LOGIN_USER_FAIL = 'login_user_fail';
export const FETCH_TOKEN = 'fetch_token';

export const LOGOUT_USER = 'logout_user';


export const getOBRValues = (parsedMessage) => {
    let returnValue = { slideId: null, orderNumber: null, specimenReceivedDateTime: null };
    let obrFields = parsedMessage.filter(t => t.Name === "OBR")[0].Fields;
    let obr20Values = obrFields.filter(t => t.Name === "OBR.20");
    if (obr20Values && obr20Values.length > 0) {
        returnValue.slideId = obr20Values[0].Value.split("^")[0].replace("/", "");
    }

    let obr2Values = obrFields.filter(t => t.Name === "OBR.2");
    if (obr2Values && obr2Values.length > 0) {
        returnValue.orderNumber = obr2Values[0].Value;
    }

    let obr13Values = obrFields.filter(t => t.Name === "OBR.13");
    if (obr13Values && obr13Values.length > 0) {
        returnValue.specimenReceivedDateTime = obr13Values[0].Value;
    }

    return returnValue;
}

export const getOBXValues = (parsedMessage) => {
    let returnValue = { requester: null, stainingRunCompletedTime: null };
    let obxObjects = parsedMessage.filter(t => t.Name === "OBX");
    obxObjects.forEach(el => {
        if (el.Fields[3].Value == "Requester") {
            returnValue.requester = el.Fields[4].Value.replace("^", " ");
        }
        if (el.Fields[3].Value == "StainingRunCompletedTime") {
            returnValue.stainingRunCompletedTime = el.Fields[4].Value;
        }
    });

    return returnValue;
}

export const getTAT = (startTime, endTime) => {
    var startDate = moment(startTime.substr(0, 4) + '-' + startTime.substr(4, 2) + '-' + startTime.substr(6, 2) + ' ' + startTime.substr(8, 2) + ':' + startTime.substr(10, 2) + ':' + startTime.substr(12, 2));
    var endDate = moment(endTime.substr(0, 4) + '-' + endTime.substr(4, 2) + '-' + endTime.substr(6, 2) + ' ' + endTime.substr(8, 2) + ':' + endTime.substr(10, 2) + ':' + endTime.substr(12, 2));
    var dur = moment.duration(endDate.diff(startDate));
    var days = Math.floor(dur.asDays());
    var hours = Math.floor(dur.asHours()) - days * 24;
    var mins = Math.floor(dur.asMinutes()) - days * 24 * 60 - hours * 60;
    var sec = Math.floor(dur.asSeconds()) - days * 24 * 60 * 60 - hours * 60 * 60 - mins * 60;
    var result = days + " Days | " + ((hours > 9) ? hours : ("0" + hours)) + " Hours | " + ((mins > 9) ? mins : ("0" + mins)) + " Mins | " + ((sec > 9) ? sec : ("0" + sec)) + " Secs";
    return result;
}
