import React, { Component, useContext, useEffect } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { getAccessTokenAsync } from '../../../actions/AuthActions';
import { AppContext } from '../../../State/AppContext';
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";

const Layout = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const context = useContext(AppContext);
    const checkIfLoggedIn = async () => {
        if (!context || !context?.state?.isLogin) {
            return;
        }

        const resp = await fetch(`api/Ping`,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status == 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }
    }

    useEffect(() => {
        const timer = setInterval(() => {
            checkIfLoggedIn();
        }, 5000);
        return () => clearInterval(timer);

        // document.title = "Case workflow module";
    }, []);

    return <>
        <NavMenu />
        <Container fluid>
            {props.children}
        </Container>
    </>;
}

export { Layout };
