import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
// @ts-ignore
import { Context as UIContext, SessionLogin, SlideLoader, Gallery, ComponentEvents, ButtonLocations, Annotations } from "@pathomation/pma.ui/src/index"
import {
    Input, Nav, NavLink, NavItem, TabPane, TabContent, Col, Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, Button, UncontrolledDropdown,
    DropdownToggle, DropdownMenu, DropdownItem, Alert, Label, Badge
} from 'reactstrap';
import { getAccessTokenAsync } from '../../../actions/AuthActions';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import KeyboardEventHandler from 'react-keyboard-event-handler';
import Clipboard from 'react-clipboard.js';
import { PageLinksDropdown } from '../ui/PageLinksDropdown';
import useSyncDataContext from "../state/useSyncData";
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";

const isNumber = (char) => {
    return /^\d$/.test(char);
}

const slidePathTransform = (path) => {
    let fn = path.split('/').pop();
    fn = fn.substring(0, fn.lastIndexOf("."));
    let parts = fn.split("-");
    if (parts.length < 4) {
        return "";
    }

    let recipient = parts[1];
    let block = parts[2];
    let slideId = parts.slice(3).join("-");
    if (slideId.length < 2 || !isNumber(slideId[0]) || !isNumber(slideId[1])) {
        slideId = "0" + slideId;
    }

    const str = `${recipient}-${block.padStart(2, '0')}-${slideId}`;
    return str.replace(/_\d{14}/, "");
}

// const slidePathToSortObj = (path) => {
//     let fn = path.split('/').pop();
//     fn = fn.split(".")?.[0];
//     if (!fn) {
//         return [0, 0, 0];
//     }

//     let parts = fn.split("-");
//     if (parts.length < 4) {
//         return [fn, 0, 0];
//     }

//     return [parts[1], parts[2], parts[3]];
// }

const slideLoaderOptions = {
    overview: { collapsed: false },
    channels: { collapsed: false },
    scaleLine: true,
    annotations: { visible: true, labels: true, showMeasurements: true },
    digitalZoomLevels: 2,
    loadingBar: true,
    highQuality: true,
    barcode: { collapsed: true },
    theme: "modern",
    rotationControl: { collapsed: true },
    filename: function (filenameObj) {
        return slidePathTransform(filenameObj.path);
    }
    // customButtons: [
    //     {
    //         title: "close viewer",
    //         content: "Close \u2716",
    //         class: "text-danger",
    //     }
    // ]
}

const initState = {
    id: null,
    loaded: false,
    link: null,
    stainingCount: 0,
    stainedCount: 0,
    tissueType: "",
    subspecialism: "",
    dateReceived: null,
    mountedCount: 0,
    scannedCount: 0,
    validatedCount: 0,
    totalCount: 0,
    orderCount: 0,
    diagnosticCodes: 0,
    comments: ""
};

const loadedState = { ...initState, loaded: true };

export const CawSlides = () => {
    const [state, setState] = useState(initState);
    const [serverCreds, setServerCreds] = useState({});
    const [selectedSlides, setSelectedSlides] = useState([]);
    const [tempSelectedSlides, setTempSelectedSlides] = useState([]);
    const [allSlides, setAllSlides] = useState([]);
    const [collageMode, setCollageMode] = useState(false);
    const [gallerySlideEvent, setGallerySlideEvent] = useState({ action: "", slide: "" });
    const [slideLoaders, setSlideLoaders] = useState({});
    const syncDataContext = useSyncDataContext();
    const location = useLocation();
    const navigate = useNavigate();
    const galleryRef = useRef(null);
    const context = useRef(new UIContext({ caller: "Laba" }));
    const mainGallery = useRef(null);
    const gotoCaseRef = React.createRef();
    const caseCommentsRef = React.createRef();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [actions, setActions] = useState([]);
    const [actionRequestResult, setActionRequestResult] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [useCaseNumberFolder, setUseCaseNumberFolder] = useState(null);
    const [loading, setLoading] = useState(true);

    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => { if (activeTab !== tab) setActiveTab(tab); }
    const commentsTimeout = useRef(null);

    const fetchActions = async () => {
        let url = `api/Actions`;
        const resp = await fetch(url,
            {
                headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        setActions(result.filter(x => x.active));
    }

    const fetchCase = async (cid, index) => {
        let url = `api/cases/details?cid=${encodeURIComponent(cid)}`;
        if (index != null) {
            url = `api/cases/details?cid=${encodeURIComponent(cid)}&indexInOrder=${index}`;
        }

        const res = await fetch(url, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });

        if (res.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (res.status === 404) {
            setState(loadedState);
            setLoading(false);
            return;
        }
        setLoading(true);

        return await res.json();
    }

    const setCaseCommentsFromTextArea = (cid, comments) => {
        if (commentsTimeout.current) {
            clearTimeout(commentsTimeout.current);
        }

        commentsTimeout.current = setTimeout(setCaseComments.bind(this, cid, comments), 1500);
    }

    const setCaseComments = async (cid, comments) => {
        let url = 'api/cases/CaseComments';
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    id: cid,
                    comments: comments,
                }),
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status !== 200) {
            setActionRequestResult({ message: `Failed to set comment for case ${await resp.text()}`, error: true });
            // initPage();
            return;
        }

        setActionRequestResult({ message: `Comment was set successfully!`, error: false });
        setState({ ...state, comments: comments });
    }

    const setSlideComments = async (slideId, comments) => {
        let url = 'api/cases/SlideComments';
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    id: slideId,
                    comments: comments,
                }),
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status !== 200) {
            setActionRequestResult({ message: `Failed to set comment for slide ${await resp.text()}`, error: true });
            // initPage();
            return;
        }

        setActionRequestResult({ message: `Comment was set successfully!`, error: false });
    }

    const loadCase = (caseNumber, index, path) => {
        var lnk = "/slides.html?sb=true&path=" + getFolder(path);
        fetchCase(caseNumber, index).then((x) => {
            if (!x) {
                return;
            }

            let originalCaseNumber = x.caseNumber.substring(3);
            while (originalCaseNumber.startsWith("0")) {
                originalCaseNumber = originalCaseNumber.substring(1);
            }

            navigate(`/pulz/slides/${x.caseNumber}`, { replace: true, state: { index: syncDataContext.data.index } });
            originalCaseNumber = x.caseNumber.substring(0, 3) + originalCaseNumber;

            setState({
                loaded: true,
                id: x.oNumber,
                link: lnk,
                caseNumber: x.caseNumber,
                originalCaseNumber: originalCaseNumber,
                stainingCount: x.stainingCount,
                stainedCount: x.stainedCount,
                tissueType: x.tissueType,
                subspecialism: x.subspecialism,
                dateReceived: x.dateReceived,
                mountedCount: x.mountedCount,
                scannedCount: x.scannedCount,
                diagnosticCodes: x.diagnosticCodes,
                validatedCount: x.validatedCount,
                totalCount: x.totalCount,
                orderCount: x.orderCount,
                previousCase: x.previousCase,
                nextCase: x.nextCase,
                slides: x.slides,
                slidesWithoutTasks: x.slidesWithoutTasks,
                comments: x.comments,
                originalId: x.id
            });
        });
    };

    useEffect(() => {
        if (!syncDataContext.data.caseNumber) {
            return;
        }

        syncDataContext.setSyncData({
            ...syncDataContext.data,
            selectedSlidePaths: selectedSlides,
            selectedSlideNames: selectedSlides.map(x => slidePathTransform(x))
        });
    }, [selectedSlides]);

    useEffect(() => {

        /* Check for not returning bad request when page reloads*/
        if (!syncDataContext.data.caseNumber) {
            return;
        }

        for (let i = 0; i < serverCreds.path.length; i++) {
            loadCase(syncDataContext.data.caseNumber, syncDataContext.data.index, serverCreds.path[i]);
        }
    }, [syncDataContext.data.caseNumber]);

    const fetchServerSettings = async () => {
        const res = await fetch(`api/Settings`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (res.status === 404) {
            return;
        }

        var screds = await res.json();

        const res1 = await fetch(`api/PmaCoreSessionId`, { headers: { "Authorization": "Bearer " + getAccessTokenAsync() } });
        if (res1.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (res1.status === 404) {
            return;
        }
        var sessionIdResult = await res1.json();

        setServerCreds({ username: screds.username, serverUrl: screds.serverUrl, path: screds.path, sessionId: sessionIdResult.sessionId });
    }

    useEffect(() => {
        fetchActions();
        fetchServerSettings();
    }, []);

    useEffect(() => {
        if (caseCommentsRef && caseCommentsRef.current) {
            caseCommentsRef.current.value = state.comments;
        }
    }, [state]);

    useEffect(() => {
        if (gallerySlideEvent.action === "selected") {
            if (collageMode) {
                mainGallery.current.selectSlide(null);
                return;
            }

            let slide = gallerySlideEvent.slide;
            const slides = ss => [...ss, slide.path];
            setSelectedSlides(slides);
            setTempSelectedSlides(slides);
        }
        else if (gallerySlideEvent.action === "deselected") {
            let slide = gallerySlideEvent.slide;
            if (!slide || collageMode) {
                return;
            }

            const slides = ss => ss.filter(s => s !== slide.path);
            setSelectedSlides(slides);
            setTempSelectedSlides(slides);
        }

    }, [gallerySlideEvent]);

    useLayoutEffect(() => {
        if (!state.loaded || !state.id) {
            setAllSlides([]);
            if (mainGallery.current) {
                mainGallery.current.loadSlides([]);
            }

            return;
        }

        var urlKey = getUrlKey(state.id);
        if (!serverCreds || !serverCreds.serverUrl) {
            return;
        }

        new SessionLogin(context.current, [{ serverUrl: serverCreds.serverUrl, sessionId: serverCreds.sessionId }]);
        mainGallery.current = new Gallery(context.current,
            {
                element: "#mainGallery",
                mode: "grid",
                thumbnailWidth: 120,
                thumbnailHeight: 120,
                showFileName: true,
                showBarcode: true,
                multiSelect: true,
                filenameCallback: function (serverUrl, path) {
                    return slidePathTransform(path);
                },
                additionalHtmlCallback: function (filenameObj) {
                    const rx = /\d{8}/;
                    const match = filenameObj.path.match(rx);
                    if (match && match.length > 0) {
                        const str = `${match[0].substr(0, 4)}-${match[0].substr(4, 2)}-${match[0].substr(6, 2)}`;
                        return `<small style="float: right; color: grey">${str}</small>`;
                    }

                    return "";
                }
            });

        // if (collageMode) {
        //     mainGallery.current.setRenderOptions(GalleryRenderOptions.Barcode);
        // }
        // else {
        //     mainGallery.current.setRenderOptions(GalleryRenderOptions.All);
        // }

        mainGallery.current.listen(ComponentEvents.SlideSelected, (slide) => {
            // can't access state in this callback so trigger a state change and handle everything 
            setGallerySlideEvent({ action: "selected", slide: slide });
        });
        mainGallery.current.listen(ComponentEvents.SlideDeSelected, (slide) => {
            setGallerySlideEvent({ action: "deselected", slide: slide });
        });
        mainGallery.current.listen(ComponentEvents.SlideInfoError, (slide) => {
            setAllSlides([]);
        });

        if (!context.current) {
            return;
        }

        if (!state.caseNumber) {
            setUseCaseNumberFolder(false);
            return;
        }

        setUseCaseNumberFolder(null);
        new Promise((resolve, reject) => {
            for (let i = 0; i < serverCreds.path.length; i++) {
                context.current.getDirectories(serverCreds.serverUrl, `${serverCreds.path[i]}${getUrlKey(state.caseNumber)}`, function (sessionId, result) {
                    resolve(true);
                }, function () {
                    reject();
                })
            };
        }).then((r) => { setUseCaseNumberFolder(true); }, () => { setUseCaseNumberFolder(false); })
            .catch(() => { setUseCaseNumberFolder(false); });

        document.title = `Slides viewer ${syncDataContext.data.caseNumber} - Case workflow module`;
    }, [state.loaded, state.id]);

    const loadSlidesToGallery = (slides) => {
        let sld = slides.sort((a, b) => {
            const aa = slidePathTransform(a.path);
            const bb = slidePathTransform(b.path);
            return aa > bb ? 1 : -1;
        });

        mainGallery.current.loadSlides(sld.map(s => { return { serverUrl: s.server, path: s.path }; }));

        setAllSlides(sld);
    }

    const caseActionClick = async (action) => {
        if (!window.confirm(`Are you sure you want to perform action ${action.name} on this case?`)) {
            return;
        }

        let url = `api/Actions/Perform`;
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    Id: action.id,
                    CaseId: state.caseNumber,
                })
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        let result = await resp.json();
        if (resp.status === 200) {
            setActionRequestResult({ message: `Action completed successfully, affected ${result} rows`, error: false });
            // initPage();
            return;
        }

        setActionRequestResult({ message: `${result}`, error: resp.status !== 200 });
    }

    const findSlidesByPath = (slidePaths) => {
        let foundSlides = [];
        for (let i = 0; i < slidePaths.length; i++) {
            let transformed = slidePathTransform(slidePaths[i]).split("-");
            let foundSlide = state.slidesWithoutTasks.find(swt => swt.slideId === transformed[2] && swt.recipient === transformed[0] && swt.block === transformed[1])
            if (foundSlide) {
                foundSlides.push(foundSlide);
                continue;
            }

            // also try the slides array
            foundSlide = state.slides.find(swt => swt.slideId === transformed[2] && swt.recipient == transformed[0] && swt.block === transformed[1])
            if (foundSlide) {
                foundSlide.path = slidePaths[i];
                foundSlides.push(foundSlide);
                continue;
            }
        }

        return foundSlides;
    }

    const slideActionClick = async (action) => {
        if (selectedSlides.length === 0 || (selectedSlides.length === 1 && selectedSlides[0].startsWith("{"))) {
            return;
        }

        if (!window.confirm(`Are you sure you want to perform action ${action.name} on ${selectedSlides.length} slides?`)) {
            return;
        }

        let foundSlides = findSlidesByPath(selectedSlides);
        if (foundSlides.length === 0) {
            return;
        }

        let url = `api/Actions/Perform`;
        const resp = await fetch(url,
            {
                method: "POST",
                headers: { "Authorization": "Bearer " + getAccessTokenAsync(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    Id: action.id,
                    SlideIds: foundSlides.map(s => `${s.id}`),
                })
            });

        if (resp.status === 401) {
            navigate('/signin', { state: { from: encodeURIComponent(location.pathname) } });
            return;
        }

        if (resp.status === 200) {
            let result = await resp.json();
            setActionRequestResult({ message: `Action completed successfully, affected ${result} rows`, error: false });
            // initPage();
            return;
        }

        setActionRequestResult({ message: `${await resp.text()}`, error: resp.status !== 200 });
    }

    useEffect(() => {
        if (actionRequestResult != null) {
            setTimeout(() => {
                if (actionRequestResult.error === false) {
                    setIsModalOpen(false);
                }
            }, 2000);

            setTimeout(() => {
                setIsModalOpen(false);
                setActionRequestResult(null);
            }, 10000);
        }
    }, [actionRequestResult]);

    useEffect(() => {
        if (!mainGallery.current || useCaseNumberFolder == null) {
            return;
        }

        let prom = [];
        for (let i = 0; i < serverCreds.path.length; i++) {
            prom.push(new Promise((resolve, reject) => {
                context.current.getDirectories(serverCreds.serverUrl, getFolder(serverCreds.path[i]) + "/WSI", function (sessionId, result) {
                    getAllSlides(getFolder(serverCreds.path[i]) + "/WSI")
                        .then(sls => resolve(sls));
                }, function () {
                    getAllSlides(getFolder(serverCreds.path[i]))
                        .then(sls => resolve(sls));
                });
            }));
        };

        Promise.all(prom).then((sls) => {
            loadSlidesToGallery([...sls.flat()].map(x => { return { server: serverCreds.serverUrl, path: x }; }));
        });
    }, [useCaseNumberFolder])

    const getUrlKey = (value) => {
        return encodeURIComponent(value.substring(0, 3)) + "/" + encodeURIComponent(value.substring(0, 5)) + "/" + encodeURIComponent(value.substring(0, 7)) + "/" + encodeURIComponent(value.substring(0, 9));
    }

    const getAllSlides = async (path) => {
        let slides = await getSlides(path);
        return slides;
    }

    const getSlides = path => {
        return new Promise((resolve, reject) => {
            context.current.getSlides({
                serverUrl: serverCreds.serverUrl,
                path: path,
                success: (sessionId, files) => { resolve(files) },
                failure: () => { resolve([]) }
            });
        });
    }

    const getFolder = (path) => {
        if (!state.id) {
            return;
        }

        if (useCaseNumberFolder) {
            if (!state.caseNumber) {
                return;
            }

            return `${path}${getUrlKey(state.caseNumber)}`;
        }

        return `${path}${getUrlKey(state.id)}`;
    }

    const makeid = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const setCollageModeAndSelectedSlides = () => {
        if (mainGallery.current) {
            // if (collageMode) {
            //     mainGallery.current.setRenderOptions(GalleryRenderOptions.Barcode);
            // }
            // else {
            //     mainGallery.current.setRenderOptions(GalleryRenderOptions.All);
            // }
        }

        if (collageMode && state.id) {
            let collection = {
                "Name": getFolder() + "_" + makeid(20),
                "CollectionRows": 1, //Math.ceil(Math.sqrt(allSlides.length)),
                "CollectionTileMargin": 1024,
                "Sources": allSlides.map(s => s.path),
                "CollectionLayout": 0
            };

            mainGallery.current.selectSlide(null);
            if (allSlides.length === 0) {
                setSelectedSlides([]);
                setTempSelectedSlides([]);
            }
            else {
                const slides = [JSON.stringify(collection)];
                setSelectedSlides(slides);
                setTempSelectedSlides(slides);
            }
        }
        else {
            setSelectedSlides([]);
            setTempSelectedSlides([]);
        }
    }

    useEffect(() => {
        setCollageModeAndSelectedSlides();
    }, [collageMode, allSlides]);

    const closeViewerButton = (slide) => {
        if (!mainGallery.current) {
            return;
        }

        let index = allSlides.findIndex(as => as.path === slide);
        if (index === -1) {
            return;
        }

        mainGallery.current.selectSlide(index);
    }

    const selectSlide = (sPath, isFullscreen) => {
        let index = allSlides.findIndex(as => as.path === sPath);
        if (index === -1) {
            return;
        }

        for (let i = 0; i < allSlides.length; i++) {
            mainGallery.current.highlightSlide(i, false);
        }

        mainGallery.current.highlightSlide(index, true);
        setSelectedSlides([allSlides[index].path]);
        if (!isFullscreen || tempSelectedSlides.length < 2) {
            setTempSelectedSlides([allSlides[index].path]);
        }
    }

    const selectNext = (sPath, isFullscreen) => {
        if (tempSelectedSlides.length > 1) {
            let index = tempSelectedSlides.findIndex(s => s === sPath);
            if (index === -1) {
                return;
            }

            index = index === tempSelectedSlides.length - 1 ? 0 : index + 1;
            selectSlide(tempSelectedSlides[index], isFullscreen);
        }
        else {
            let index = allSlides.findIndex(s => s.path === sPath);
            if (index === -1) {
                return;
            }

            index = index === allSlides.length - 1 ? 0 : index + 1;
            selectSlide(allSlides[index].path, isFullscreen);
        }
    }

    const selectPrevious = (sPath, isFullscreen) => {
        if (tempSelectedSlides.length > 1) {
            let index = tempSelectedSlides.findIndex(s => s === sPath);
            if (index === -1) {
                return;
            }

            index = index === 0 ? tempSelectedSlides.length - 1 : index - 1;
            selectSlide(tempSelectedSlides[index], isFullscreen);
        }
        else {
            let index = allSlides.findIndex(s => s.path === sPath);
            if (index === -1) {
                return;
            }

            index = index === 0 ? allSlides.length - 1 : index - 1;
            selectSlide(allSlides[index].path, isFullscreen);
        }
    }

    const hasPreviousSlide = (idx) => {
        let index = -1;
        if (tempSelectedSlides.length > 1) {
            index = tempSelectedSlides.findIndex(s => s === selectedSlides[idx]);
        }
        else {
            index = allSlides.findIndex(as => as.path === selectedSlides[idx]);
        }

        return index > 0;
    }

    const hasNextSlide = (idx) => {
        let index = -1;
        let length = 0;
        if (tempSelectedSlides.length > 1) {
            length = tempSelectedSlides.length;
            index = tempSelectedSlides.findIndex(s => s === selectedSlides[idx]);
        }
        else {
            length = allSlides.length;
            index = allSlides.findIndex(as => as.path === selectedSlides[idx]);
        }

        return index >= 0 && index < length - 1;
    }

    useEffect(() => {
        if (!document.fullscreenElement && tempSelectedSlides.length >= 1) {
            for (let i = 0; i < allSlides.length; i++) {
                mainGallery.current.highlightSlide(i, false);
            }

            for (let i = 0; i < tempSelectedSlides.length; i++) {
                const index = allSlides.findIndex(x => x.path === tempSelectedSlides[i]);
                mainGallery.current.highlightSlide(index, true);
            }

            setSelectedSlides([].concat(tempSelectedSlides));
        }

    }, [document.fullscreenElement])

    const gotoCase = (evt) => {
        if (!gotoCaseRef.current) {
            return;
        }

        var caseId = gotoCaseRef.current.value;
        if (!caseId) {
            return;
        }

        caseId = caseId.trim();
        if (caseId.length < 8) {
            caseId = caseId.substring(0, 3) + caseId.substring(3).padStart(5, '0');
        }

        if (gotoCaseRef.current && gotoCaseRef.current.value) {
            gotoCaseRef.current.value = "";
        }

        if (caseId === syncDataContext.data.caseNumber) {
            return;
        }

        setState({ ...state, loaded: false });
        setCaseForSlideViewer(caseId, null, -1, false);
        setSelectedSlides([]);
        setTempSelectedSlides([]);
        setAllSlides([]);
    }

    const clickNext = () => {
        setState({ ...state, loaded: false });
        setCaseForSlideViewer(state.nextCase, null, Math.max(0, syncDataContext.data.index + 1), true);
    }

    const clickPrevious = () => {
        setState({ ...state, loaded: false });
        setCaseForSlideViewer(state.previousCase, null, Math.max(0, syncDataContext.data.index - 1), true);
    }

    const setCaseForSlideViewer = async (caseNumber, oNumber, index, isPrevNext) => {
        if (isPrevNext === false) {
            await syncDataContext.setSyncData({
                ...syncDataContext.data,
                caseNumber: caseNumber,
                oNumber: oNumber,
                index: 0,
                searchModel: {
                    ...syncDataContext.data.searchModel,
                    caseFilter: caseNumber,
                    orderNumberFilter: "",
                    tissueTypeFilter: "",
                    subSpecialismFilter: "",
                    dateFromFilter: null,
                    dateToFilter: null,
                    diagnosticCodesFilter: "",
                    customQueryFilter: "",
                    page: 1
                }
            });
        }
        else {
            await syncDataContext.setSyncData({
                ...syncDataContext.data,
                caseNumber: caseNumber,
                oNumber: oNumber,
                index: index
            });
        }
    }

    const setSlideLoaderFromViewer = (slideLoader, path) => {
        let o = {};
        o[path] = slideLoader;
        setSlideLoaders(v => { return { ...v, ...o }; });
    }

    useEffect(() => {
        if (collageMode) {
            if (Object.entries(slideLoaders).length === 0) {
                return;
            }

            Object.entries(slideLoaders).forEach(([key, value]) => {
                if (value && value.slideLoader && value.slideLoader.mainViewport) {
                    value.slideLoader.load(null, null);
                }
            });

            setSlideLoaders({});
            return;
        }

        let copy = Object.assign({}, slideLoaders);
        let needsRefresh = false;
        Object.entries(slideLoaders).forEach(([key, value]) => {
            if (selectedSlides.filter(ss => ss === key).length === 0) {
                slideLoaders[key].slideLoader.load(null, null);
                delete copy[key];
                needsRefresh = true;
            }
        });

        if (needsRefresh) {
            Object.entries(copy).forEach(([key, value]) => {
                if (value.slideLoader && value.slideLoader.mainViewport) {
                    value.slideLoader.mainViewport.updateSize();
                }
            });

            setSlideLoaders(Object.assign({}, copy));
        }
    }, [selectedSlides, slideLoaders]);

    useEffect(() => {
        if (tooltipOpen) {
            setTimeout(() => {
                setTooltipOpen(false);
            }, 5000);
        }
    }, [tooltipOpen]);


    return (<>

        <div className="d-flex h-100">
            <div className="d-flex" style={{ width: "15%", flexDirection: "column" }}>
                <h4>
                    <span className="d-flex flex-column">
                        <span>
                            {!state.loaded && <p>Loading...</p>}
                            {syncDataContext.data.oNumber && state.caseNumber}
                            {state.loaded && !syncDataContext.data.oNumber && loading && <p>Loading...</p>}
                            {state.loaded && !loading && "Case not found"}
                            <small>
                                <Clipboard className="btn btn-link"
                                    onSuccess={() => setTooltipOpen(true)} data-clipboard-text={syncDataContext.data.caseNumber} title="Copy">
                                    <FontAwesomeIcon icon="fa-regular fa-clipboard" />
                                </Clipboard>

                                {state.originalCaseNumber !== state.caseNumber &&
                                    <Clipboard className="btn btn-link"
                                        onSuccess={() => setTooltipOpen(true)} data-clipboard-text={state.originalCaseNumber} title="Davinci copy">
                                        <FontAwesomeIcon icon="fa-solid fa-clipboard" />
                                    </Clipboard>
                                }
                                {tooltipOpen && <small className="text-success">Copied!</small>}
                            </small>
                        </span>
                        <small><i className="font-weight-light">{syncDataContext.data.oNumber}</i></small>
                    </span>
                </h4>

                <Label for="collageMode">Collage mode&nbsp;
                    <Input disabled={allSlides.length === 0} type="switch" name="collageMode" id="collageMode" value={collageMode} label="Collage mode" onChange={() => { setCollageMode(p => !p) }} />
                </Label>
                <div id="mainGallery" ref={galleryRef} className="w-100 slidesgallery" style={{ height: "calc(100% - 85px)" }}></div>
                <div style={{ width: "100%" }}>

                    <Form>
                        <FormGroup row>
                            <Col >
                                <textarea rows="3" ref={caseCommentsRef} placeholder="Case comments"
                                    onChange={(e) => { setCaseCommentsFromTextArea(state.originalId, caseCommentsRef.current.value) }}
                                    type="text" className="form-control" aria-label="caseComments" id="caseCommentsInput" defaultValue={state.comments} />
                            </Col>
                        </FormGroup>
                    </Form>


                </div>
            </div>
            <div className="d-flex flex-column align-items-stretch" style={{ flex: "1 1 auto" }}>
                <h4 className="row mb-1">
                    <div className="col">
                        <span>
                            <span style={{ fontWeight: "lighter" }}>Description: </span> {state.loaded ? state.tissueType : ""}
                        </span>

                    </div>
                    <div className='col-auto'>
                        <div className="col-auto ml-auto d-flex ">
                            <KeyboardEventHandler
                                handleKeys={['down', 'up']}
                                onKeyEvent={(key, e) => { key === "up" ? clickPrevious() : clickNext(); }} />
                            <div className="btn-group mb-3 mr-1" style={{ height: "38px" }} role="group" aria-label="navigation">
                                <button disabled={!state.previousCase || !state.loaded} className="btn btn-outline-secondary" onClick={clickPrevious} title={state.previousCase ? `Previous ${state.previousCase}` : ""}><FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon></button>
                                <button disabled={!state.nextCase || !state.loaded} className="btn btn-outline-secondary" onClick={clickNext} title={state.nextCase ? `Next ${state.nextCase}` : ""}><FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon></button>
                            </div>
                            <div className="input-group mb-3" style={{ height: "38px" }}>
                                <input ref={gotoCaseRef} type="text" className="form-control" placeholder="Go to case" aria-label="Go to case" id="gotocase"
                                    onKeyPress={event => { if (event.key === "Enter") { gotoCase(); } }} />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" onClick={gotoCase}>Go</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-auto ml-auto align-items-end">
                        <ButtonGroup className="cases-main">
                            <UncontrolledDropdown className="w-100 mb-2 btn-group">
                                <DropdownToggle color="primary" caret block>Case actions</DropdownToggle>
                                <DropdownMenu end>
                                    {actions && actions.filter(a => a.level === 1).map(a => <DropdownItem key={a.name} onClick={() => caseActionClick(a)}>{a.name}</DropdownItem>)}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown className="w-100 mb-2 btn-group" disabled={selectedSlides.length === 0}>
                                <DropdownToggle disabled={selectedSlides.length === 0} color="primary" caret block>Slide actions</DropdownToggle>
                                <DropdownMenu end>
                                    {actions && actions.filter(a => a.level === 0).map(a => <DropdownItem key={a.name} onClick={() => slideActionClick(a)}>{a.name}</DropdownItem>)}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <PageLinksDropdown className="w-100 mb-2 btn-group" block></PageLinksDropdown>
                        </ButtonGroup>
                    </div>

                </h4>
                <div className="row mb-1">
                    <div className="col">
                        <div className="card d-flex flex-row">
                            <strong className="col" style={{ flexGrow: "1.1" }}>Subspecialism <small><i className="font-weight-light">{state.subspecialism}</i></small></strong>
                            <strong className="col">Date <small><i className="font-weight-light">{state.dateReceived ? moment(state.dateReceived).format("ddd, DD MMM YYYY") : "N/A"}</i></small></strong>
                            <strong className="col">Validated <small><i className="font-weight-light">{state.validatedCount} / {state.orderCount}</i></small></strong>
                            <strong className="col">Coverslipped <small><i className="font-weight-light">{state.mountedCount} / {state.totalCount}</i></small></strong>
                            <strong className="col">Scanned <small><i className="font-weight-light">{state.scannedCount} / {state.totalCount}</i></small></strong>
                            {state.diagnosticCodes && <strong className="col">Diagnostic codes <small><i className="font-weight-light">{state.diagnosticCodes}</i></small></strong>}
                        </div>
                    </div>
                </div>


                {actionRequestResult && actionRequestResult.error === true &&
                    <Alert className="alertActions" color="danger">{actionRequestResult && actionRequestResult.message}
                    </Alert>}
                {actionRequestResult && actionRequestResult.error === false &&
                    <Alert className="alertActions" color="success">{actionRequestResult && actionRequestResult.message}
                    </Alert>}


                <AnnotationsToolbar slideLoaders={slideLoaders} collageMode={collageMode} disabled={collageMode || Object.entries(slideLoaders).length === 0}></AnnotationsToolbar>
                <div className="d-flex flex-wrap  align-items-stretch caw-slide-viewer" style={{ flex: "1 1 auto" }}>
                    {state.id && selectedSlides.length === 0 && <h4 className="text-muted p-3">Please select any slides on the left</h4>}
                    {selectedSlides.map((ss, i) =>
                        <React.Fragment key={`viewer-${ss}`}>
                            <ViewerComp serverUrl={serverCreds.serverUrl} slidePath={ss} slideLoaderOptions={slideLoaderOptions} UIContext={context.current}
                                navigationButtons={(selectedSlides.length === 1 || document.fullscreenElement) && !collageMode}
                                collageMode={collageMode}
                                selectSlide={selectSlide}
                                selectNextSlide={selectNext}
                                selectPreviousSlide={selectPrevious}
                                hasPreviousSlide={hasPreviousSlide(i)}
                                hasNextSlide={hasNextSlide(i)}
                                setSlideLoader={setSlideLoaderFromViewer}
                                closeViewerButton={closeViewerButton}
                                setSlideComments={setSlideComments}
                                findSlidesByPath={findSlidesByPath}
                                actionRequestResult={actionRequestResult}
                                setActionRequestResult={setActionRequestResult}
                                style={{ flex: `0 1 ${(100 / Math.ceil(Math.sqrt(selectedSlides.length))).toFixed(2)}%` }} className="mh-100">
                            </ViewerComp>
                        </React.Fragment>
                    )}

                </div>
            </div >
        </div >
        {isModalOpen &&
            <Modal isOpen={isModalOpen} size="xl">
                <ModalHeader>Comments</ModalHeader>
                <ModalBody>
                    <Nav tabs>
                        <NavItem style={{ cursor: "pointer" }}>
                            <NavLink className={activeTab === '1' ? "active" : ""} onClick={() => { toggle('1'); }}>Case</NavLink>
                        </NavItem>
                        <NavItem style={{ cursor: "pointer" }}>
                            <NavLink className={activeTab === '2' ? "active" : ""} onClick={() => { toggle('2'); }}>Slides</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className="h-100">
                        <TabPane tabId="1" className="h-100">
                            <Form>
                                <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                                    <Col>
                                        <textarea rows="9" ref={caseCommentsRef}
                                            onKeyPress={event => { if (event.key === "Enter") { setCaseComments(state.originalId, caseCommentsRef.current.value); } }}
                                            type="text" className="form-control" aria-label="caseComments" id="caseCommentsInput" defaultValue={state.comments} />
                                    </Col>
                                </FormGroup>
                            </Form>
                        </TabPane>
                        <TabPane tabId="2" className="h-100">
                            <Form>
                                <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                                    {state.slides && state.slides.filter(s => s.comments).map(s =>
                                        <><label className="col-sm-2 col-form-label">{`${s.recipient}-${s.block}-${s.slideId}`}</label>
                                            <div className="col-sm-10">
                                                <p className="form-control-plaintext">{s.comments}</p>
                                            </div>
                                        </>
                                    )}
                                    {state.slidesWithoutTasks && state.slidesWithoutTasks.filter(s => s.comments).map(s =>
                                        <><label className="col-sm-2 col-form-label">{`${s.recipient}-${s.block}-${s.slideId}`}</label>
                                            <div className="col-sm-10">
                                                <p className="form-control-plaintext">{s.comments}</p>
                                            </div>
                                        </>
                                    )}
                                </FormGroup>
                            </Form>
                        </TabPane>
                    </TabContent>

                </ModalBody>
                <ModalFooter>
                    <small className={"form-text " + (actionRequestResult && actionRequestResult.error ? "text-danger" : "text-success")}>
                        {actionRequestResult && actionRequestResult.message}
                    </small>
                    {activeTab === "1" &&
                        <Button color="primary" onClick={() => { setCaseComments(state.originalId, caseCommentsRef.current.value); }}>Save</Button>
                    }
                    <Button color="secondary" onClick={() => setIsModalOpen(false)}>Close</Button>
                </ModalFooter>
            </Modal>
        }

    </>);
}

export const AnnotationsToolbar = ({ slideLoaders, collageMode, disabled }) => {
    const [color, setColor] = useState("#78eb10");
    const [notes, setNotes] = useState("");
    const [saveState, setSaveState] = useState("");
    const [featureSelection, setFeatureSelection] = useState(null);
    const [lengthArea, setLengthArea] = useState({ length: "", area: "" });
    const notesTimeout = useRef(null);
    const [needsSave, setNeedsSave] = useState(false);

    useEffect(() => {
        Object.entries(slideLoaders).forEach(([key, value]) => {
            if (!value || !value.annotationManager) {
                return;
            }

            value.annotationManager.listen(ComponentEvents.AnnotationsSelectionChanged, (e) => {
                if (e && e.length > 0 && e[0].metaData) {
                    setFeatureSelection(e[0]);
                    setLengthArea({ length: e[0].metaData.FormattedLength || "", area: e[0].metaData.FormattedArea || "" });
                    setNotes(e[0].metaData.Notes);
                    return;
                }
                setNotes("");
                setLengthArea({ length: "", area: "" });
                setFeatureSelection(null);
            });

            value.annotationManager.listen(ComponentEvents.AnnotationsSaved, (e) => {
                if (saveState !== "errored" && e && e.success) {
                    setSaveState("saved");
                    setTimeout(() => { setSaveState("") }, 3000);
                }

                if (e && !e.success) {
                    setSaveState("errored");
                    setTimeout(() => { setSaveState("") }, 3000);
                }
            });

            value.annotationManager.listen(ComponentEvents.AnnotationAdded, (e) => { setNeedsSave(true); });
            value.annotationManager.listen(ComponentEvents.AnnotationDeleted, (e) => { setNeedsSave(true); });
            value.annotationManager.listen(ComponentEvents.AnnotationEditingEnded, (e) => { setNeedsSave(true); });
            value.annotationManager.listen(ComponentEvents.AnnotationModified, (e) => { setNeedsSave(true); });
        });
    }, [slideLoaders]);

    useEffect(() => {
        if (saveState !== "saving" && needsSave) {
            setNeedsSave(false);
            saveAll();
        }
    }, [needsSave]);

    useEffect(() => {
        Object.entries(slideLoaders).forEach(([key, value]) => {
            if (!value || !value.slideLoader || !value.slideLoader.mainViewport || !value.annotationManager) {
                return;
            }

            let annotationManager = value.annotationManager;
            value.slideLoader.mainViewport.showAnnotationsLabels(true, collageMode ? false : true);
        });
    }, [collageMode]);


    const updateNotes = () => {
        let updated = false;
        Object.entries(slideLoaders).forEach(([key, value]) => {
            if (!value || !value.slideLoader || !value.slideLoader.mainViewport || !value.annotationManager) {
                return;
            }

            let annotationManager = value.annotationManager;
            var ann = annotationManager.getSelection();
            if (!ann || ann.length === 0 || !ann[0].metaData) {
                return;
            }

            let md = ann[0].metaData;
            if (md.Notes === notes) {
                return;
            }

            updated = true;
            md.Notes = notes ? notes : " ";
            annotationManager.setMetadata(ann[0], md);
            annotationManager.saveAnnotations();
            //annotationManager.clearSelection();
            value.slideLoader.mainViewport.showAnnotationsLabels(false, false);
            value.slideLoader.mainViewport.showAnnotationsLabels(true, collageMode ? false : true);
        });

        if (updated) {
            setFeatureSelection(null);
            //setNotes("");
        }
    }

    useEffect(() => {
        if (notesTimeout.current) {
            clearTimeout(notesTimeout.current);
        }

        notesTimeout.current = setTimeout(updateNotes, 1500);
    }, [notes]);

    // const measureClick = (action, type) => {
    //     Object.entries(slideLoaders).forEach(([key, value]) => {
    //         if (!value || !value.slideLoader || !value.slideLoader.mainViewport) {
    //             return;
    //         }

    //         if (type === "clear") {
    //             value.slideLoader.mainViewport.stopMeasuring();
    //             return;
    //         }

    //         value.slideLoader.mainViewport.startMeasuring(type);
    //     });
    // }

    const drawClick = (type) => {
        Object.entries(slideLoaders).forEach(([key, value]) => {
            if (!value || !value.annotationManager || !value.annotationManager) {
                return;
            }

            let annManager = value.annotationManager;
            drawCommands("draw", type, color, annManager, notes);
        });
    }

    const drawCommands = (action, type, color, annotationManager, notes) => {
        if (action) {
            if (action === "draw") {
                var f = annotationManager.getSelection();

                annotationManager.startDrawing({
                    type: type,
                    color: color,
                    fillColor: "rgba(33,44,55,0.45)",
                    penWidth: 2,
                    iconRelativePath: null,
                    feature: type === "MultiPoint" && f.length > 0 ? f[0] : undefined,
                    notes: notes ? notes : " "
                });
            }
        }
    }

    const deleteSelection = () => {
        Object.entries(slideLoaders).forEach(([key, value]) => {
            if (!value || !value.slideLoader || !value.slideLoader.mainViewport || !value.annotationManager) {
                return;
            }

            let annotationManager = value.annotationManager;
            var ann = annotationManager.getSelection();
            if (ann && ann.length > 0) {
                annotationManager.deleteAnnotation(ann[0].getId());
            }
        });

        setFeatureSelection(null);
        setLengthArea({ length: "", area: "" });
        setNotes("");
    }

    const isAnnotationSelected = () => {
        let v = false;
        Object.entries(slideLoaders).forEach(([key, value]) => {
            if (!value || !value.slideLoader || !value.slideLoader.mainViewport || !value.annotationManager) {
                return;
            }

            let annotationManager = value.annotationManager;
            var ann = annotationManager.getSelection();
            if (ann && ann.length > 0) {
                v = true;
                return;
            }
        });

        return v;
    }

    const deleteAll = () => {
        Object.entries(slideLoaders).forEach(([key, value]) => {
            if (!value || !value.slideLoader || !value.slideLoader.mainViewport || !value.annotationManager) {
                return;
            }

            let allAnnotations = value.slideLoader.mainViewport.getAnnotations();
            let annotationManager = value.annotationManager;
            for (var i = 0; i < allAnnotations.length; i++) {
                annotationManager.deleteAnnotation(allAnnotations[i].getId());
                setLengthArea({ length: "", area: "" });
            }
        });

        setFeatureSelection(null);
        setNotes("");
    }

    const saveAll = () => {
        Object.entries(slideLoaders).forEach(([key, value]) => {
            if (!value || !value.annotationManager) {
                return;
            }

            setSaveState("saving");
            value.annotationManager.finishDrawing(true, null);
            value.annotationManager.saveAnnotations();
        });
    }

    return (<>
        {collageMode && <></>}
        {!collageMode && <div className="row">
            <div className="col-auto annotations pr-0">
                <div className="btn-group btn-group-sm">
                    <button type="button" className="btn btn-light" disabled={disabled} data-action="draw" data-type="Circle"
                        onClick={() => { drawClick("Circle"); }} title="Circle">
                        <FontAwesomeIcon icon={["far", "circle"]}></FontAwesomeIcon>
                    </button>
                    <button type="button" className="btn btn-light" disabled={disabled} data-action="draw" data-type="Rectangle"
                        onClick={() => { drawClick("Rectangle"); }} title="Rectangle">
                        <FontAwesomeIcon icon={["far", "square"]}></FontAwesomeIcon>
                    </button>
                    <button type="button" className="btn btn-light" disabled={disabled} data-action="draw" data-type="Polygon"
                        onClick={() => { drawClick("Polygon"); }} title="Polygon">
                        <FontAwesomeIcon icon={["fas", "square"]}></FontAwesomeIcon>
                    </button>
                    <button type="button" className="btn btn-light" disabled={disabled} data-action="draw" data-type="Freehand"
                        onClick={() => { drawClick("Freehand"); }} title="Freehand">
                        <FontAwesomeIcon icon={["fas", "pen"]}></FontAwesomeIcon>
                    </button>
                    <button type="button" className="btn btn-light" disabled={disabled} data-action="draw" data-type="Arrow"
                        onClick={() => { drawClick("Arrow"); }} title="Arrow">
                        <FontAwesomeIcon icon={["fas", "arrow-right"]}></FontAwesomeIcon>
                    </button>
                    <button type="button" className="btn btn-light" disabled={disabled} data-action="draw" data-type="ClosedFreehand"
                        onClick={() => { drawClick("ClosedFreehand"); }} title="Closed freehand">
                        <FontAwesomeIcon icon={["fas", "pen-square"]} size='lg'></FontAwesomeIcon>
                    </button>
                    <button type="button" className="btn btn-light" disabled={disabled} data-action="draw" data-type="Line"
                        onClick={() => { drawClick("Line"); }} title="Line">
                        <FontAwesomeIcon icon={["fas", "minus"]}></FontAwesomeIcon>
                    </button>
                    <button type="button" className="btn btn-light" disabled={disabled} data-action="draw" data-type="CompoundFreehand"
                        onClick={() => { drawClick("CompoundFreehand"); }} title="Compound polygon">
                        <FontAwesomeIcon icon={["fas", "draw-polygon"]}></FontAwesomeIcon>
                    </button>
                </div>
            </div>
            <div className="col-auto annotations px-0">
                <div className="btn-group btn-group-sm">
                    {/* <UncontrolledDropdown disabled={disabled} className="btn-group-sm">
                        <DropdownToggle tag="button" className="btn btn-light" caret title="Measure">
                            <FontAwesomeIcon icon={["fas", "ruler"]}></FontAwesomeIcon>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem data-action="measure" data-type="line" onClick={() => measureClick("measure", "line")}>Line</DropdownItem>
                            <DropdownItem data-action="measure" data-type="area" onClick={() => measureClick("measure", "area")}>Area</DropdownItem>
                            <DropdownItem divider={true}></DropdownItem>
                            <DropdownItem data-action="measure" data-type="clear" onClick={() => measureClick("measure", "clear")}>Clear measurements</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown> */}
                    <input id="color-picker-native" type='color' value={color} onChange={(e) => { setColor(e.target.value); }} className="color-picker mx-2" />
                    <button type="button" className="btn btn-light color-btn" style={{ color: "#0000ff" }} onClick={() => setColor("#0000ff")}>
                        <FontAwesomeIcon icon={["fa", "square"]}></FontAwesomeIcon>
                    </button>
                    <button type="button" className="btn btn-light color-btn" style={{ color: "#ff0000" }} onClick={() => setColor("#ff0000")}>
                        <FontAwesomeIcon icon={["fa", "square"]}></FontAwesomeIcon>
                    </button>
                    <button type="button" className="btn btn-light color-btn" style={{ color: "#00ff00" }} onClick={() => setColor("#00ff00")}>
                        <FontAwesomeIcon icon={["fa", "square"]}></FontAwesomeIcon>
                    </button>
                </div>
                <input id="color-picker-hidden" type='hidden' value='#78eb10' className="color-picker" />
            </div>
            <div className="col">
                <div className="row top-row">
                    <div className="col">
                        <div className="btn-group btn-group-sm">
                            <button type="button" className="btn btn-secondary" disabled={disabled || featureSelection == null} onClick={() => { deleteSelection(); }}
                                title="Delete">
                                <FontAwesomeIcon icon={["fas", "times"]}></FontAwesomeIcon>
                            </button>
                            <button type="button" className="btn btn-secondary" disabled={disabled} onClick={() => { deleteAll(); }}
                                title="Delete all">
                                <FontAwesomeIcon icon={["fas", "times-circle"]}></FontAwesomeIcon>
                            </button>
                            {/* <button type="button" className="btn btn-secondary" disabled={disabled} onClick={() => { saveAll(); }}
                                title="Save">
                                <FontAwesomeIcon icon={["fas", "save"]}></FontAwesomeIcon>
                            </button> */}
                        </div>

                        {/* <div className="btn-group btn-group-sm btn-group-toggle" data-toggle="buttons">
                            <button className="btn btn-secondary active" disabled={disabled} title="Toggle annotation labels">
                                <input id="chkLabels" type="checkbox" autoComplete="off" />
                                <FontAwesomeIcon icon={["fas", "font"]}></FontAwesomeIcon>
                            </button>
                            <button className="btn btn-secondary active" disabled={disabled} title="Toggle annotation measurements">
                                <input id="chkMeasurements" type="checkbox" autoComplete="off" />
                                <FontAwesomeIcon icon={["fas", "tag"]}></FontAwesomeIcon>
                            </button>
                        </div> */}

                        <span className="form-group" style={{ marginRight: "15px" }}>
                            <input type="text" className={"form-control form-control-sm" + (isAnnotationSelected() ? "" : " d-none")} value={notes} onBlur={(e) => { updateNotes() }} onChange={(e) => { setNotes(e.target.value); }} disabled={disabled} id="annotation-text"
                                style={{ marginLeft: "5px", display: "initial", width: "360px" }} />&nbsp;
                            {lengthArea.length && <Badge color="light" className="text-dark">
                                Length
                                <span id="txt-length"> {lengthArea.length} </span>
                            </Badge>
                            }
                            {lengthArea.area && <Badge color="light" className="text-dark" style={{ marginLeft: "10px" }}>
                                Area
                                <span id="txt-area"> {lengthArea.area} </span>
                            </Badge>
                            }
                        </span>
                        <span className="annotation-helper-icon">
                            {saveState === "saving" && <FontAwesomeIcon icon={["fas", "spinner"]} spin></FontAwesomeIcon>}
                            {saveState === "saved" && <Badge color="success"><FontAwesomeIcon icon={["fas", "check"]}></FontAwesomeIcon> Saved</Badge>}
                            {saveState === "errored" && <Badge color="danger"><FontAwesomeIcon icon={["fas", "times"]}></FontAwesomeIcon> Error</Badge>}
                        </span>
                    </div>
                </div>
            </div>
        </div>}
    </>);
}

export const ViewerComp = ({ serverUrl, slidePath, slideLoaderOptions, UIContext, navigationButtons, hasPreviousSlide, hasNextSlide, selectSlide, selectNextSlide, selectPreviousSlide, collageMode, setSlideLoader, closeViewerButton, setSlideComments, findSlidesByPath, setActionRequestResult, actionRequestResult, ...props }) => {
    const viewerRef = useRef(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [imageInfo, setImageInfo] = useState(false);
    const [isCommentsOpen, setIsCommentsOpen] = useState(false);
    const [slideObj, setslideObj] = useState(null);

    const slideCommentsRef = React.createRef();

    const loadViewer = (sPath, isCollage) => {
        let opts = Object.assign({}, slideLoaderOptions);
        if (sPath.startsWith("{")) {
            opts.filename = false;
        }

        opts.fullscreenControl = false;

        if (navigationButtons === true || document.fullscreenElement) {
            opts.customButtons = [
                {
                    location: ButtonLocations.NE,
                    title: "Previous",
                    content: "🡄 Previous",
                    class: hasPreviousSlide ? "text-info" : "text-info disabled",
                    callback: function () {
                        if (!hasPreviousSlide) {
                            return;
                        }

                        selectPreviousSlide(slidePath, !!document.fullscreenElement);
                    }
                },
                {
                    location: ButtonLocations.NE,
                    title: "Next",
                    content: "Next 🡆",
                    class: hasNextSlide ? "text-info" : "text-info disabled",
                    callback: function () {
                        if (!hasNextSlide) {
                            return;
                        }

                        selectNextSlide(slidePath, !!document.fullscreenElement);
                    }
                }
            ];
        }

        let foundSlide = findSlidesByPath([sPath]);
        if (foundSlide.length > 0) {
            setslideObj({ id: foundSlide[0].id, comments: foundSlide[0].comments, path: sPath });
        }
        else {
            setslideObj(null);
        }

        opts.customButtons = opts.customButtons || [];
        if (!isCollage) {
            opts.customButtons.push({
                location: ButtonLocations.NE,
                title: "Fullscreen",
                content: `<i class="fa fa-arrows-alt" aria-hidden="true"></i>`,
                class: "text-info button-fullscreen",
                callback: function () {
                    let elem = document.querySelector(".caw-slide-viewer");
                    // let hasChildren = document.querySelector(".caw-slide-viewer").children.length > 1;
                    // let elem = hasChildren ? viewerRef.current : document.querySelector(".caw-slide-viewer");
                    if (!elem) {
                        return;
                    }

                    if (!document.fullscreenElement) {
                        selectSlide(this.imageInfo.Filename, true);
                        elem.requestFullscreen().catch((err) => {
                            alert(
                                `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
                            );
                        });
                    }
                    else {
                        document.exitFullscreen();
                    }
                }
            });

            opts.customButtons.push({
                location: ButtonLocations.NE,
                title: "Image info",
                content: "ⓘ",
                class: "text-info",
                callback: function () {
                    setModalOpen(true);
                }
            });

            opts.customButtons.push({
                location: ButtonLocations.NE,
                title: "Comments",
                content: "🗨",
                class: "text-info text-comment",
                callback: function () {
                    setIsCommentsOpen(true);
                }
            });
        }

        opts.customButtons.push({
            location: ButtonLocations.NE,
            title: "Close",
            content: "×",
            class: "text-info",
            callback: function () {
                closeViewerButton(slidePath);
                if (document.fullscreenElement) {
                    document.exitFullscreen();
                }
            }
        });

        let slideLoader = new SlideLoader(UIContext, Object.assign(opts, { element: viewerRef.current }));
        slideLoader.listen(ComponentEvents.BeforeSlideLoad, (e) => {
        });

        slideLoader.load(serverUrl, sPath, () => {
            if (!isCollage) {
                let annotationManager = new Annotations({
                    context: UIContext,
                    element: null,
                    viewport: slideLoader.mainViewport,
                    serverUrl: serverUrl,
                    path: sPath,
                    enabled: true,

                });

                setImageInfo(slideLoader.mainViewport.imageInfo);
                setSlideLoader({ slideLoader: slideLoader, annotationManager: annotationManager }, sPath);
                return;
            }

            if (!sPath.startsWith("{")) {
                return;
            }

            var collageObj = JSON.parse(sPath);
            var sources = collageObj["Sources"];
            let collectionRows = collageObj["CollectionRows"];
            let tileMargin = collageObj["CollectionTileMargin"];
            let layout = collageObj["CollectionLayout"];
            if (!sources || sources.length === 0) {
                return;
            }

            UIContext.getImagesInfo({
                serverUrl: serverUrl,
                images: sources,
                success: (sessionId, images) => {
                    let annotations = new Annotations({
                        context: UIContext,
                        viewport: slideLoader.mainViewport,
                        serverUrl: serverUrl,
                        path: "",
                        enabled: true,
                        annotations: { visible: true, labels: true, showMeasurements: false },
                    });

                    let minresX = Math.min.apply(Math, images.map(im => im.MicrometresPerPixelX));
                    let minresY = Math.min.apply(Math, images.map(im => im.MicrometresPerPixelY));

                    let c = Math.round(images.length / collectionRows);
                    c = Math.max(1, c);
                    let sx = 0;
                    let sy = 0;
                    let maxHeight = 0;
                    for (let i = 0; i < images.length; i++) {
                        let mX = images[i].MicrometresPerPixelX / minresX;
                        let mY = images[i].MicrometresPerPixelY / minresY;

                        let w = Math.round(images[i].Width * mX);
                        let h = Math.round(images[i].Height * mY);

                        annotations.addAnnotation({
                            LayerID: 0,
                            Geometry: `POLYGON ((${sx} ${sy}, ${sx} ${sy + h}, ${sx + w} ${sy + h},${sx + w} ${sy}, ${sx} ${sy}))`,
                            Notes: slidePathTransform(images[i].Filename)
                        });

                        if (layout === 0) {
                            sx += w + tileMargin;
                            maxHeight = Math.max(maxHeight, h);
                        }
                        else {
                            sy += h + tileMargin;
                            maxHeight = Math.max(maxHeight, w);
                        }

                        if (((i + 1) % c) === 0) {
                            if (layout === 0) {
                                sx = 0;
                                sy += maxHeight + tileMargin;
                                maxHeight = 0;
                            }
                            else {
                                sy = 0;
                                sx += maxHeight + tileMargin;
                                maxHeight = 0;
                            }
                        }
                    }
                }
            });
        });
    }

    useEffect(() => {
        loadViewer(slidePath, collageMode);
    }, [slidePath, navigationButtons, collageMode, slideLoaderOptions]);

    const setSlideCommentsFromTextArea = (sid, comments) => {
        if (!sid) {
            return;
        }

        setSlideComments(sid, comments);
        if (slideObj) {
            setslideObj({ ...slideObj, comments: comments });
        }
    }

    useEffect(() => {
        if (slideCommentsRef?.current) {
            slideCommentsRef.current.value = slideObj?.comments;
        }
    }, [isCommentsOpen, slideObj]);


    return (
        <>
            <div ref={viewerRef} {...props} data-id={slideObj?.id}></div>
            {isCommentsOpen &&
                <Modal isOpen={isCommentsOpen} size="xl">
                    <ModalHeader>Slide comments</ModalHeader>
                    <ModalBody>
                        <Col>
                            <Form>
                                <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                                    <Col>
                                        {slideObj && slideObj.id &&
                                            <textarea rows="9" ref={slideCommentsRef}
                                                onKeyPress={event => { if (event.key === "Enter") { setSlideCommentsFromTextArea(slideObj?.id, slideCommentsRef?.current?.value); } }}
                                                type="text" className="form-control" aria-label="caseComments" id="caseCommentsInput" defaultValue={slideObj?.comments} />
                                        }
                                        {
                                            !slideObj &&
                                            <span className="text-danger">Slide was not found in the database, comments cannot be saved</span>
                                        }
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                    </ModalBody>
                    <ModalFooter>
                        <small className={"form-text " + (actionRequestResult && actionRequestResult.error ? "text-danger" : "text-success")}>
                            {actionRequestResult && actionRequestResult.message}
                        </small>
                        {slideObj && slideObj.id &&
                            <Button color="primary" onClick={() => { setSlideCommentsFromTextArea(slideObj?.id, slideCommentsRef?.current?.value); setIsCommentsOpen(false); }}>Save</Button>
                        }
                        <Button color="secondary" onClick={() => setIsCommentsOpen(false)}>Close</Button>
                    </ModalFooter>
                </Modal>
            }
            {
                modalOpen &&
                <Modal isOpen={modalOpen} size="xl">
                    <ModalHeader>Slide information</ModalHeader>
                    <ModalBody>
                        <Col>
                            <Form>
                                <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                                    <label className="font-weight-bold col-3" htmlFor="name">Filename</label>
                                    <Col>
                                        <div className="form-control-static">{imageInfo.Filename}</div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                                    <label className="font-weight-bold col-3">Width x Height</label>
                                    <Col>
                                        <div className="form-control-static">{imageInfo.Width} x {imageInfo.Height}</div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                                    <label className="font-weight-bold col-3">Micrometres per pixel</label>
                                    <Col>
                                        <div className="form-control-static">{imageInfo.MicrometresPerPixelX?.toFixed(4)} x {imageInfo.MicrometresPerPixelY?.toFixed(4)}</div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-2 mr-sm-2 mb-sm-0">
                                    <label className="font-weight-bold col-3">MetaData</label>
                                    <Col>
                                        {imageInfo.MetaData && imageInfo.MetaData.map(md =>
                                            <div key={md.Name} style={{ whiteSpace: "nowrap" }}><strong style={{ textTransform: "capitalize" }}>{md.Name}</strong>: {md.Value}</div>)
                                        }
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setModalOpen(false)}>Close</Button>
                    </ModalFooter>
                </Modal>
            }
        </>)

}