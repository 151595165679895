export const createDirectory = async (sessionId, path, pmaCoreServerUrl) => {
    const resp = await fetch(`${pmaCoreServerUrl}api/json/CreateDirectory`,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                sessionID: sessionId,
                path: path
            })
        });

    if (resp.status != 200) {
        throw "Error creating directory";
    }

    await !!resp.json();
}