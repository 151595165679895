import React, { createContext, useReducer } from "react";
import {
    LOGIN_USER_SUCCESS, LOGIN_USER_FAIL, LOGOUT_USER
} from '../actions/Types';
import { getAccessTokenAsync, getUser } from '../actions/AuthActions';

let initialState = {
    register_error: '',
    isLoading: false,
    user: getUser() ?? "",
    isLogin: getAccessTokenAsync() != null,
    login_error: ''
};

let AppContext = createContext({ state: initialState, dispatch: () => { } });

let reducer = (state, action) => {
    switch (action.type) {
        case LOGIN_USER_FAIL: {
            return { login_error: action.payload };
        }
        case LOGIN_USER_SUCCESS: {
            return { ...state, isLogin: true, user: action.payload };
        }
        case LOGOUT_USER: {
            return { ...state, isLogin: false };
        }
        default:
            return state;
    }
};

const AppContextProvider = (props) => {
    const fullInitialState = {
        ...initialState,
    }

    const [state, dispatch] = useReducer(reducer, fullInitialState);
    const value = { state, dispatch };

    return (
        <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
    );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
