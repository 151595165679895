import React, { Component, useContext, useState } from 'react';
import { DropdownToggle, DropdownMenu, Container, Navbar, NavbarBrand, UncontrolledDropdown, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AppContext } from '../../State/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const NavMenu = () => {
    const context = useContext(AppContext);

    return <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
            <Container className="d-sm-inline-flex flex-sm-row" style={{ maxWidth: "100%" }}>
                <NavLink className="text-dark" style={{ marginTop: 9.5 }} tag={(props) => <Link {...props} />} to="/">Pathology Lab Workspace</NavLink>
                <NavbarBrand style={{ flexGrow: 1, marginLeft: "auto", marginRight: "auto" }} tag={(props) => <Link {...props} />} to="/pims/home">Pathology Image Management System</NavbarBrand>

                <ul className="navbar-nav" style={{ marginTop: 3 }}>
                    <NavItem>
                        <NavLink tag={(props) => <Link {...props} />} className="text-dark" to="/pims/tasks">Tasks</NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav>
                        <DropdownToggle nav caret className="text-dark">Configuration</DropdownToggle>
                        <DropdownMenu>
                            <NavLink tag={(props) => <Link {...props} />} className="text-dark" to="/pims/mappings">Mappings</NavLink>
                            <NavLink tag={(props) => <Link{...props} />} className="text-dark" to="/pims/queries">Queries</NavLink>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    {context?.state?.isLogin &&
                        <NavItem>
                            <NavLink tag={(props) => <Link {...props} />} className="text-dark" to="/signout">
                                <FontAwesomeIcon icon={faUser} />&nbsp;
                                <span className="pl-1">{context?.state?.user ?? "Signout"}</span>
                            </NavLink>
                        </NavItem>
                    }
                </ul>
            </Container>
        </Navbar>
    </header>;
}

export { NavMenu };