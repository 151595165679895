import React, { useContext, useEffect, useState } from 'react';
import { UncontrolledCollapse, ModalHeader, ModalFooter, ModalBody, FormGroup, Modal, Input, ButtonGroup, Button, Card, CardTitle, CardText, Table, CardColumns, ListGroup, ListGroupItem, CardBody, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import { getAccessTokenAsync } from '../../actions/AuthActions';
import { getOBRValues, getOBXValues } from '../../actions/Types';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';

export const MessageDetails = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({
        messageHeader: null,
        segments: null,
        identifiers: null,
        loaded: false,
        slideId: null,
        orderNumber: null,
        buttonClicked: false,
        modalMessage: false
    });

    const { messageId } = useParams();
    const [messageStatusNotes, setMessageStatusNotes] = useState("");

    useEffect(() => {
        if (location.state) {
            const { message, identifiers } = location.state;
            setMessage(message, identifiers);
        }

        document.title = "Message - Verification of automated staining";
        if (!state.loaded) {
            fetchMessageAndIdentifiers(messageId);
        }
    }, []);

    const goBack = (evt) => {
        evt.preventDefault();
        navigate(-1);
    }

    const modalMessageChanged = (e) => {
        setMessageStatusNotes(e.target.value);
    }

    const submitHandler = (e) => {
        e.preventDefault();
    }

    const postApprove = async (approve) => {
        var response = await fetch(`api/message/approve`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + getAccessTokenAsync() },
                body: JSON.stringify({
                    Id: state.messageHeader.id,
                    Approve: approve,
                    Notes: messageStatusNotes
                })
            });

        const result = await response.json();
        setState({ ...state, modalMessage: false, buttonClicked: false });

        if (result === true) {
            fetchMessageAndIdentifiers(state.messageHeader.id);
            return;
        }

        console.warn("Server error for this message");
    }

    const approveClick = async (approve) => {
        setState({ buttonClicked: true });
        if (!approve && state.messageHeader.status !== 2) {
            setState({ ...state, modalMessage: true });
        }
        else {
            await postApprove(approve);
        }
    }

    const setMessage = (message, identifiers) => {
        setMessageStatusNotes("");
        let segments = JSON.parse(message.messageJson);
        let { slideId, orderNumber, specimenReceivedDateTime } = getOBRValues(segments);
        let { requester, stainingRunCompletedTime } = getOBXValues(segments);

        segments = segments.slice(1);

        for (let [key, c] of Object.entries(segments)) {
            c.Name = identifiers[c.Name];
            c.Fields = c.Fields.filter(v => v.Value).map(f => {
                var split = f.Name.split(".");
                split = split.map(s => isNaN(parseInt(s)) ? s : parseInt(s) + 1);
                var n = split.join(".");
                return { Name: identifiers[n], Value: f.Value }
            });
        }

        setState({
            messageHeader: message,
            segments: segments,
            identifiers: identifiers,
            loaded: true,
            slideId: slideId,
            orderNumber: orderNumber,
            stainingRunCompletedTime: stainingRunCompletedTime
        });
    }

    const fetchMessageAndIdentifiers = async (id) => {
        const response = await fetch('api/message/message?id=' + id, {
            headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
        });

        const message = await response.json();

        const responsei = await fetch("api/message/identifiers", {
            headers: { "Authorization": "Bearer " + getAccessTokenAsync() }
        });

        var identifiers = await responsei.json();

        setMessage(message, identifiers);
    }

    return (
        !state.loaded
            ? <p><em>Loading...</em></p>
            :
            <div>
                <Card body className={"mb-2 " + (state.messageHeader.status === 1 ? "border-success" : state.messageHeader.status === 2 ? "border-danger" : "")}>
                    <h5 className="card-title">
                        <ButtonGroup className="float-right">
                            <Button color="link" className="" onClick={() => approveClick(true)}>
                                <FontAwesomeIcon spin={state.buttonClicked} className={state.messageHeader.status === 1 ? "text-success" : "text-muted"} icon={!state.buttonClicked ? faThumbsUp : faSpinner}></FontAwesomeIcon>
                            </Button>
                            <Button color="link" onClick={() => approveClick(false)}>
                                <FontAwesomeIcon spin={state.buttonClicked} className={state.messageHeader.status === 2 ? "text-danger" : "text-muted"} icon={!state.buttonClicked ? faThumbsDown : faSpinner}></FontAwesomeIcon>
                            </Button>
                            <Button color="link" onClick={() => navigate(-1)}>Back</Button>
                        </ButtonGroup>
                        <div>
                            <b>Order number:</b> {state.orderNumber}
                            <br />
                            <small className="text-muted">
                                <Moment format="LLLL">
                                    {state.messageHeader.messageDate}
                                </Moment>
                            </small>
                        </div>
                    </h5>
                    <div className="d-flex flex-column flex-wrap">
                        <div><strong>O-number </strong><a href={'/vas/slides/' + state.slideId} target="_blank">{state.slideId}</a></div>
                        <div><strong>Stainer </strong>{state.messageHeader.stainer} {state.messageHeader.stainerSerial}</div>
                        <div><strong>Stain finished time: </strong> <Moment className="ml-1" parse="YYYYMMDDHHmm" format="LLLL">{state.stainingRunCompletedTime}</Moment></div>
                        {state.messageHeader.status !== 0 &&
                            <>
                                <div>
                                    <strong>Status </strong>{state.messageHeader.status === 1 ? <span className="text-success">Approved</span> : <span className="text-danger">Failed</span>}
                                    <small className="pl-1">
                                        <strong> by </strong>{state.messageHeader.statusChangedBy}
                                        <strong className="pl-1"> on </strong><Moment subtract={{ minutes: (new Date()).getTimezoneOffset() }} format="LLLL">{state.messageHeader.statusChangedOn}</Moment>
                                    </small>
                                </div>
                                <div>
                                    {state.messageHeader.statusNotes}
                                </div>
                            </>
                        }
                    </div>
                    <CardText>
                        <small className="text-muted">{state.messageHeader.messageControlId}</small>
                    </CardText>
                </Card>

                <Card body className="mb-2">
                    <CardTitle className="ml-3 mr-3 pb-1 border-bottom">
                        Message Text
                        <ButtonGroup className="float-right">
                            <Button color="link" id="toggler">
                                Show / Hide
                            </Button>
                        </ButtonGroup>
                    </CardTitle>
                    <CardText>
                        <UncontrolledCollapse toggler="#toggler">
                            {state.messageHeader.messageText}
                        </UncontrolledCollapse>
                    </CardText>
                </Card>

                {state.messageHeader.statusChanges.length > 0 && <Card body className="mb-2">
                    <CardTitle className="ml-3 mr-3 pb-1 border-bottom">
                        Audit Trail
                    </CardTitle>
                    <div className="d-flex flex-column flex-wrap">
                        <ul>
                            {state.messageHeader.statusChanges.map((f, i) =>
                                <li key={i}>
                                    Status&nbsp;changed&nbsp;to&nbsp;{f.status === 1 ? <span className="text-success">Approved</span> : f.status === 2 ? <span className="text-danger">Failed</span> : <span>Not Set</span>}
                                    &nbsp;by&nbsp;{f.statusChangedBy}
                                    &nbsp;on&nbsp;<Moment subtract={{ minutes: (new Date()).getTimezoneOffset() }} format="dddd, MMMM Do YYYY LTS">{f.statusChangedOn}</Moment>
                                    {f.status === 2 ? <>&nbsp;-&nbsp;Reason:&nbsp;{f.statusNotes}</> : <></>}
                                </li>
                            )}
                        </ul>
                    </div>
                </Card>}

                <CardColumns>
                    {state.messageHeader.lotNumbers && state.messageHeader.lotNumbers.length > 0 &&
                        <Card body key={"lots"} className="mb-2">
                            <h5 className="card-title">Lot numbers</h5>
                            <CardText>
                                <ListGroup>
                                    {state.messageHeader?.lotNumbers.map(l =>
                                        <ListGroupItem tag="a" href={"/vas/home?lotnumber=" + l} action className="py-1" key={l}>{l}</ListGroupItem>
                                    )}
                                </ListGroup>
                            </CardText>
                        </Card>}

                    {Object.entries(state.segments).map(([k, c], i) =>
                        <Card key={i} body>
                            <CardTitle id={c.Name.replace(/ /g, "_") + i.toString()}>{c.Name}</CardTitle>

                            <Table size="sm">
                                <tbody>
                                    {c.Fields.map(f =>
                                        <tr key={c.Name + " " + f.Name}>
                                            <td>{f.Name}</td>
                                            {f.Name === "Received Date/Time" ?
                                                <td><Moment format="LLLL" parse="YYYYMMDDHHmmss">{f.Value}</Moment></td> :
                                                f.Name === "Lot Serial Number" ? <td><a href={"/vas/home?lotserial=" + f.Value}>{f.Value}</a></td> : <td>{f.Value}</td>
                                            }
                                        </tr>
                                    )}
                                </tbody>
                            </Table>

                        </Card>
                    )
                    }
                </CardColumns>
                {state.modalMessage &&
                    <Modal isOpen={state.modalMessage}>
                        <ModalHeader>Please provide a reason</ModalHeader>
                        <ModalBody>
                            <form onSubmit={submitHandler}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Input type="text" name="message" placeholder="Reason" onInput={modalMessageChanged} />
                                </FormGroup>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => postApprove(false)}>Save</Button>{' '}
                            <Button color="secondary" onClick={() => setState({ ...state, modalMessage: false, buttonClicked: false })}>Cancel</Button>
                        </ModalFooter>
                    </Modal>}
            </div >
    );
}



